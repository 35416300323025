<template>
  <div>
    <div class="col-12">
      <div v-if="selectedEmployee">
        <h1>Daily Time Record of:</h1>
      </div>
      <h3 v-if="selectedEmployee != null">
        <b>{{ selectedEmployee.employee.full_name }}</b>
      </h3>
      <h6 v-if="selectedEmployee != null">
          {{selectedEmployee.employee.employee_category}} |   {{selectedEmployee.employee.salary_period}}
      </h6>
    </div>
    <div class="col-12">
      <table class="table table-bordered" id="employee-dtr-summary-table">
        <thead>
          <tr>
            <th></th>
            <th>Date</th>
            <th>Normal Working Hours</th>
            <th>Overtime Hours</th>
            <th>Adjustments (minutes)</th>
            <th>Project</th>
            <th>Gross Earning</th>
            <th>Legal Holiday Additional</th>
            <th>Special Holiday Additional</th>
            <th>Penalty</th>
          </tr>
        </thead>
        <tbody v-if="selectedEmployee">
          <tr v-for="summary in selectedEmployee.dtr_metas" :key="summary.id">
            <td><span @click="disableDtrMeta(summary.id)" style="color:red;cursor:pointer" class="fa fa-times"></span></td>
            <td>{{ summary.date_log | formatDate }}</td>
            <td>{{ Math.floor(summary.normal_employee_work_hours/60) }}</td>
            <td>{{ summary.approved_overtime }}</td>
            <td>{{  parseInt(summary.total_minutes) | formatDuration }}</td>
            <td>{{ summary.project.name }}</td>
            <td>{{ summary.gross_earning | numeral('0,0.00')  }}</td>
            <td>{{ summary.legal_holiday_additional | numeral('0,0.00')  }}</td>
            <td>{{ summary.special_holiday_additional | numeral('0,0.00')  }}</td>
            <td>{{ summary.total_penalty | numeral('0,0.00') }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapState} from 'vuex'
  export default {
    data: function(){
            return {
               selectedSummary: {}
            }
        },
    computed: {
      ...mapState({
          summaries: state => state.payroll.employeeSummaries,
          selectedEmployee: state => state.payroll.selectedEmployee,
      })
    },
    methods:{
      disableDtrMeta(id){
        this.disableDtrMeta(id);
      },
      ...mapActions('payroll', [
        'disableDtrMeta'
      ])
    }
  }
</script>
