<template>
    <div class="row">
        <loading
            :active.sync="isLoading"
            :is-full-page="fullPage">
        </loading>

        <special-deductions-edit/>
        <special-deductions-delete/>

        <div class="table-responsive">
            <table id="special-deductions-table" style="width:100%" class="display table table-sm table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Employee Id</th>
                        <th>Employee Name</th>
                        <th>Project Name</th>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Description</th>
                        <th>Total Amount</th>
                        <th>Amount Per Deduction</th>
                        <th>Total Amount Paid</th>
                        <th>Remaining Balance</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tfoot>
                    <tr>
                        <th>#</th>
                        <th>Employee Id</th>
                        <th>Employee Name</th>
                        <th>Project Name</th>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Description</th>
                        <th>Total Amount</th>
                        <th>Amount Per Deduction</th>
                        <th>Total Amount Paid</th>
                        <th>Remaining Balance</th>
                        <th>Action</th>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>
<script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import { mapMutations } from 'vuex'

    export default {

        data: function(){
            return {
                isLoading: false,
                fullPage: false,
                deductionTable: {}
            }
        },

        mounted(){
            let vm = this;
            function formatDate(date){
                var today  = new Date(date);
                return today.toLocaleDateString("en-US");
            }

            function formatMoney(value){
               return vm.$options.filters.numeral(value, '0,000.00');
            }


            var table = $('#special-deductions-table').DataTable({
                serverSide: true,
                processing: true,
                ajax:"/data/special-deductions",
                columns: [
                    { data: 'id' },
                    { data: 'employee_id' },
                    {
                        data: 'full_name',
                        sortable: true,

                    },
                    {
                        data: 'project_name',
                        sortable: false,
                    },
                    {
                        data: 'date',
                        sortable: true,
                        "render": function ( data, type, full, meta ) {
                            return formatDate(full.date);
                        }
                    },

                    { data: 'type' },
                    { data: 'description' },
                    {
                        data: 'amount',
                        sortable: false,
                        "render": function ( data, type, full, meta ) {
                            return ''+formatMoney(full.amount);
                        }
                    },
                    {
                        data: 'amount_per_deduction',
                        sortable: false,
                        "render": function ( data, type, full, meta ) {
                            return ''+formatMoney(full.amount_per_deduction);
                        }
                    },
                     {
                        data: 'total_payment',
                        sortable: false,
                        "render": function ( data, type, full, meta ) {
                            return ''+formatMoney(full.total_payment ? full.total_payment : 0);
                        }
                    },
                    {
                        data: 'remaining_amount',
                        sortable: false,
                        "render": function ( data, type, full, meta ) {
                            return ''+formatMoney(full.amount - full.total_payment);
                        }
                    },
                    {
                        sortable: false,
                        "render": function ( data, type, full, meta ) {
                            let html = `
                                <button type="button" data-details='`+JSON.stringify(full)+`' class="btn btn-sm btn-warning edit-btn">
                                    <i class="fa fa-edit"></i>
                                    Edit
                                </button>

                                <button type="button" data-details='`+JSON.stringify(full)+`' class="btn btn-sm btn-danger delete-btn">
                                    <i class="fa fa-trash"></i>
                                    Delete
                                </button>
                            `
                            return html;
                        }
                    },
                ],
                "dom":
                    `
                        <"row"
                            <"col-6"l><"col-6 pr-0"f><"d-flex justify-content-end w-100 toolbar">
                        >
                        <rtip>
                    `,
                "drawCallback": function(){

                    $(".edit-btn").click(function(){
                        let details = $(this).data('details');
                        vm.setSpecialDeductionDetails(details);
                        $("#edit-special-deduction-btn").click();
                    })

                    $(".delete-btn").click(function(){
                        let details = $(this).data('details');
                        vm.setSpecialDeductionDetails(details);
                        $("#delete-special-deduction-btn").click();
                    })
                }
            });

            this.deductionTable = table;
        },

        methods:{
            ...mapMutations('specialDeductions', [
                'setSpecialDeductionDetails',
            ]),

            payDeduction(sp_id){
                axios.post('/special-deductions/paid',{
                    sp_id: sp_id
                }).then(response => {
                    this.deductionTable.draw();
                });
            },

             revertDeduction(sp_id){
                axios.post('/special-deductions/revert',{
                    sp_id: sp_id
                }).then(response => {
                    this.deductionTable.draw();
                });
            }
        },

        components: {
            Loading
        },
}
</script>
