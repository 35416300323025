import Vue from 'vue'
import axios from 'axios'

Vue.use(axios)

export default {
  getEmployeeDtrSummary(cb, link) {
    let summaries = [];

    axios.get(link).then((response) => {
      summaries = response.data;
      cb(summaries);
    })
  },

  getEmployeeDeductions(cb, id) {
    let link = '/data/payroll/employees/' + id + '/deductions';
    let deductions = [];

    axios.get(link).then((response) => {
      deductions = response.data;
      cb(deductions);
    })
  },

  getEmployeeSpecialDeductions(cb, id, projectId) {
    let link = '/data/payroll/employees/' + id + '/' + projectId + '/special-deductions';
    let deductions = [];

    axios.get(link).then((response) => {
      deductions = response.data;
      cb(deductions);
    })
  },

  updateSpecialDeductionDetails(details) {
    let link = '/data/special-deductions/' + details.id + '/update';
    axios.post(link, details);
  },

  getUserDateLogDetails(cb, employeeId, dateLog) {
    let link = "/data/" + employeeId + "/" + dateLog + "/date-logs";

    axios.get(link).then(response => {
      cb(response.data.data);
    });
  },

  getDateTimeLogs(cb, employeeId, date) {
    let link = "/data/dtrs/" + employeeId + "/" + date + "/time-logs";

    axios.get(link).then(response => {
      cb(response.data.data);
    });
  },

  getDtrMeta(cb, employeeId, dateLog, projectId) {
    let link = "/data/" + employeeId + "/" + dateLog + "/get-dtr-meta?project_id=" + projectId;

    axios.get(link).then(response => {
      cb(response.data.data);
    })
  },

  getEmployeePenalties(cb, employeeId, dateLog) {
    let link = "/api/v1/employees/" + employeeId + "/penalties?date=" + dateLog;
    axios.get(link).then(response => {
      cb(response.data.data);
    });
  },

  getEmployeePenaltiesFromDateRange(cb, employeeId, fromDate, toDate) {
    let link = "/penalties/" + employeeId + "/range-penalties?fromDate=" + fromDate + '&toDate=' + toDate;
    axios.get(link).then(response => {
      cb(response.data);
    });
  },

  addEmployeePenalty(cb, penalty) {
    let link = "/api/v1/employees/" + penalty.employee_id + "/penalties"
    axios.post(link, penalty).then(() => {
      cb();
    });
  },

  removeEmployeePenalty(cb, employeeId, penaltyId) {
    let link = "/api/v1/employees/" + employeeId + "/penalties/" + penaltyId
    axios.delete(link).then(() => {
      cb();
    });
  },

  savePayroll(cb, payload) {
    let link = "/data/payroll/process/save";
    axios.post(link, payload).then((response) => {
      cb(response);
    });
  },

  deletePayroll(cb, deletePayrollId) {
    let link = "/data/payroll/delete";
    axios.post(link, {
      payroll_id: deletePayrollId
    }).then((response) => {
      cb(response);
    });
  },

  addDtrTimeAdjustment(cb, dtrTimeAdjustment) {
    let link = "/api/v1/dtr-time-adjustments";
    axios.post(link, {
      dtrTimeAdjustment: dtrTimeAdjustment
    }).then((response) => {
      cb(response);
    });
  },

  removeDtrTimeAdjustment(cb, id) {
    let link = "/api/v1/dtr-time-adjustments/" + id;
    axios.delete(link).then((response) => { cb(response) });
  },

  removeDtr(cb, ids) {
    let link = "/api/v1/daily-time-records/ids?ids=" + ids;
    axios.delete(link).then((response) => { cb(response) });
  },

  searchEmployees(cb, query) {
    let link = "/data/get-employees/" + query;
    axios.get(link)
      .then((response) => { cb(response.data.data) })
      .catch((error) => { cb([]) });
  },

  searchProjects(cb, query) {
    let link = "/data/get-projects/" + query;
    axios.get(link)
      .then((response) => { cb(response.data.data); })
      .catch((error) => { cb([]) });

  },

  addTimeLog(cb, payloads) {
    let link = "/data/add-time-log";
    axios.post(link, payloads).then((response) => { cb(response) });
  },

  getSpecialDeductionTypes(cb) {
    let link = "/data/special-deductions/types";
    axios.get(link).then((response) => { cb(response.data) });
  },

  getTimeDifferenceFromPreviousTime(cb, employeeId, projectId, date, timeLogIn, timeLogOut) {
    let link = '/api/v1/dtr-time-adjustments/calculateTimeDifference'
    axios.get(link, {
      params: {
        employeeId: employeeId,
        projectId: projectId,
        date: date,
        timeLogIn: timeLogIn,
        timeLogOut: timeLogOut
      }
    }).then((response) => { cb(response.data.data.timeDifference) })
  },

  getPaymentsData(cb, id) {
   let link = "/special-deduction-report/payments";
    axios.get(link, {
      params: {
        id:id,
      }
    }).then((response) => {
      cb(response.data.data) });
  },

  saveOverTime(cb, employeeId, date, overTimeInMinutes) {
    let link = '/api/v1/dtr-over-time/overtime';
    axios.post(link, {
      employeeId: employeeId,
      date: date,
      overTimeInMinutes: overTimeInMinutes
    }).then((response) => { cb(response.data) });
  },

  saveApprovedOverTime(cb, employeeId, date, approvedOvertimeInMinutes) {
    let link = '/api/v1/dtr-over-time/approvedUpdateOvertime';
    axios.post(link, {
      employeeId: employeeId,
      date: date,
      approvedOvertimeInMinutes: approvedOvertimeInMinutes
    }).then((response) => {
      cb(response.data)
    });
  },

  saveHoliday(cb, name, date, is_recurring, percentage, type) {
    let link = '/holiday/store';
    axios.post(link, {
      name: name,
      date: date,
      is_recurring: is_recurring,
      percentage: percentage,
      type: type
    }).then((response) => {
      cb(response.data)
    });
  },

  deleteHoliday(cb, id){
    let link = '/holiday/destroy';
    axios.post(link, {
      id: id
    }).then((response) => {
      cb(response.data)
    });
  },

  updateHoliday(cb, id, name, date, percentage, type, is_recurring) {
    let link = '/holiday/update';
    axios.post(link, {
      id:id,
      name: name,
      date: date,
      percentage: percentage,
      type: type,
      is_recurring: is_recurring
    }).then((response) => {
      cb(response.data)
    });
  },


  deleteCutOffOvertimeEntry(id){
    let link = '/cut-off-overtime/delete';
    axios.post(link, {
      id:id,
    }).then((response) => {

    });
  },

  deleteTimeLog(cb, timeLogToDelete){
    let link = '/data/dtrs/time-logs/delete';
    axios.post(link, {
      timeLogToDelete: timeLogToDelete,
    }).then((response) => {
        cb();
    });
  },

  deleteDtrMeta(cb, dtr_meta_id){
    let link = '/data/dtr-meta/delete';
    axios.post(link, {
      dtr_meta_id: dtr_meta_id,
    }).then((response) => {
        cb();
    });
  },

  saveTotalWorkingHours(cb, dtrMetaId, totalWorkingHours){
    let link = '/data/dtrs-meta/save';
    axios.post(link, {
      dtr_meta_id: dtrMetaId,
      total_working_hours: totalWorkingHours
    }).then((response) => {
        cb();
    });
  },

  getEmployeeWorkedProjects(cb, employeeId, dateLog){
    let link = '/data/dtrs-meta/'+employeeId+'/worked-projects';
    axios.post(link, {
      date_log: dateLog
    }).then((response) => {
        cb(response.data);
    });
  },

  saveWorkingHours(cb, summaries){
    let link = '/data/dtrs-meta/summaries/save';
    axios.post(link, {
        summaries: summaries
      }).then((response) => {
          cb(response.data);
      });
  },

  getProjects(cb){
    axios.get("/api/v1/projects").then(response => {
        cb(response.data.data);
    });
  },

  getEmployeeAllowance(cb, employee_id, startDate, endDate){
    axios.get("/data/employees/"+employee_id+"/allowances?start_date="+startDate+"&end_date="+endDate).then(response => {
        cb(response.data);
    });
  },

  bulkDeletePayroll(cb, projectId, employeeCategory, fromDate, toDate){
    axios.post("/data/payroll/bulk-delete", {
        project_id: projectId,
        employee_category: employeeCategory,
        from_date: fromDate,
        to_date: toDate
    }).then(response => {
        cb(response.data);
    });
  },

  bulkDeleteCutOffOvertime(cb, payload){
    axios.post("/cut-off-overtime/bulk-delete", payload).then(response => {
        cb(response.data);
    });
  },
  getCutOffOvertime(cb, employee_id, date_log, project_id){
    axios.get("/data/cut-off-overtimes/"+employee_id+"/"+date_log+"/"+project_id).then(response => {
        cb(response.data);
    });
  },
  saveCutOffOvertime(cb, payload){
    axios.post("cut-off-overtimes/store", payload).then(response => {
        cb(response.data);
    });
  },
  getCutOffOverTimes(cb, payload){
    axios.post("/cut-off-overtimes", payload).then(response => {
        cb(response.data);
    });
  },
  getPayrollDeductions(cb, payrollId){
    axios.post("/data/payroll/deductions", {id: payrollId}).then(response => {
        cb(response.data);
    });
  },
  getPayrollSpecialDeductions(cb, payrollId){
    axios.post("/data/payroll/special-deductions", {id: payrollId}).then(response => {
        cb(response.data);
    });
  },
  disableDtrMeta(cb, dtrMetaId){
    axios.post("/api/v1/dtr-metas/"+dtrMetaId+"/disable", {id: dtrMetaId}).then(response => {
        cb(response.data);
    });
  },
  getEmployeeSpecialHolidayAmount(cb, employeeId, startDate, endDate) {
    axios.get("/data/employees/"+employeeId+"/special-holiday-amount/"+startDate+"/"+endDate).then(response => {
        cb(response);
    });
  },
  generatePayrollEntry(cb, employeeIds, projectId, cutOffDate, fromDate, toDate) {
      const  payload = {
          employee_ids: employeeIds,
          project_id: projectId,
          cutoff_date: cutOffDate,
          from_date: fromDate,
          to_date: toDate
      }
      axios.post('/data/employees/generate-payroll', payload)
      .then(response => {
          cb(response)
      })
  },
  updatePayroll(cb, payload){
    axios.post('/data/payrolls/update', payload)
    .then(response => {
        cb(response)
    })
  },
  updateSpecialDeductionPayment(cb, payload){
    axios.post('/data/payrolls/special-deductions/update/payment', payload)
    .then(response => {
        cb(response)
    })
  }
}
