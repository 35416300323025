<template>
  <div class="row">
    <div
      class="modal fade"
      id="holiday-delete-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Confirm Delete</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              Are you sure you want to delete record: <b>{{ holidayData.name }} </b>?
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="deleteHoliday(),refreshData()"
            >
              Delete
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table
        id="holidays-table"
        style="width: 100%"
        class="display table table-sm table-striped"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Date</th>
            <th>Percentage</th>
            <th>Type</th>
            <th>Is Recurring?</th>
            <th>Action</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Date</th>
            <th>Percentage</th>
            <th>Type</th>
            <th>Is Recurring?</th>
            <th>Action</th>
          </tr>
        </tfoot>
      </table>
    </div>
    <button
      id="delete-holiday-modal-btn"
      data-toggle="modal"
      data-target="#holiday-delete-modal"
      class="d-none "
    ></button>
    <button
      id="edit-holiday-modal-btn"
      data-toggle="modal"
      data-target="#edit-holiday-modal"
      class="d-none"
    ></button>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import moment from "moment";
export default {
  mounted() {
    let vm = this;

    function formatDate(date) {
      var date = new Date(date);
      // return today.toLocaleDateString("en-US", { month: 'long' , day: 'long'});
      return moment(date).format("MM/DD/YYYY");
    }

    let table = $("#holidays-table").DataTable({
      serverSide: true,
      processing: true,
      ajax: "/data/holiday",
      columns: [
        { data: "id" },
        { data: "name", sortable: true },
        {
            sortable: true,
            render: function (data, type, full, meta) {
               return formatDate(full.date);
            },
        },

        {
            render: function (data, type, full, meta) {
               return full.percentage+'%';
            },
        },
         {
            sortable: true,
            render: function (data, type, full, meta) {
               return full.type;
            },
        },
        {
          sortable: false,
          render: function (data, type, full, meta) {
            return full.is_recurring ? 'true' : 'false';
          },
        },
        {
          sortable: false,
          render: function (data, type, full, meta) {
            let html =
              `
                                <button type="button" data-details='` +
              JSON.stringify(full) +
              `' class="btn btn-sm btn-warning edit-btn">
                                    <i class="fa fa-edit"></i>
                                    Edit
                                </button>

                                <button type="button" data-details='` +
              JSON.stringify(full) +
              `' class="btn btn-sm btn-danger delete-btn">
                                    <i class="fa fa-trash"></i>
                                    Delete
                                </button>
                            `;
            return html;
          },
        },
      ],
      dom: `
            <"row"
                <"col-6"l><"col-6 pr-0"f><"d-flex justify-content-end w-100 toolbar">
            >
            <rtip>
        `,
      drawCallback: function () {
        $(document).ready(function () {
          $(".edit-btn").click(function () {
            let details = $(this).data("details");
            vm.setHolidayData(details);
            vm.setUpdateHolidayDefaultData();
            $("#edit-holiday-modal-btn").click();
          });

          $(".delete-btn").click(function () {
            let details = $(this).data("details");
            vm.setHolidayData(details);
            $("#delete-holiday-modal-btn").click();
          });
        });
      },

    });

    vm.table = table;
  },
  data: function() {
    return {
      table: {},
    }
  }
   ,
  computed: {
    ...mapState({
      holidayData: (state) => state.holidays.holidayData,
    }),
  },
  methods: {
    ...mapMutations("holidays", [
      "setHolidayData",
      "setUpdateHolidayDefaultData",
    ]),
    ...mapActions("holidays", ["deleteHoliday"]),

    refreshData(){
     location.reload();
    }
  },
};
</script>
