<template>
  <div>
    <button type="button" style="width: 80px" data-toggle="modal" :id="'delete-special-deduction-btn-'+specialDeductionId" :data-target="'#delete-special-deduction-modal'+specialDeductionId" class="btn btn-sm btn-danger mb-2">
        <span class="fa fa-plus-circle mr-1"></span>
        Delete
    </button>

    <div class="modal fade" :id="'delete-special-deduction-modal'+specialDeductionId" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Delete Deduction</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form action="/data/special-deductions/delete" method="post">
          <div class="modal-body">
            <input type="hidden" name="_token" :value="csrf">
            <input type="hidden" name="id" :value="specialDeductionId">
            <h4>Are you sure you want to delete?</h4>
          </div>

          <div class="modal-footer">
              <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal"><span class="fa fa-times mr-1"></span>Cancel</button>
              <button type="submit" class="btn btn-danger btn-sm"><span class="fa fa-save mr-1"></span>Delete</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data(){
    return{
      csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }
  },

  computed: {
    ...mapState({
      specialDeductionDetails: state => state.specialDeductions.specialDeductionDetails
    }),
  },

  props: [
      'specialDeductionId'
  ]
}
</script>
