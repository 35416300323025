<template>
    <div class="form-group">
        <label>Project:</label>
        <input type="text" class="form-control form-control-sm" v-model="projectNameId" placeholder="Project Name" required @input="getProjects()"/>
        <ul class="list-group">
            <li class="list-group-item project-list mt-2" v-for="project in projects" :key="project.id" @click="setProject(project)">{{ project.name }}</li>
        </ul>
    </div>
</template>
<script>
export default {
    data(){
        return{
            projects: [],
            projectNameId: this.value ? this.value : '',
        }
    },

    methods:{
        getProjects(){
            axios.get('/data/get-projects/'+this.projectNameId)
            .then(response => {
                this.projects = response.data.data;
            });
        },

        setProject(project){
            this.projects = [];
            this.projectNameId = project.name;
            this.$emit('set-project-id', project.id);
        }
    }
}
</script>
<style scoped>
    .project-list{
        cursor: pointer;
    }
</style>
