<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="add-new-holiday-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add Holiday</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <label>Name:</label>
          <input
            type="text"
            ref="name"
            class="form-control mb-2"
            @input="handleSetName($event.target.value)"
            name="name"
            placeholder="Holiday Name"
            required
          />
          <label>Date:</label>
          <input
            type="date"
            ref="date"
            class="form-control mb-2"
            @change="handleSetDate($event.target.value)"
            name="date"
            required
          />

            <label>Type:</label>
            <select
                ref="type"
                class="form-control mb-2"
                @change="handleSetType($event.target.value)"
                name="type"
                required
            >
                <option value="legal-holiday">Legal Holiday</option>
                <option value="special-holiday">Special Holiday</option>
            </select>

           <label>Percentage:</label>
          <input
            type="number"
            step="0.01"
            ref="percentage"
            class="form-control mb-2"
            @change="handleSetPercentage($event.target.value)"
            name="percent"
            required
          />


            <div class="d-flex justify-content-left align-items center">
                <input
                    type="checkbox"
                    name="is_recurring"
                    id="is-recurring"
                    @change="setIsRecurringValue()"
                    class="mt-1"
                />
                <label class="ml-2 my-0">
                    Is recurring?
                </label>
            </div>

        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            <span class="fa fa-times mr-1"></span>Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="handleSaveHoliday()"
            data-dismiss="modal"
          >
            <span class="fa fa-save mr-1"></span>Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  methods: {
    ...mapActions("holidays", ["addHoliday"]),
    ...mapMutations("holidays", [
      "setName",
      "setDate",
      "setType",
      "setPercentage",
      "setClearInputs",
      "setIsRecurring"
    ]),

    handleSaveHoliday() {
      this.addHoliday();
      this.clearInputs();
      location.reload();
    },
    handleSetName(value) {
      this.setName(value);
    },
    handleSetDate(value) {
      this.setDate(value);
    },
    handleSetType(value) {
      this.setType(value);
    },
    handleSetPercentage(value) {
      this.setPercentage(value);
    },
    clearInputs() {
      this.$refs.name.value = "";
      this.$refs.date.value = null;
      this.$refs.percentage.value = 100;
    },
    setIsRecurringValue(){
        let val = $("#is-recurring").is(":checked");
        this.setIsRecurring(val);
    }
  },

  computed: {},
};
</script>
