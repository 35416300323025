<template>
    <div class="form-group">
        <label for="inputPosition">Position:</label>
        <div class="input-group">
            <input
                type="text"
                class="form-control form-control-sm"
                :class="{ error: error }"
                id="inputPosition"
                @input="getPositions()"
                v-model="positionName"
                required
                placeholder="Position" 
                autocomplete="off"
            />
            <div class="input-group-append">
            <button
                class="btn btn-outline-secondary btn-sm"
                data-toggle="modal"
                data-target="#position-modal"
                type="button"
            >
                <span class="fa fa-plus"></span>
            </button>
            </div>
        </div>
        <ul class="list-group">
            <li
            class="list-group-item mt-1 bg-info text-white"
            v-for="position in positions"
            :key="position.id"
            @click="setPosition(position)"
            >{{ position.name }}</li>
        </ul>
        <input type="hidden" name="position_id" v-model="positionId" />
    </div>
</template>
<script>
export default {
    data(){
        return{
            positions: [],
            positionName: '',
            positionId: 0
        }
    },

    methods:{
        getPositions() {
            axios.get("/data/positions/" + this.positionName).then(response => {
                this.positions = response.data.data;
            });
        },

        setPosition(position) {
            this.positions = [];
            this.positionName = position.name;
            this.positionId = position.id;
            this.$emit('set-position', this.positionId)
        },
    },

    props: ['error']
}
</script>
<style scoped>
    .form-control.error {
        border-color: red;
    }
</style>