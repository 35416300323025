<template>
  <div id="modal-content" ref="modalContent" class="modal-body">
    <form enctype="multipart/form-data">
      <!--1st container -->
      <div class="container-fluid">
        <div
          v-if="errorMessage"
          v-html="errorMessage"
          class="d-flex justify-content-center alert alert-danger pt-4"
        ></div>

        <div class="alert alert-success" id="success-alert">
          <button type="button" class="close" data-dismiss="alert">x</button>
          <strong>Success!</strong> Employee terminated successfully.
        </div>

        <h4>Personal Details</h4>

        <div class="row">
          <div class="col"></div>
          <div class="form-group d-flex flex-row-reverse">
            <div>
              <img
                v-if="profilePicPreviewUrl"
                :src="profilePicPreviewUrl"
                style="height: 150px; width: 150px"
              />
              <i v-else class="fas fa-user-tie fa-border" style="font-size:100px;color:black;"></i>

              <div class="d-flex m-auto">
                <input
                  type="file"
                  ref="profilePicInput"
                  style="display: none;"
                  @change="onProfilePicChange"
                />

                <button
                  type="button"
                  class="btn btn-sm btn-primary d-flex p-auto"
                  style="width: 150px; text-align:center"
                  @click="selectProfilePic"
                >
                  <span class="fa fa-portrait mt-1 mr-1"></span>Select picture
                </button>
              </div>
            </div>
          </div>
          <div class="float-right mx-4">
            <label>Employee ID:</label>
            <p class="font-weight-bold" style="font-size: 24px">{{ employee.id }}</p>
            <label>Status:</label>
            <p class="font-weight-bold" style="font-size: 24px">{{ employee.employee_status }}</p>
            <div v-if="this.isAdmin">
                <label>Added By:</label>
                <p class="font-weight-bold">{{ employee.added_by_name }}</p>
                <label>Updated By:</label>
                <p class="font-weight-bold">{{ employee.updated_by_name }}</p>
            </div>
          </div>

          <div>
            <button
              class="btn btn-sm btn-primary my-1 mx-2"
              style="width: 100px"
              @click="updateEmployee"
            >
              <span class="fa fa-save mr-1"></span>
              Update
            </button>
            <br />

            <a
              :href="'employees/'+employee.id+'/print'"
              class="btn btn-sm btn-primary my-1 mx-2"
              style="width: 100px"
            >
              <span class="fa fa-print mr-1"></span>Print
            </a>

            <employees-histories-create :employee-id="employee.id"></employees-histories-create>

            <button
              type="button"
              data-toggle="modal"
              data-target="#terminatemodalAdminPassword"
              class="btn btn-sm btn-danger my-1 mx-2"
              style="width: 100px"
            >
              <span class="fa fa-user-minus mr-1"></span>Terminate
            </button>
          </div>
        </div>

        <div class="form-group row">
          <div class="col">
            <label for="first_name">First Name:</label>
            <input type="text" class="form-control" v-model="employee.first_name" required />
          </div>

          <div class="col">
            <label for="middle_name">Middle Name:</label>
            <input type="text" class="form-control" v-model="employee.middle_name"/>
          </div>

          <div class="col">
            <label for="last_name">Last Name:</label>
            <input type="text" class="form-control" v-model="employee.last_name" required />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-2">
            <label for="contact_number">Contact Number:</label>
            <input type="text" class="form-control" v-model="employee.contact_number" />
          </div>
          <div class="col-3">
            <label for="birth_date">Birthday:</label>
            <input type="date" class="form-control" v-model="employee.birth_date" required />
            <!--
              <datepicker   v-model="birth_date" name="birth_date"></datepicker>
            -->
          </div>
          <div class="col-3">
            <label for="email">Email:</label>
            <input type="text" class="form-control" v-model="employee.email" />
          </div>
          <div class="col-4">
            <label for="address">Address:</label>
            <input type="text" class="form-control" v-model="employee.address" required />
          </div>
        </div>

        <div class="form-group row">
          <div class="col">
            <label for="birth_place">Birth Place:</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">Same as Address</span>
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    v-model="employee.check_address"
                    @change="checkBirthPlaceEdit()"
                    aria-label="Checkbox for following text input"
                  />
                </div>
              </div>
              <input type="text" class="form-control" v-model="employee.birth_place" />
            </div>
          </div>
          <div class="col-2">
            <label for="philheath_number">Philhealth #:</label>
            <input type="text" class="form-control" v-model="employee.philheath_number" />
          </div>
          <div class="col-2">
            <label for="pagibig_number">Pag-ibig #:</label>
            <input type="text" class="form-control" v-model="employee.pagibig_number" />
          </div>

          <div class="col-2">
            <label for="tin_number">TIN #:</label>
            <input type="text" class="form-control" v-model="employee.tin_number" />
          </div>

          <div class="col-2">
            <label for="sss_number">SSS #:</label>
            <input type="text" class="form-control" v-model="employee.sss_number" />
          </div>
        </div>

        <div class="form-group row">
          <div class="col-3">
            <label for="umid_number">UMID #:</label>
            <input type="text" class="form-control" v-model="employee.umid_number" />
          </div>
          <div class="col-3">
            <label for="drivers_license">Driver's License #:</label>
            <input type="text" class="form-control" v-model="employee.drivers_license" />
          </div>
          <div class="col-2">
            <label for="nation_id_number">National ID #:</label>
            <input type="text" class="form-control" v-model="employee.national_id_number" />
          </div>
          <div class="col-2">
            <label for="employee_insurance">Insurance:</label>
            <input type="text" class="form-control" v-model="employee.insurance" />
          </div>
          <div class="col-2">
            <label for="nation_id_number">PRC ID #:</label>
            <input
              type="text"
              class="form-control"
              id="nation_id_number"
              v-model="employee.prc_number"
            />
          </div>
        </div>

        <div class="form-group row">
          <div class="col-3">
            <label for="employee_civil_status">Civil Status:</label>
            <select class="form-control" v-model="employee.civil_status">
              <option
                :selected="true"
                v-bind:value="employee.civil_status"
              >{{employee.civil_status }}</option>
              <option>Single</option>
              <option>Married</option>
              <option>Widow</option>
            </select>
          </div>
          <div class="col-3">
            <label for="employee_gender">Gender:</label>
            <select class="form-control" v-model=" employee.gender">
              <option :selected="true" v-bind:value="employee.gender">{{ employee.gender }}</option>
              <option>Male</option>
              <option>Female</option>
            </select>
          </div>
          <div class="col-3">
            <label for="employee_religion">Religion:</label>
            <select class="form-control" v-model="employee.religion">
              <option :selected="true" v-bind:value="employee.religion">{{ employee.religion }}</option>
              <option>Roman Catholic</option>
              <option>Islam</option>
              <option>Evangelicals (PCEC)</option>
              <option>Iglesia ni Cristo</option>
              <option>Seventh-day Adventist</option>
              <option>Aglipayan</option>
              <option>Bible Baptist Church</option>
              <option>United Church of Christ in the Philippines</option>
              <option>Jehovah's Witnesses</option>
              <option>Non-Roman Catholic and Protestant (NCCP)</option>
              <option>Others</option>
            </select>
          </div>
        </div>
      </div>
      <!-- separator -->
      <div class="container-fluid" style="background-color: #F0F0F0; height: 1px"></div>

      <!--2nd container -->

      <div class="container-fluid mt-3">
        <h4>Employment Details</h4>
        <div class="form-group row">
          <div class="col-3">
            <label for="inputCategory">Category:</label>
            <select class="form-control" v-model="employee.employee_category">
                <option v-for="(category, index) in employeeCategories" :key="index">{{ category }}</option>
            </select>
          </div>
          <div class="col-3">
            <label for="inputEmployeeType">Type:</label>
            <select class="form-control" v-model="employee.employee_type" :disabled="!isAdmin">
              <option value="Probation">Probation</option>
              <option value="Regular">Regular</option>
              <option value="Project Base">Project Base</option>
            </select>
          </div>
          <div class="col-3">
            <label for="inputSalaryPeriod">Salary Period:</label>
            <select
              class="form-control"
              v-model="employee.salary_period"
              @change="computeHourlyRate()"
            >
              <option>Daily</option>
              <option>Weekly</option>
              <option>Monthly</option>
            </select>
          </div>
          <div class="col-3">
            <label for="inputPosition">Position:</label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                id="inputPosition"
                @input="getPositions()"
                v-model="positionName"
                required
                placeholder="Position"
                autocomplete="off"
                :disabled="!isAdmin"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  data-toggle="modal"
                  data-target="#position-modal"
                  type="button"
                >
                  <span class="fa fa-plus"></span>
                </button>
              </div>
            </div>
            <ul class="list-group">
              <li
                class="list-group-item mt-1 bg-info text-white"
                v-for="position in positions"
                :key="position.id"
                @click="setPosition(position)"
              >{{ position.name }}</li>
            </ul>
            <input type="hidden" name="position_id" v-model="employee.employee_position" />
          </div>
        </div>

        <div class="form-group row">
          <div class="col-3">
            <label>Deductions:</label>
            <div class="input-group" v-for="deduction in deductions" v-bind:key="deduction.id">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input
                    class="mr-1"
                    type="checkbox"
                    @change="setCheckDeduction(deduction, $event.target)"
                    :checked="isDeductionChecked(deduction.id)"
                  />
                  <small style="font-size:11px;">{{ deduction.deduction_name }}</small>
                </div>
              </div>
              <input
                type="text"
                class="form-control"
                :value="getDeductionAmount(deduction.id)"
                aria-label="0.00"
                @change="updateDeductionAmount(deduction.id, $event.target.value)"
              />
            </div>
          </div>

          <div class="col-3">
            <label for="inputProject">Project:</label>
            <input
              type="text"
              class="form-control"
              v-model="projectName"
              placeholder="Project Name"
              required
              @input="getProjects()"
              autocomplete="off"
              :disabled="!isAdmin"
            />
            <ul class="list-group">
              <li
                class="list-group-item employee-list mt-1 bg-info text-white"
                v-for="project in projects"
                :key="project.id"
                @click="setProject(project)"
              >{{ project.name }}</li>
            </ul>
            <input type="hidden" name="project_id" id="project-id" v-model="employee.project_id" />
          </div>
          <div class="col-2">
            <label for="inputEditSelectCompany">Company:</label>
            <select id="inputEditSelectCompany" class="form-control" v-model="employee.company_id" :disabled="!isAdmin">
              <option
                v-for="option in companies"
                v-bind:key="option.id"
                v-bind:value="option.id"
              >{{ option.name }}</option>
            </select>
          </div>
          <div class="col-2">
            <label for="inputEmployeeStatus">Employee Status:</label>
            <select class="form-control" v-model="employee.employee_status">
              <option>Active</option>
              <option>Inactive</option>
            </select>
          </div>
          <div class="col-2">
            <label for="inputLeaveCredits">Leave Credits:</label>
            <input
              class="form-control"
              type="number"
              v-model="employee.leave_creadits"
              placeholder="Credits"
            />
          </div>
        </div>

        <div class="form-group row">
          <div class="col-3"></div>
          <div class="col">
            <label for="nation_id_number">Date Hired:</label>
            <input type="date" class="form-control" v-model="employee.date_hired" />
          </div>
          <div class="col">
            <label for="inputGrossMonthlySalary">Salary:</label>
            <input
              class="form-control"
              type="text"
              placeholder="0"
              @input="computeHourlyRate($event.target.value)"
              :value="grossMonthlySalary"
              :disabled="!isAdmin"
            />
          </div>
          <div class="col">
            <label>Computed Hourly Rate:</label>
            <input
              class="form-control"
              type="text"
              placeholder="0"
              :value="computedHourlyRate"
              readonly
            />
          </div>

          <div class="col">
            <label>Allowance:</label>
            <input
              class="form-control"
              type="number"
              v-model="employee.allowance"
              placeholder="Allowance"
              :disabled="!isAdmin"
            />
          </div>
        </div>
      </div>
      <!-- separator -->
      <div class="container-fluid" style="background-color: #F0F0F0; height: 1px"></div>
      <!--3rd container -->

      <div class="container-fluid mt-3">
        <h4>Emergency Contacts</h4>
        <div class="form-group row">
          <div class="col-3">
            <label for="inputEmergencyContactName">Name:</label>
            <input class="form-control" v-model="employee.emergency_name" type="text" placeholder />
          </div>
          <div class="col-3">
            <label for="inputEmergencyContactNumber">Contact Number:</label>
            <input
              class="form-control"
              v-model="employee.emergency_contact_number"
              type="text"
              placeholder
            />
          </div>
          <div class="col-3">
            <label for="inputEmergencyContactRelation">Relation:</label>
            <input
              class="form-control"
              v-model="employee.emergency_person_relation"
              type="text"
              placeholder
            />
          </div>
          <div class="col-3">
            <label for="inputEmergencyContactRelationAddress">Address:</label>
            <input
              class="form-control"
              v-model="employee.emergency_person_address"
              type="text"
              placeholder
            />
          </div>
        </div>
      </div>

      <!-- separator -->
      <div class="container-fluid" style="background-color: #F0F0F0; height: 1px"></div>
      <!-- 4th container -->
      <div class="container-fluid mt-3">
        <h4>Educational Background</h4>

        <h5 class="mt-2">Elementary</h5>
        <div class="form-group row">
          <div class="col-4">
            <label for="inputElementarySchoolName">Name of School:</label>
            <input
              class="form-control"
              v-model="employee.ed_background_elem_school_name"
              type="text"
              placeholder
            />
          </div>
          <div class="col-3">
            <label for="inputElementarySchoolYearAttended">Year Attended:</label>
            <input
              class="form-control"
              v-model="employee.ed_background_elem_school_year"
              type="text"
              placeholder
            />
          </div>
          <div class="col-3">
            <label for="inputElementarySchoolAward">Honor/Awards:</label>
            <input
              class="form-control"
              v-model="employee.ed_background_elem_awards"
              type="text"
              placeholder
            />
          </div>
        </div>

        <h5 class="mt-2">Secondary</h5>
        <div class="form-group row">
          <div class="col-4">
            <label for="inputSecondarySchoolName">Name of School:</label>
            <input
              class="form-control"
              v-model="employee.ed_background_second_school_name"
              type="text"
              placeholder
            />
          </div>
          <div class="col-3">
            <label for="inputSecondarySchoolYearAttended">Year Attended:</label>
            <input
              class="form-control"
              v-model="employee.ed_background_second_school_year"
              type="text"
              placeholder
            />
          </div>
          <div class="col-3">
            <label for="inputSecondarySchoolAward">Honor/Awards:</label>
            <input
              class="form-control"
              v-model="employee.ed_background_second_awards"
              type="text"
              placeholder
            />
          </div>
        </div>

        <h5 class="mt-2">Tertiary</h5>
        <div
          class="form-group row"
          v-for="educational_background in educational_backgrounds"
          :key="educational_background.id"
        >
          <div class="col-3">
            <label for="inputTertiarySchoolName">Name of School:</label>
            <input
              class="form-control"
              v-model="educational_background.school_name"
              type="text"
              placeholder
            />
          </div>
          <div class="col-3">
            <label for="inputTertiarySchoolYearAttended">Degree:</label>
            <input
              class="form-control"
              v-model="educational_background.degree"
              type="text"
              placeholder
            />
          </div>
          <div class="col-2">
            <label for="inputTertiarySchoolYearAttended">Year Attended:</label>
            <input
              class="form-control"
              v-model="educational_background.year"
              type="text"
              placeholder
            />
          </div>
          <div class="col-3">
            <label for="inputTertiarySchoolAward">Honor/Awards:</label>
            <input
              class="form-control"
              v-model="educational_background.honor"
              type="text"
              placeholder
            />
          </div>
          <div class="col pl-0 ml-0 mt-4">
            <button
              class="btn btn-danger btn-sm"
              @click.prevent="deleteEducationBackground(educational_background.id);"
            >
              <span class="fa fa-trash mr-1"></span>Delete
            </button>
          </div>
        </div>

        <div class="form-group row">
          <div class="form-group col-3">
            <input
              class="form-control"
              v-model="employee.ed_background_tert_school_name"
              type="text"
              placeholder="Name of School"
            />
          </div>
          <div class="form-group col-3">
            <input
              class="form-control"
              v-model="employee.ed_background_tert_degree"
              type="text"
              placeholder="Degree/Course"
            />
          </div>
          <div class="form-group col-2">
            <input
              class="form-control"
              v-model="employee.ed_background_tert_school_year"
              type="text"
              placeholder="Year Attended"
            />
          </div>
          <div class="form-group col-3">
            <input
              class="form-control"
              v-model="employee.ed_background_tert_awards"
              type="text"
              placeholder="Honor/Awards"
            />
          </div>
          <div class="form-group col-1 pl-0 ml-0 pt-2">
            <button
              type="button"
              class="btn btn-info btn-sm"
              @click.prevent="saveEducationBackground();"
            >
              <span class="fa fa-plus-circle mr-1"></span>Save
            </button>
          </div>
        </div>

        <h5 class="mt-2">Others</h5>
        <div class="form-group row" v-for="(vocationalCourse,k) in vocational_courses" :key="k">
          <div class="col-3">
            <input class="form-control" v-model="vocationalCourse.school_name" type="text" />
          </div>
          <div class="col-3">
            <input class="form-control" v-model="vocationalCourse.name" type="text" />
          </div>
          <div class="col-2">
            <input class="form-control" v-model="vocationalCourse.year" type="text" />
          </div>
          <div class="col-3">
            <input class="form-control" v-model="vocationalCourse.honor" type="text" />
          </div>
          <div class="col pl-0 ml-0 mt-2">
            <button
              class="btn btn-danger btn-sm"
              @click.prevent="deleteVocationalCourse(vocationalCourse.id);"
            >
              <span class="fa fa-trash mr-1"></span>Delete
            </button>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-3">
            <input
              class="form-control"
              v-model="employee.vocational_name"
              type="text"
              placeholder="Name of School"
            />
          </div>
          <div class="col-3">
            <input
              class="form-control"
              v-model="employee.vocational_school_name"
              type="text"
              placeholder="Degree/Course"
            />
          </div>
          <div class="col-2">
            <input
              class="form-control"
              v-model="employee.vocational_school_year"
              type="text"
              placeholder="Year Attended"
            />
          </div>
          <div class="col-3">
            <input
              class="form-control"
              v-model="employee.vocational_awards"
              type="text"
              placeholder="Honor/Awards"
            />
          </div>
          <div class="col-1 pl-0 ml-0">
            <button
              type="button"
              class="btn btn-info btn-sm"
              @click.prevent="saveVocationalBackground();"
            >
              <span class="fa fa-plus-circle mr-1"></span>Save
            </button>
          </div>
        </div>
      </div>

      <!-- separator -->
      <div class="container-fluid" style="background-color: #F0F0F0; height: 1px"></div>
      <!-- 5th container -->
      <div class="container-fluid mt-3">
        <h4 class="d-inline">Attached Documents</h4>
        <button
          type="button"
          class="btn btn-primary d-inline ml-3"
          data-toggle="modal"
          data-target="#attach-file-modal"
        >
          <span class="fa fa-paperclip mr-1"></span>Attach File
        </button>

        <div class="form-group row mt-3">
          <div v-for="doc in attachFiles" :key="doc.id" class="mx-3" style="width: 90px">
            <i
              class="fas fa-times-circle d-flex justify-content-end"
              @click="removeAttachedDocument(doc.id)"
            ></i>
            <a :href="doc.file_path" target="_blank">
              <i class="fas fa-paperclip fa-border" style="font-size:48px;color:black;"></i>
            </a>
            <br />
            <label>{{ doc.name }}</label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          @click="clearAttachFilesVariable(), clearFormInputsData();"
        >
          <span class="fa fa-times mr-1"></span>Close
        </button>

        <button type="button" class="btn btn-primary" @click="updateEmployee">
          <span class="fa fa-save mr-1"></span>Update
        </button>
      </div>
    </form>
  </div>
</template>

<script>

import {mapState} from 'vuex';

export default {
  mounted() {
    console.log("edit employee component mounted");

    // get deductions
    axios.get("/api/v1/deductions").then((response) => {
      this.deductions = response.data.data;
    });

    $(document).ready(function () {
      $("#success-alert").hide();
    });

    this.getDeductions();

  },

  created(){
    console.log('deductions');
    console.log(this.deductions);
  },

  data() {
    // TODO
    return {
      profilePicture: null,
      employee: {
        first_name: "",
        middle_name: "",
        last_name: "",
        contact_number: "",
        address: "",
        birth_date: null,
        birth_place: "",
        philheath_number: "",
        pagibig_number: "",
        tin_number: "",
        sss_number: "",
        umid_number: "",
        drivers_license: "",
        national_id_number: "",
        prc_number: "",
        employee_category: null,
        employee_type: null,
        salary_period: null,
        employee_position: null,
        project_id: null,
        employee_status: "Active",
        leave_creadits: null,
        gross_monthly_salary: null,
        date_hired: "",
        hourly_rate: null,
        allowance: 0,
        emergency_name: null,
        emergency_contact_number: null,
        emergency_person_relation: null,
        emergency_person_address: null,
        religion: "",
        insurance: "",
        civil_status: "",
        gender: "",
        company_id: "",
        email: "",

        ed_background_elem_school_name: null,
        ed_background_elem_school_year: null,
        ed_background_elem_awards: null,

        ed_background_second_school_name: null,
        ed_background_second_school_year: null,
        ed_background_second_awards: null,

        ed_background_tert_school_name: null,
        ed_background_tert_school_year: null,
        ed_background_tert_degree: null,
        ed_background_tert_awards: null,

        vocational_name: null,
        vocational_school_name: null,
        vocational_school_year: null,
        vocational_awards: null,
      },

      errorMessage: null,
      profilePicPreviewUrl: null,
      attachedDocuments: Array(),
      projects: [],
      positions: [],
      deductions: [],
      selectedOldDeductions: [],
      selectedDeductions: [],
      projectName: "",
      positionName: "",
      check_address: false,
      educational_backgrounds: [],
      vocational_courses: [],
      companies: [],
    };
  },

  computed: {
    grossMonthlySalary(){
      if(this.isAdmin){
        return this.employee.gross_monthly_salary;
      }else{
        return "*****";
      }
    },

    computedHourlyRate(){
      if(this.isAdmin){
        return this.employee.hourly_rate;
      }else{
        return "*****";
      }
    },

    ...mapState({
      isAdmin: state => state.user.isAdmin
    }),
  },

  methods: {
    setCheckDeduction(entry, elem){
      let deduction = this.selectedDeductions.find(x => x.deduction_id === entry.id);
      if($(elem).is(":checked")){
        if(!deduction){
          this.selectedDeductions.push({deduction_id: entry.id, deduction_amount: entry.amount});
        }
      }else{
        if(deduction){
          let index = this.deductions.findIndex(x => x.deduction_id === entry.id);
          this.selectedDeductions.splice(index,1);
        }
      }
    },

    isDeductionChecked(id){
      let deduction = this.selectedDeductions.find(x => x.deduction_id === id)
      if(deduction){
        return true;
      }
      return false;
    },

    getDeductionAmount(id){
      let deduction = this.selectedDeductions.find(x => x.deduction_id === id);
      if(deduction){
        return deduction.deduction_amount;
      }
      return 0;
    },


    updateDeductionAmount(id, amount){
     let deduction = this.selectedDeductions.find(x => x.deduction_id === id);
     let index = this.selectedDeductions.findIndex(x => x.deduction_id === id);
     deduction.deduction_amount = amount;
     this.selectedDeductions[index] = deduction;
    },

    setTerminateEmployee() {
      axios
        .post("/employee/terminate", {
          id: this.employee.id,
        })
        .then((response) => {
          //show alert message
          $("#success-alert")
            .fadeTo(2000, 500)
            .slideUp(500, function () {
              $("#success-alert").slideUp(500);
              $("#refreshButton").click(); //refresh datatable
              $("#editEmployeeModal").modal("hide");
            });
        });
    },

    saveEducationBackground() {
      if (
        this.ed_background_tert_school_name !== null ||
        this.ed_background_tert_degree !== null
      ) {
        axios
          .post("/educational-backgrounds/store", {
            employee_id: this.employee.id,
            ed_background_tert_school_name: this.employee
              .ed_background_tert_school_name,
            ed_background_tert_school_year: this.employee
              .ed_background_tert_school_year,
            ed_background_tert_degree: this.employee.ed_background_tert_degree,
            ed_background_tert_awards: this.employee.ed_background_tert_awards,
          })
          .then((response) => {
            this.employee.ed_background_tert_school_name = "";
            this.employee.ed_background_tert_school_year = "";
            this.employee.ed_background_tert_awards = "";
            this.employee.ed_background_tert_degree = "";
            this.loadEmployeeData(this.employee.id); //refresh data after save educational background
          });
      }
    },

    saveVocationalBackground() {
      axios
        .post("/vocational-courses/store", {
          employee_id: this.employee.id,
          vocational_name: this.employee.vocational_name,
          vocational_school_name: this.employee.vocational_school_name,
          vocational_school_year: this.employee.vocational_school_year,
          vocational_awards: this.employee.vocational_awards,
        })
        .then((response) => {
          this.employee.vocational_name = "";
          this.employee.vocational_school_name = "";
          this.employee.ed_background_tert_school_year = "";
          this.employee.vocational_school_year = "";
          this.loadEmployeeData(this.employee.id); //refresh data after save educational background
        });
    },

    deleteEducationBackground(education_id) {
      axios
        .post("/educational-backgrounds/delete", {
          id: education_id,
        })
        .then((response) => {
          this.loadEmployeeData(this.employee.id); //refresh data after save educational background
        });
    },

    deleteVocationalCourse(vocational_id) {
      axios
        .post("/vocational-courses/delete", {
          id: vocational_id,
        })
        .then((response) => {
          this.loadEmployeeData(this.employee.id); //refresh data after save educational background
        });
    },

    clearFormInputsData() {
      Object.assign(this.$data, this.$options.data()); //reset data property
      this.selectedDeductions = [];
    },

    checkBirthPlaceEdit() {
      if (this.employee.check_address == true) {
        this.employee.birth_place = this.employee.address;
      } else {
        this.employee.birth_place = "";
      }
    },

    getPositions() {
      axios.get("data/positions/" + this.positionName).then((response) => {
        this.positions = response.data.data;
      });
    },

    setPosition(position) {
      this.positions = [];
      this.positionName = position.name;
      this.employee.employee_position = position.id;
    },

    getProjects() {
      axios.get("/api/v1/projects/" + this.projectName).then((response) => {
        this.projects = response.data.data;
      });
    },

    setProject(project) {
      this.projects = [];
      this.projectName = project.name;
      this.employee.project_id = project.id;
      this.employee.company_id = project.company_id ? project.company_id : null;

      if (this.employee.company_id == "" || this.employee.company_id == null) {
        //enable company input and load list
        axios.get("/companies/show").then((response) => {
          this.companies = [];
          this.companies = response.data.data;
        });
      } else {
        //load project default company
        axios
          .get("data/companies/" + this.employee.company_id + "/details")
          .then((response) => {
            this.companies = response.data;
          });
      }
    },

    computeHourlyRate(e) {
      const filters = this.$options.filters;
      let value = 0.0;

      if(this.isAdmin){
        this.employee.gross_monthly_salary = Number.isNaN(parseFloat(e)) ? 0 : parseFloat(e);
      }

      if (
        this.employee.salary_period == "Daily" ||
        this.employee.salary_period == "Weekly"
      ) {
        value =
          this.employee.gross_monthly_salary > 0
            ? this.employee.gross_monthly_salary / 8
            : 0;
      } else {
        value =
          this.employee.gross_monthly_salary > 0
            ? this.employee.gross_monthly_salary / (30 * 8)
            : 0;
      }




      this.employee.hourly_rate = value.toFixed(2);


    },

    selectProfilePic($event) {
      this.$refs.profilePicInput.click();
    },
    selectDocuments(event) {
      this.$refs.attachedDocuments.click();
    },
    onProfilePicChange(data) {
      const file = data.target.files[0];
      this.profilePicPreviewUrl = URL.createObjectURL(file);
      this.profilePicture = file;
    },
    toFormData(obj, form, namespace) {
      let fd = form || new FormData();
      let formKey;

      for (let property in obj) {
        if (obj.hasOwnProperty(property) && obj[property]) {
          if (namespace) {
            formKey = namespace + "[" + property + "]";
          } else {
            formKey = property;
          }

          // if the property is an object, but not a File, use recursivity.
          if (obj[property] instanceof Date) {
            fd.append(formKey, obj[property].toISOString());
          } else if (
            typeof obj[property] === "object" &&
            !(obj[property] instanceof File)
          ) {
            this.toFormData(obj[property], fd, formKey);
          } else {
            // if it's a string or a File object
            fd.append(formKey, obj[property]);
          }
        }
      }

      return fd;
    },
    removeAttachedDocument(id) {
      let attachFilesCopy = this.attachFiles;
      $.each(this.attachFiles, function (index, value) {
        if (value && value.id == id) {
          attachFilesCopy.splice(index, 1);
        }
      });

      this.attachFiles = attachFilesCopy;
    },
    updateEmployee() {
      this.employee.attachFiles = this.attachFiles;
      this.employee.profilePicture = this.profilePicture;
      this.employee.selectedDeductions = this.selectedDeductions;
      console.log(JSON.stringify(this.selectedDeductions));
      let formData = this.toFormData(this.employee);
      axios({
        method: "post",
        url: "/employees/update",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        console.log(response.data);
        // TODO display error message to user
        // error
        if (response.data.status === "ok") {
          console.log("employee updated!");
          $("#editEmployeeModal").modal("toggle");
          $("#refreshButton").click(); //refresh datatable
          this.clearFormInputsData(); //clear form inputs
        } else {
          console.log(response.data);
          const messages = response.data.message;
          this.errorMessage = " <h4>Please fix:</h4>";
          this.errorMessage = this.errorMessage + "<ul>";
          for (var key in messages) {
            if (!messages.hasOwnProperty(key)) continue;
            var obj = messages[key];
            this.errorMessage =
              this.errorMessage + "<li>" + key + " - " + obj + "</li>";
          }
          $("#editEmployeeModal").animate({ scrollTop: 0 }, 300, "linear");
        }

        this.getDeductions();

      });


    },

    getDeductions(){
      axios.get("/api/v1/deductions").then((response) => {
        this.deductions = response.data.data;
      });
    },


    loadEmployeeData(id) {
      console.log("loadEmployeeData " + id);

      // get employee data
      axios.get("/api/v1/employees/" + id + "/show").then((response) => {
        if (response.data.status === "ok") {
          var employee = response.data.data;
          this.employee = employee;
          this.projectName = this.employee.project.name;
          this.positionName = this.employee.position
            ? this.employee.position.name
            : "";
          this.profilePicPreviewUrl = null
          if(employee.avatar_path !== null){
            this.profilePicPreviewUrl = "storage/" + employee.avatar_path;
          }
          this.selectedDeductions = this.employee.employee_deductions;

          this.$emit(
            "edit-employee-load-attach-files",
            employee.attached_files
          );
          this.educational_backgrounds = employee.educational_backgrounds;

          this.vocational_courses = employee.vocational_courses;
          this.companies = [];
          employee.company
            ? this.companies.push(employee.company)
            : (this.companies = []);
          this.checkCompanyState();
        }
      });
    },

    clearAttachFilesVariable() {
      this.$emit("edit-employee-load-attach-files", []);
      this.errorMessage = null;
    },

    printDetails() {
      window.print();
    },

    checkCompanyState() {
      if (this.companies.length > 0) {
        $("#inputEditSelectCompany").prop("disabled", true);
      } else {
        $("#inputEditSelectCompany").prop("disabled", false);
        axios.get("/companies/show").then((response) => {
          this.companies = [];
          this.companies = response.data.data;
        });
      }
    },
  },

  props: ["attachFiles", "employee_id", "employeeCategories"],
};
</script>
