<template>
  <div class="col-3">
    <div class="mb-2">
      <button class="btn btn-sm btn-success" @click="clearSelectedEmployees()">
        <i class="fa fa-undo"></i> Clear
      </button>
      <button
        class="btn btn-sm btn-primary"
        data-toggle="modal"
        data-target="#add-employee-payroll-process-modal"
      >
        <i class="fa fa-plus"></i> Add
      </button>
    </div>

    <div class="employee-table">
      <table class="table table-bordered" id="selected-employee">
          <thead>
            <tr>
                <th>Action</th>
                <th>ID</th>
                <th>Name</th>
            </tr>
          </thead>
          <tbody>
          <tr
            v-for="(employee, index) in selectedEmployees"
            :key="employee.id"
            v-bind:class="{'selected': selectedEmployee!=null && selectedEmployee.id == employee.id}"

          >
            <td>
                <button
                    class="btn"
                    @click="removeEmployee(index)"
                >
                    <i class="fa fa-times red"></i>
                </button>
            </td>
            <td
                class="employee-item"
                @click="changeSelectedEmployee(employee)"
            >
                {{employee.id}}
            </td>
            <td
                @click="changeSelectedEmployee(employee)"
            >
                {{employee.last_name}}, {{employee.first_name}} {{ employee.middle_name ? employee.middle_name.charAt(0)+'.' : ''}}
            </td>
          </tr>
          </tbody>
      </table>
    </div>
  </div>
</template>
<script>
  import { mapState, mapMutations, mapActions } from 'vuex'

  export default {

    computed: {
      ...mapState({
          selectedEmployees: state => state.payroll.selectedEmployees,
          selectedEmployee: state => state.payroll.selectedEmployee,
          payrollEntries: state => state.payroll.payrollEntries
      }),
    },

    watch: {
        selectedEmployees: function (oldVal, newVal) {

            $('#selected-employee').DataTable().destroy();

            setTimeout(function(){
                $('#selected-employee').DataTable({
                    searching: false,
                    paging: false,
                    info: false
                });
            }, 1000)


        }
    },

    methods: {
      changeSelectedEmployee(employee) {
        this.toggleIsLoading();

        const entry = this.payrollEntries.find(x => x.employee_id === employee.id)
        this.setSelectedEmployee(entry)

        // const vm = this;
        // setTimeout(function(){
        //     vm.toggleIsLoading();
        // }, 500)


        // if (this.fromDateRange !== null && this.toDateRange !== null) {
        //   this.getEmployeeDtrSummary(employee.id);
        // }

        // this.getEmployeeDeductions(employee.id);
        // this.getEmployeeSpecialDeductions(employee.id);
        console.log(entry);
        this.getEmployeeDeductions(entry.id);
        this.getEmployeeSpecialDeductions(employee.id);
        // this.getEmployeeAllowance();
        // this.getEmployeeSpecialHolidayAmount();
      },

      removeEmployee (index) {
          this.selectedEmployees.splice(index, 1)
      },

      ...mapActions('payroll', [
        'getEmployeeDtrSummary',
        'getEmployeeDeductions',
        'getEmployeeSpecialDeductions',
        'getEmployeeAllowance',
        'getEmployeeSpecialHolidayAmount'
      ]),

      ...mapMutations('payroll', [
        'clearSelectedEmployees',
        'setSelectedEmployee',
        'toggleIsLoading'
      ]),

      ...mapActions('dtr', [
        'getEmployee'
      ])
    }
}
</script>
<style scoped>
  .employee-table {
    height: 500px;
    overflow-y: scroll;
  }

  table tr th{
    top: 0;
    position: sticky;
    background-color: #dee2e6;
  }

  table tr td {
    cursor: pointer;
  }

  .selected{
    background-color:  #dee2e6;
  }

  .red {
      color: red;
  }
</style>
