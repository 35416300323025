<template>
<div>
    <button type="button" id="edit-cash-advance-details-btn" class="d-none btn btn-primary" data-toggle="modal" data-target="#edit-cash-advance-details-modal">Edit Cash Details</button>
    <div class="modal fade" id="edit-cash-advance-details-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Edit Cash Advance Details</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form action="/cash-advances/update" method="post">
                    <div class="modal-body">
                        <input type="hidden" :value="cashAdvanceId" name="id"/>
                        <div class="form-group">
                            <label>Amount:</label>
                            <input type="text" v-model="cashAdvance.amount" name="amount" placeholder="0.0" class="form-control form-control-sm"/>
                        </div>

                        <div class="form-group">
                            <label>Date:</label>
                            <input type="datetime-local" v-model="cashAdvance.date" name="date" class="form-control form-control-sm"/>
                        </div>

                        <div class="form-group">
                            <label>Terms:</label>
                            <input type="number" v-model="cashAdvance.terms" name="terms" class="form-control form-control-sm"/>
                        </div>
                        
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal" aria-label="Close"><span class="fa fa-times mr-1"></span>Cancel</button>
                        <button type="submit" class="btn btn-sm btn-primary"><span class="fa fa-save mr-1"></span>Update</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    data(){
        return{
            cashAdvance: {
                id: 0,
                amount: 0,
                date: '',
                terms: 0
            }
        }
    },

    methods:{
        getCashAdvanceDetails(id){
            axios.get('/cash-advances/'+id+'/details')
            .then(response => {
                this.cashAdvance = response.data;
                this.cashAdvance.date = response.data.date.replace(' ', 'T');
            });
        }
    },

    props: ['cashAdvanceId']
}
</script>
