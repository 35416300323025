<template>
  <!-- process payroll modal -->
  <div
    class="modal fade"
    data-backdrop="static"
    data-keyboard="false"
    id="process-payroll-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div v-bind:class="largeModal ? 'modal-dialog modal-xl' : 'modal-dialog modal-md'" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Process New Payroll Period
          </h5>
          <button
            type="button"
            class="close"
            id="modal-close-button"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div v-if="!isDateRangeSet">
            <div class="row">
              <div class="col-sm-4">
                <label>Cut-off Overtime:</label>
              </div>

              <div class="col-sm-6">
                <input
                  type="date"
                  @change="
                    setProcessPayrollCutOffOvertimeDate($event.target.value)
                  "
                />
              </div>
            </div>

            <div class="row">
              <div class="col-sm-4">
                <label>From:</label>
              </div>

              <div class="col-sm-6">
                <input type="date" v-model="fromDateRange" />
              </div>
            </div>

            <div class="row">
              <div class="col-sm-4">
                <label>To:</label>
              </div>

              <div class="col-sm-6">
                <input
                  type="date"
                  v-model="toDateRange"
                  min="2020-01-01"
                  :max="maxDateRange"
                />
              </div>
            </div>

            <div class="d-flex justify-content-center">
              <div>
                <p class="text-danger">{{ dateRangeErrorMessage }}</p>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="row d-flex flex-row align-items-center">
              <div class="d-flex flex-column mr-4">
                <h3>Period: {{ period }}</h3>
              </div>
              <div class="ml-auto">
                <!-- <button
                  class="btn btn-primary"
                  style="width: 200px"
                  @click="prepareProcessPayroll()"
                >
                  PROCESS
                </button> -->
              </div>
            </div>
            <div class="row mt-4">
              <payroll-add></payroll-add>

              <div class="col-9 p-4 border">
                <loading
                  :active.sync="isLoading"
                  :can-cancel="false"
                  :is-full-page="false"
                >
                </loading>
                <payroll-summary></payroll-summary>

                <div v-if="selectedEmployee" class="row">
                  <div class="col">
                    <payroll-deductions></payroll-deductions>
                  </div>
                  <div class="col">
                    <h6><strong>Payroll Summary</strong></h6>
                    <table class="table table-bordered">
                      <tbody>
                        <template v-if="selectedEmployee.employee.employee_category == 'Admin'">
                            <tr>
                                <td>Work Days:</td>
                                <td>
                                    <b>{{ selectedEmployee.total_days }} </b>
                                </td>
                            </tr>

                            <tr>
                                <td>Sundays Count:</td>
                                <td>
                                    <b> {{ selectedEmployee.sundays_count }} </b>
                                </td>
                            </tr>

                            <tr>
                                <td class="bot-line">Salary Days:</td>
                                <td class="bot-line">
                                    <b>{{ selectedEmployee.salary_days }} </b>
                                </td>
                            </tr>
                        </template>

                        <tr>
                          <td>Hourly Rate:</td>
                          <td>
                            <b
                              >{{
                                selectedEmployee.employee.hourly_rate
                                  | numeral("0,0.00")
                              }}</b
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>Cutoff Overtime Pay:</td>
                          <td>
                            <b>
                                {{ selectedEmployee.cutoff_total_amount | numeral("0,0.00") }}
                            </b>
                          </td>
                        </tr>
                        <tr>
                          <td>Gross salary:</td>
                          <td>
                            <b>{{ selectedEmployee.gross_salary | numeral("0,0.00") }}</b>
                          </td>
                        </tr>
                        <tr>
                          <td>Allowance:</td>
                          <td>
                            <b>{{ selectedEmployee.employee.allowance | numeral("0,0.00") }}</b>
                          </td>
                        </tr>
                        <tr>
                          <td>Legal Holiday:</td>
                          <td>
                            <b>{{ selectedEmployee.legal_holiday_total_amount | numeral("0,0.00") }}</b>
                          </td>
                        </tr>
                        <tr>
                          <td>Special Holiday:</td>
                          <td>
                            <b>{{ selectedEmployee.special_holiday_total_amount | numeral("0,0.00") }}</b>
                          </td>
                        </tr>
                        <tr>
                          <td>Total Deductions:</td>
                          <td>
                            <b>
                                {{ selectedEmployee.total_deductions | numeral("0,0.00") }}
                            </b>
                          </td>
                        </tr>
                        <tr>
                          <td>Total Penalties:</td>
                          <td>
                            <b>
                                {{ selectedEmployee.total_penalty | numeral("0,0.00") }}
                            </b
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>OT Discrepancy Hours:</td>
                          <td>
                            <input
                              type="number"
                              class="form-control"
                              :value="selectedEmployee.ot_discrepancy_hour"
                              @change="setupOtDiscrepancyHour"
                              placeholder="0.00"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>OT Discrepancy Amount:</td>
                          <td>
                            <input
                              type="number"
                              class="form-control"
                              :value="selectedEmployee.ot_discrepancy_amount"
                              readonly="readonly"
                              placeholder="0.00"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Other Discrepancy Amount:</td>
                          <td>
                            <input
                              type="number"
                              class="form-control"
                              :value="selectedEmployee.discrepancy_amount"
                              @change="setupDiscrepancy"
                              placeholder="0.00"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Net Salary:</td>
                          <td>
                            <b>{{ selectedEmployee.net_salary | numeral("0,0.00") }}</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span class="fa fa-times mr-1"></span>Close
          </button>
          <button
            v-if="!isDateRangeSet"
            type="button"
            class="btn btn-sm btn-primary"
            @click="changeModalSize(); createNewPayrollProcess();"
          >
            <span class="fa fa-plus mr-1"></span>Create
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment, { min } from "moment";
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import Loading from "vue-loading-overlay";
import axios from 'axios'
export default {
  mounted() {
    console.log("ProcessPayroll mounted");
    this.setMaxPeriodDate();
  },

  data: function () {
    return {
      table: {},
      isDateRangeSet: false,
      dateRangeErrorMessage: "",
      salaryPeriod: "monthly",
      fromDateRange: null,
      toDateRange: null,
      dtrSummary: [],
      // payroll summary
      houryRate: 0,
      // grossSalary: 0,
      // netSalary: 0,
      // totalDeductions: 0,
      maxDateRange: null,
      employeesAdjustments: [],
      selectedEmployeeDtrSummary: [],
      selectedEmployeeSpecialDeductions: [],
      largeModal: false,
      employeePayrollData: {}
    };
  },
  computed: {
    period: function () {
      // convert date range to readable text

      return moment(this.fromDateRange)
        .format("MMM DD")
        .concat(" to ")
        .concat(moment(this.toDateRange).format("MMM DD, YYYY"));
    },

    ...mapState({
      selectedEmployees: (state) => state.payroll.selectedEmployees,
      selectedEmployee: (state) => state.payroll.selectedEmployee,
      adjustments: (state) => state.payroll.adjustments,
      employeeDeductions: (state) => state.payroll.employeeDeductions,
      employeeSpecialDeductions: (state) => state.payroll.employeeSpecialDeductions,
      totalDeductions: (state) => state.payroll.totalDeductions,
      isLoading: (state) => state.payroll.isLoading,
      employeeSummaries: (state) => state.payroll.employeeSummaries,
      employeeAllowance: (state) => state.payroll.employeeAllowance,
      dtrSummaries: (state) => state.payroll.dtrSummaries,
      fromDate: (state) => state.payroll.fromDate,
      toDate: (state) => state.payroll.toDate,
    }),

    ...mapGetters("payroll", {
      selectedEmployeeTotalDeductions: "selectedEmployeeTotalDeductions",
      selectedEmployeeTotalPenalties: "selectedEmployeeTotalPenalties",
      grossSalary: "grossSalary",
      cutOffOvertimeHour: "cutOffOvertimeHour",
      netSalary: "netSalary",
      discrepancyAmount: "discrepancyAmount",
      otDiscrepancyHour: "otDiscrepancyHour",
      otDiscrepancyAmount: "otDiscrepancyAmount",
      holidayAmount: "holidayAmount"
    }),

    dailyWeeklyMonthly: function () {
      let isDaily = this.selectedEmployee.employee.salary_period === "Daily";
      let isWeekly = this.selectedEmployee.employee.salary_period === "Weekly";
      return isDaily || isWeekly ? "Daily" : "Monthly";
    },
  },

  watch: {
    selectedEmployee(value, oldValue) {
        if(value){
            this.getSelectedEmployeePayrollData(value.employee_id);
        }
    },
  },

  methods: {
    ...mapMutations("payroll", [
      "setDateRange",
      "toggleIsLoading",
      "setDiscrepancies",
      'setOtDiscrepancyHours',
      "setOtDiscrepancyAmounts",
      "setGrossSalaries",
      "setEmployeeNetSalaries",
      "adjustNetSalary",
      "setProcessPayrollCutOffOvertimeDate",
    ]),

    ...mapActions("payroll", [
        "processPayroll",
        "getCutOffOvertimes",
        "updatePayroll"
    ]),

    setupOtDiscrepancyHour(e) {
        let payload = {
            id: this.selectedEmployee.id,
            field: 'ot_discrepancy_hour',
            value: e.target.value
        }
        this.updatePayroll(payload)

    //   this.setOtDiscrepancyHours(e.target.value);
    //   this.setOtDiscrepancyAmounts(parseInt(e.target.value) * this.selectedEmployee.hourly_rate);
    //   this.setGrossSalaries();
    //   this.setEmployeeNetSalaries();
      // this.adjustNetSalary(e.target.value);
    },

    // setupOtDiscrepancyAmount(e) {
    //   this.setOtDiscrepancyAmounts(e.target.value);
    //   this.setGrossSalaries();
    //   this.setEmployeeNetSalaries();
    //   // this.adjustNetSalary(e.target.value);
    // },

    setupDiscrepancy(e) {
        const payload = {
            id: this.selectedEmployee.id,
            field: 'discrepancy_amount',
            value: e.target.value
        }
        this.updatePayroll(payload)
    //   this.setDiscrepancies(e.target.value);
    //   this.setGrossSalaries();
    //   this.setEmployeeNetSalaries();
      // this.adjustNetSalary(e.target.value);
    },

    prepareProcessPayroll() {
      let count = 0;
      let vm = this;
      let interval = 4000;
      this.showGenerateDialog();
      // vm.toggleIsLoading();
      $(".employee-item").each(function () {
        let item = $(this);
        setTimeout(function () {
          item.click();
        }, count * interval);
        count++;
      });

      count++;

      setTimeout(function () {
        vm.processPayroll();
        setTimeout(function () {
          vm.hideGenerateDialog();
        }, interval);
      }, count * interval);
    },

    createNewPayrollProcess() {
      // check if date range is valid
      if (this.fromDateRange == null || this.toDateRange == null) {
        this.dateRangeErrorMessage = "Date range is required";
      } else {
        this.setDateRange({
          fromDate: this.fromDateRange,
          toDate: this.toDateRange,
        });
        this.isDateRangeSet = true;
      }
    },

    setMaxPeriodDate() {
      // set max date range
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1; //January is 0!
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      this.maxDateRange = yyyy + "-" + mm + "-" + dd;
    },

    formatToTime(minutes) {
      var hours = minutes / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return rhours.pad() + ":" + rminutes.pad();
    },

    getSelectedEmployeeAdjustment() {
      return this.employeesAdjustments.find(
        (a) => a.employeeId === this.selectedEmployee.employee_id
      );
    },
    showGenerateDialog() {
      // Trigger generate dialog
      this.$dialog
        .alert("Generating payroll. Please wait.", {
          okText: "Cancel",
        })
        .then(function (dialog) {
          console.log("Closed");
        });
    },
    hideGenerateDialog() {
      this.$dialog.destroy();
      this.$dialog
        .alert("Payroll complete!", { okText: "Close" })
        .then(function (dialog) {
          $("#modal-close-button").click();

          window.location.reload();
        });
    },
    changeModalSize() {
      this.largeModal = true;
      console.log(this.largeModal);
    },

    getSelectedEmployeePayrollData(employeeId){
        let link = '/data/payroll/'+employeeId+'/'+this.fromDate+'/'+this.toDate+'/employees';
        axios.get(link).then((response) => {
            console.log(response.data);
        })
    }
  },

  props: ["employees"],

  components: {
    Loading,
  },
};
</script>
<style scoped>
.employee-item {
  cursor: pointer;
}
.selected {
  background: #005086;
  color: white;
}

.bot-line{
    border-bottom: 5px solid black;
}
</style>
