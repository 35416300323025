<template>
  <div class="form-group" v-if="importStatus">
    <ol class="breadcrumb" style="background-color: #cc1d00">
      <li class="breadcrumb-item active" style="color: white">{{ importStatus }} <button class="btn btn-sm btn-primary mb-3" style="margin-top: 10px" onClick="window.location.reload();">RE-CHECk</button></li>
    </ol>
  </div>
</template>
<script>
export default {
  mounted() {
    this.checkImportStatus();
  },
  data() {
    return {
        importStatus: null
    }
  },
  methods: {
    checkImportStatus() {
      axios.get("/api/v1/dtr-import-status").then((response) => {
        console.log(response.data);
        this.dtrImport = response.data.data;
        if(this.dtrImport != null && this.dtrImport.status == 'ONGOING'){
            this.importStatus = "Dtr import is ONGOING. Please wait until done. " + this.dtrImport.created_at;
        }
        console.log(this.importStatus);
      });
    },
  },
};
</script>