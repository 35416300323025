<template>
  <div class="row">
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true">
    </loading>
    <div class="table-responsive">
      <table
        id="cut-off-overtime-table"
        style="width: 100%"
        class="table table-striped table-sm"
      >
        <thead>
          <tr>
            <th>Employee ID</th>
            <th>Employee Name</th>
            <th>Project</th>
            <th>Employee Category</th>
            <th>Total Hours</th>
            <th>Start Datetime</th>
            <th>End Datetime</th>
            <th>Action</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <th>Employee ID</th>
            <th>Employee Name</th>
            <th>Project</th>
            <th>Employee Category</th>
            <th>Total Hours</th>
            <th>Start Datetime</th>
            <th>End Datetime</th>
            <th>Action</th>
          </tr>
        </tfoot>
      </table>
    </div>

    <cut-off-overtime-delete />
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import moment from "moment";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  mounted() {
    let vm = this;
    let startDate = 0;
    let endDate = 0;

    this.getProjects();

    function formatDate(date) {
      var date = new Date(date);
      // return today.toLocaleDateString("en-US", { month: 'long' , day: 'long'});
      return moment(date).format("MM/DD/YYYY hh:mm:ss");
    }

    var table = $("#cut-off-overtime-table").DataTable({
      serverSide: true,
      processing: true,
      ajax: "/data/cut-off-overtime/entries",
      lengthMenu: [
        [25, 100, -1],
        [25, 100, "All"],
      ],
      buttons: [
        {
          extend: "excelHtml5",
          text: '<i class="fa fa-file-excel fa-2x"></i>',
          titleAttr: "Excel",
          title: "Cut-Off Overtime",
          exportOptions: {
            modifier: {
              search: "applied",
              order: "applied",
            },
          },
        },
        {
          extend: "csvHtml5",
          text: '<i class="fa fa-file-csv fa-2x"></i>',
          titleAttr: "CSV",
          title: "Cut-Off Overtime",
          exportOptions: {
            modifier: {
              search: "applied",
              order: "applied",
            },
          },
        },
        {
          extend: "pdfHtml5",
          text: '<i class="fa fa-file-pdf fa-2x"></i>',
          titleAttr: "PDF",
          title: "Cut-Off Overtime",
          exportOptions: {
            modifier: {
              search: "applied",
              order: "applied",
            },
          },
        },
        {
          extend: "print",
          text: '<i class="fa fa-print fa-2x"></i>',
          title: "Cut-Off Overtime",
          exportOptions: {
            modifier: {
              search: "applied",
              order: "applied",
            },
          },
          customize: function (win) {
            $(win.document.body).addClass("white-bg");
            $(win.document.body).css("font-size", "10px").prepend("");
            $(win.document.body)
              .find("table")
              .addClass("compact")
              .css("font-size", "inherit");
          },
        },
      ],
      columns: [
        { data: "employee_id" },
        {
          data: "full_name",
          sortable: true,
        },
        {
          data: "project",
        },
        {
          data: "employee_category",
        },
        {
          data: "hours",
        },
        {
          data: "start_datetime",
          sortable: true,
          render: function (data, type, full, meta) {
            return formatDate(full.start_datetime);
          },
        },
        {
          data: "end_datetime",
          sortable: true,
          render: function (data, type, full, meta) {
            return formatDate(full.end_datetime);
          },
        },
        {
          sortable: false,
          render: function (data, type, full, meta) {
            var html =
              `
                                <button type="button" class="btn btn-danger btn-sm delete-cut-off-overtime" data-id='` +
              full.id +
              `'>
                                    <span class="fa fa-trash mr-1"></span>
                                    Delete
                                </button>
                            `;

            return html;
          },
        },
      ],
      dom: `
                        <"row"
                            <"col-sm-6"><"col-sm-6"f>
                        > +
                        <"row"
                            <col-sm-4"B><"col-sm-10 toolbar">
                        >
                        <rtip>
                    `,

      drawCallback: function (settings) {
        $(".delete-cut-off-overtime").click(function () {
          vm.setDeleteCutOffOvertime($(this).data("id"));
          $("#delete-cut-off-overtime-btn").click();
        });
      },
    });

    $("div.toolbar").html(
      `
                    <div class="row justify-content-end m-0">
                        <label for="project" class="ml-2 mr-2">
                            Project
                            <select class="form-control form-control-sm" id="project">
                                <option value="0">All</option>
                            </select>
                        </label>

                        <label for="category" class="mr-2">
                            Employee Category
                            <select class="form-control-sm form-control" id="category">
                                <option>All</option>
                                <option>Civil</option>
                                <option>Admin</option>
                                <option>Sub-Contractor</option>
                            </select>
                        </label>

                        <label for="startdate" class="mr-2">
                            Start Date
                            <input type="date" id="start_datetime" name="start_datetime" class="form-control form-control-sm">
                        </label>

                        <label for="enddate" class="mr-3">
                            End Date
                            <input type="date" id="end_datetime" name="end_datetime" class="form-control form-control-sm">
                        </label>
                        <div class="mt-3">
                            <button type="button" id="bulk-delete" class="btn btn-danger btn-sm"><span class="fa fa-trash mr-1"></span>Mass Delete</button>
                        </div>
                    </div>
                `
    );

    $(document).ready(function () {
      $("#project").change(function () {
        table.columns(2).search(this.value).draw();
      });

      $("#category").change(function () {
        table.columns(3).search(this.value).draw();
      });

      $("#start_datetime").change(function () {
        table.columns(5).search(this.value).draw();
      });

      $("#end_datetime").change(function () {
        table.columns(6).search(this.value).draw();
      });

      $("#bulk-delete").click(function () {
        vm.bulkDeleteCutOffOvertime({
          project: parseInt($("#project").val()),
          category: $("#category").val(),
          start_time: $("#start_datetime").val(),
          end_time: $("#end_datetime").val(),
        });
      });
    });
  },

  computed: {
    ...mapState({
      isLoading: (state) => state.cutOffOvertime.isLoading,
      projects: (state) => state.general.projects,
    }),
  },

  watch: {
    projects: function () {
      this.appendProjects();
    },
  },

  methods: {
    ...mapActions("general", ["getProjects", "bulkDeleteCutOffOvertime"]),

    ...mapMutations("cutOffOvertime", ["setDeleteCutOffOvertime"]),

    appendProjects() {
      $("#project").empty();
      let vm = this;
      this.createProjectOption("All", 0);
      this.projects.forEach(function (value, index) {
        vm.createProjectOption(value.name, value.id);
      });
    },

    createProjectOption(name, id) {
      var o = new Option(name, id);
      $(o).html(name);
      $("#project").append(o);
    },
  },

  props: ["csrf"],

  components: {
    Loading,
  },
};
</script>
