import devlarn from '../../api/devlarn';

//initial state
const state = () => ({
    specialDeductiontData : [],
    paymentsData:[],

})

//actions
const actions = {
    getSpecialDeductionsData({commit}, id) {
        devlarn.getPaymentsData( details => {
            commit('setPaymentsData',details);
        }, id)
    },
}

//mutations
const mutations = {
    setSpecialDeductionsData(state, details) {
        state.specialDeductiontData = details;
    },

    setPaymentsData(state, details) {
        state.paymentsData = details;
    }
}

//getters
const getters = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }

