<template></template>
<script>
export default {
  mounted() {
    $("#body-row .collapse").collapse("hide");

    //check sidebar collapsed state after reload
    if (localStorage.getItem("isCollapsed") === "true") {
      SidebarCollapse();
    } else {
      $("#collapse-icon").addClass("fa-angle-double-left");
    }

    function SidebarCollapse() {
      $(".menu-collapsed").toggleClass("d-none");
      $(".sidebar-submenu").toggleClass("d-none");
      $(".submenu-icon").toggleClass("d-none");
      $("#sidebar-container").toggleClass("sidebar-expanded sidebar-collapsed");

      // Treating d-flex/d-none on separators with title
      var SeparatorTitle = $(".sidebar-separator-title");
      if (SeparatorTitle.hasClass("d-flex")) {
        SeparatorTitle.removeClass("d-flex");
      } else {
        SeparatorTitle.addClass("d-flex");
      }

      // Collapse/Expand icon
      $("#collapse-icon").toggleClass(
        "fa-angle-double-left fa-angle-double-right"
      );
    }
    //$('#collapse-icon').addClass('fa-angle-double-left');
    function setCollapseState() {
      if (localStorage.getItem("isCollapsed") === "true") {
        localStorage.setItem("isCollapsed", "false");
      } else {
        localStorage.setItem("isCollapsed", "true");
      }
    }
    // Collapse click
    $("[data-toggle=sidebar-colapse]").click(function() {
      SidebarCollapse();
      setCollapseState();
    });

    $("#report-submenu").click(function() {
      if($("#submenu-icon").hasClass("fa-caret-right")){ 
       $("#submenu-icon").removeClass("fa-caret-right");
        $("#submenu-icon").addClass("fa-caret-down");
        console.log('right');
        }
      else{
        $("#submenu-icon").removeClass("fa-caret-down");
        $("#submenu-icon").addClass("fa-caret-right");
        console.log('not right');
      } 
    });
  }
};
</script>