<template>
    <div class="form-group">
        <label for="inputProject">Project:</label>
        <input
            type="text"
            class="form-control form-control-sm"
            v-model="projectName"
            placeholder="Project Name"
            required
            @input="getProjects()" 
            autocomplete="off" 
        />
        <ul class="list-group">
            <li
                class="list-group-item employee-list mt-1 bg-info text-white"
                v-for="project in projects"
                :key="project.id"
                @click="setProject(project)">
                    {{ project.name }}
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    data(){
        return{
            projects: [],
            projectName: ''
        }
    },

    methods:{
        getProjects() {
            axios.get("/api/v1/projects/" + this.projectName).then(response => {
                this.projects = response.data.data;
            });
        },

        setProject(project) {
           this.projectName = project.name;
           this.$emit('set-project', project);
           this.projects = []
        },
    }
}
</script>