import Vue from 'vue'
import Vuex from 'vuex'
import payroll from './modules/payroll'
import specialDeductions from './modules/specialDeductions'
import dtr from './modules/dtr'
import addDtr from './modules/addDtr'
import specialDeductionReport from './modules/specialDeductionReport'
import user from './modules/user'
import holidays from './modules/holiday'
import cutOffOvertime from './modules/cutOffOvertime'
import general from './modules/general'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    payroll,
    specialDeductions,
    dtr,
    addDtr,
    specialDeductionReport,
    user,
    holidays,
    cutOffOvertime,
    general
  }
})
