<template>
<div>
    <button type="button" id="delete-cash-advance-btn" class="d-none btn btn-primary" data-toggle="modal" data-target="#delete-cash-advance-modal">View Details</button>
    <div class="modal fade" id="delete-cash-advance-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Are you sure?</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form :action="'cash-advances/delete'" method="post">
                    <input type="hidden" name="id" :value="cashAdvanceId">
            
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal"><span class="fa fa-times mr-2"></span>Close</button>
                        <button type="submit" class="btn btn-sm btn-danger"><span class="fa fa-times mr-2"></span>Delete</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    props: ['cashAdvanceId']
}
</script>