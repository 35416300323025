<template>
    <div class="row">
        <loading 
            :active.sync="isLoading" 
            :is-full-page="fullPage">
        </loading>

        <div class="modal fade" id="attach-file-modal" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Attach File</h5>
                    <button type="button" class="close attach-file-modal-close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                
                <div class="modal-body">
                    <input type="text" class="form-control mb-2" v-model="document_name" placeholder="Document Name"/>
                    <input type="file" class="form-control" id="input-file" name="file" placeholder="Upload file" @change="processFile($event)"/>
                </div>
                
                <div class="modal-footer">
                    <a href="#" class="btn btn-secondary" data-dismiss="modal"><span class="fa fa-times-circle mr-1"></span>Cancel</a>
                    <button type="submit" class="btn btn-danger" @click="uploadFile()"><span class="fa fa-arrow-alt-circle-up mr-1"></span>Upload</button>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {    
        mounted() {
           
        },

        data(){
            return({
                document_name: '',
                document_file: '',
                document_meta: '',
                isLoading: false,
                fullPage: false
            }) 
        },

        methods:{
            processFile(event){
                this.document_meta = event.target.files[0];
                console.log(this.document_meta);
                this.createFile();
            },

            createFile(file) {
                let reader = new FileReader();
                let vm = this;
                reader.onload = (e) => {
                    vm.document_file = e.target.result;
                };
                reader.readAsDataURL(this.document_meta);
            },

            uploadFile(){
                if(!this.document_file){
                    return;
                }
                
                this.isLoading = true;
                let attachFile;

                axios.post('/attach-files/store',{
                    document_meta: this.document_meta.type,
                    document_name: this.document_name,
                    document_file: this.document_file
                }).then(response => {
                    this.isLoading = false;
                    if(response.data.status == "ok"){   
                        $(".attach-file-modal-close").click();
                        attachFile = response.data.data;
                        this.$emit('file-uploaded', attachFile);
                        this.document_meta = '';
                        this.document_name = '';
                        this.document_file = '';
                        $("#input-file").val('');
                    }
                });
            }
        },

        props:[
            
        ],

        components: {
            Loading
        },
    }
</script>


