<template>
<div>
    <button type="button" id="pay-cash-advance-btn" class="d-none btn btn-primary" data-toggle="modal" data-target="#pay-cash-advance-modal">View Details</button>
    <div class="modal fade" id="pay-cash-advance-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Pay Cash Advance</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label>Amount:</label>
                        <input type="text" v-model="amount" placeholder="0.0" class="form-control form-control-sm"/>
                    </div>

                    <div class="form-group">
                        <label>Date:</label>
                        <input type="datetime-local" v-model="date" class="form-control form-control-sm"/>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal"><span class="fa fa-times mr-2"></span>Close</button>
                    <button type="submit" class="btn btn-sm btn-primary" @click="saveCashAdvancePayment()"><span class="fa fa-save mr-2"></span>Pay</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    data(){
        return{
            'amount': 0,
            'date': new Date
        }
    },

    methods:{
        saveCashAdvancePayment(){
            console.log(this.cashAdvanceId);
            axios.post('/cash-advances/payment',{
                cash_advance_id: this.cashAdvanceId,
                amount: this.amount,
                date: this.date
            })
            .then(response => {
                this.$emit('cash-advance-paid');
                $(".btn-secondary").click();
            });
        }
    },

    props:['cashAdvanceId']
}
</script>