<template></template>

<script>
export default {
  mounted() {
    function formatDate(date) {
      var today = new Date(date);
      return today.toLocaleDateString("en-US");
    }

    function formatTime(time) {
      var date = new Date("2020-02-20 " + time);
      return date.toLocaleTimeString("en-US");
    }

    var table = $("#projects-table").DataTable({
      serverSide: true,
      processing: true,
      lengthMenu: [[25, 100, -1], [25, 100, "All"]],
      pageLength: 25,
      ajax: "/data/projects",
      columns: [
        { data: "id" },
        { data: "name" },
        { data: "status" },
        {
          data: "start_date",
          sortable: true,
          render: function(data, type, full, meta) {
            return formatDate(full.start_date);
          }
        },
        {
          data: "target_completion_date",
          sortable: true,
          render: function(data, type, full, meta) {
            return formatDate(full.target_completion_date);
          }
        },
        {
          sortable: false,
          render: function(data, type, full, meta) {
            return formatTime(full.time_in);
          }
        },
        {
          sortable: false,
          render: function(data, type, full, meta) {
            return formatTime(full.time_out);
          }
        },
        {
          sortable: false,
          render: function(data, type, full, meta) {
            return (
              '<a href="/projects/' +
              full.id +
              '/show" class="btn btn-sm btn-primary"><span class="fa fa-info-circle mr-1"></span> View Details</a>'
            );
          }
        }
      ],
      dom: `<"row" 
                <"col-1.5 mt-1 p-0"l><"col-6 d-flex ml-1 p-0"B><"col mt-1"f> 
            >
            <rtip>
            `,
      buttons: [ 
        {
          extend: "excelHtml5",
          text: '<i class="fa fa-file-excel fa-2x"></i>',
          titleAttr: "Excel",
          exportOptions: {
                modifier: {
                    search: 'applied',
                    order: 'applied'
                }
            }
        },
        {
          extend: "csvHtml5",
          text: '<i class="fa fa-file-csv fa-2x"></i>',
          titleAttr: "CSV",
          exportOptions: {
                modifier: {
                    search: 'applied',
                    order: 'applied'
                }
            }
        },
        {
          extend: "pdfHtml5",
          text: '<i class="fa fa-file-pdf fa-2x"></i>',
          titleAttr: "PDF",
          exportOptions: {
                modifier: {
                    search: 'applied',
                    order: 'applied'
                }
            }
        },
        {
          extend: "print",
          title:
            "<center><p text-black><strong>Devlarn Ventures</strong><br>List of Projects</p></center>",
          text: '<i class="fa fa-print fa-2x"></i>', 
          exportOptions: {
                modifier: {
                    search: 'applied',
                    order: 'applied'
                }
            },
          customize: function(win) {
            $(win.document.body).addClass("white-bg");
            $(win.document.body)
              .css("font-size", "10px")
              .prepend("");
            $(win.document.body)
              .find("table")
              .addClass("compact")
              .css("font-size", "inherit");
          }
        },
      ],
      
    }); 

    /* Custom filtering function which will search data in column four between two values */
    $(document).ready(function() {
      $("#status-select").change(function() {
        table
          .columns(2)
          .search(this.value)
          .draw();
      });

      $("#completion-year").change(function() {
        table
          .columns(3)
          .search(this.value)
          .draw();
      });
    });
  }
};
</script>
