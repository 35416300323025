<template>
  <div>
    <button type="button" data-toggle="modal" :id="'edit-special-deduction-btn-'+specialDeduction.id" :data-target="'#edit-special-deduction-modal-'+specialDeduction.id" class="btn btn-sm btn-primary mb-2">
        <span class="fa fa-plus-circle mr-1"></span>
        Edit
    </button>

    <div class="modal fade" :id="'edit-special-deduction-modal-'+specialDeduction.id" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Edit Special Deduction</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form>
          <div class="modal-body">
            <get-project-component @set-project-id="setProjectId" ref="projectSelect"></get-project-component>
            <input type="hidden" name="project_id" v-model="projectId">
            <input type="hidden" name="employee_id" id="employee-id" v-model="specialDeduction.employee_id">
            <label>Description:</label>
            <input type="text" class="form-control mb-2" v-model="specialDeduction.description" name="description" placeholder="Description" required/>
            <label>Type:</label>
            <special-deductions-type :type-val="specialDeduction.type" @type-selected="setType"></special-deductions-type>
            <label>Date:</label>
            <input type="date" class="form-control mb-2" v-model="dateVal" name="date" placeholder="Date" required/>
            <label>Total Amount:</label>
            <input type="text" class="form-control mb-2" v-model="specialDeduction.amount" name="total_amount" placeholder="0.00" required/>
            <label>Amount Per Deduction:</label>
            <input type="text" class="form-control mb-2" v-model="specialDeduction.amount_per_deduction" name="amount_per_deduction" placeholder="0.00" required/>
          </div>

          <div class="modal-footer">
              <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal"><span class="fa fa-times mr-1"></span>Cancel</button>
              <button type="button" class="btn btn-primary btn-sm" @click="updateDeduction()"><span class="fa fa-save mr-1"></span>Update</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
export default {
    data(){
        return {
            dateVal: moment().format('YYYY-MM-DD'),
            projectId: 0,
        }
    },

    mounted () {
        this.projectId = this.specialDeduction.project_id
        this.$refs.projectSelect.projectNameId = this.specialDeduction.project_name
        this.dateVal = moment(this.specialDeduction.date).format('YYYY-MM-DD');
    },

  methods: {
    ...mapActions('specialDeductions', [
      'updateSpecialDeductionDetails'
    ]),

    updateDeduction(){
      let details = this.specialDeduction
      details.project_id = this.projectId
      details.date = this.dateVal

      console.log(details, 'details')

      this.updateSpecialDeductionDetails(details);
      location.reload();
    },

    setProjectId(value){
        this.projectId = value
    },

    setType(type){
        this.specialDeduction.type = type
    }
  },

  props: [
      'specialDeduction'
  ]

}
</script>
