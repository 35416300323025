<template>
  <div class="row">
    <button
      type="button"
      id="view-payments-details-btn"
      class="d-none btn btn-primary"
      data-toggle="modal"
      data-target="#view-payments-details-modal"
    >View Details</button>
    <div
      class="modal fade"
      id="view-payments-details-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content" id="special-deduction-payments-detail">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Special Deduction Payments</h5>
            <button type="button" class="close no-print" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <ul class="list-group list-group-flush" style="width:100%;">
                <li class="list-group-item bg-info">
                  <h6 class="panel-title text-black">{{ specialDeductiontData.full_name }}</h6>
                </li>
                <li class="list-group-item">
                  <b>Description:
                  {{ specialDeductiontData.description }}</b>
                </li>
                <li class="list-group-item">
                  <b>Amount:
                  {{ specialDeductiontData.amount }}</b>
                </li>
                <li class="list-group-item">
                  <b>Total Paid:
                  {{ specialDeductiontData.total_payment }}</b>
                </li>
                <li class="list-group-item">
                  <b>Remaining Balance:
                  {{ specialDeductiontData.remaining_amount }}</b>
                </li>
                <li class="list-group-item disabled"> 
                </li>
              </ul>
            </div>
            <h5>Payments</h5> 
              <div class="table-responsive">
                <table
                  id="special-deductions-report"
                  style="width:100%"
                  class="display table table-sm table-striped"
                >
                  <thead>
                    <tr>
                      <th>Amount</th>
                      <th>Date Paid</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="payment in this.paymentsData" :key="payment.id">
                      <td>{{ payment.amount }}</td>
                      <td>{{ payment.date }}</td>
                    </tr>
                    <tr>
                      <td>Total number of payments: <b>{{ numberOfPayments }} </b></td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>Amount</th>
                      <th>Date Paid</th>
                    </tr>
                  </tfoot>
                </table>
              </div> 
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary no-print" data-dismiss="modal">
              <span class="fa fa-times-circle mr-1"></span>Close
            </button>
            <button type="button" class="btn btn-primary no-print" @click="printPaymentsDetails()">
              <span class="fa fa-print mr-1"></span>Print
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      paymentsData: (state) => state.specialDeductionReport.paymentsData,
      specialDeductiontData: (state) =>
        state.specialDeductionReport.specialDeductiontData,
    }),

    numberOfPayments: function () {
      return this.paymentsData.length;
    },
  },
  methods: {
    ...mapMutations("specialDeductionReport", ["setPaymentsData"]),

    printPaymentsDetails(){

            var mywindow = window.open('', 'myWindow', 'height=800,width=800');
            mywindow.document.write('<html>');
            mywindow.document.write(document.getElementsByTagName('head')[0].innerHTML);
            mywindow.document.write('<body >');
            mywindow.document.write('<h5 class="ml-3 mt-3">' + document.title  + '</h5>');
            mywindow.document.write('<div style="width: 500px">');
            mywindow.document.write(document.getElementById('special-deduction-payments-detail').innerHTML);
            mywindow.document.write('</div>');
            mywindow.document.write('</body></html>');

            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/
            
            setTimeout(function(){ 
                mywindow.print();
                mywindow.close(); 
            }, 500); 
        },
  }
};
</script>