<template>
    <table class="table">
        <tr>
            <th>#</th>
            <th>Description</th>
            <th>Amount</th>
        </tr>
        <tr v-for="(deduction, index) in deductions" :key="index">
            <td>{{ index+1 }}</td>
            <td>{{ deduction.deduction_name }}</td>
            <td>{{ deduction.amount |  numeral('0,000.00') }}</td>
        </tr>
        <tr v-for="(sdeduction, sindex) in specialDeductions" :key="sindex+deductions.length">
            <td>{{ sindex +  deductions.length + 1 }}</td>
            <td>{{ sdeduction.description }}</td>
            <td>{{ sdeduction.amount |  numeral('0,000.00') }}</td>
        </tr>
        <tr v-if="cashAdvanceAmountToDeduct > 0">
            <td>{{ deductions.length + specialDeductions.length + 1 }}</td>
            <td>Cash Advance</td>
            <td class="d-flex flex-row">
                <input type="text" :value="cashAdvanceAmountToDeduct" class="form-control form-control-sm"/>
                <button 
                    type="button" 
                    class="ml-1 btn btn-sm btn-primary d-flex flex-row align-items-center"
                    @click="payCashAdvance()"
                    v-if="cashAdvanceDeductAmount == 0"
                >
                        <i 
                            class="mr-1 fa fa-minus-circle">
                        </i>
                        Deduct
                </button>

                <button 
                    type="button" 
                    class="ml-1 btn btn-sm btn-primary d-flex flex-row align-items-center"
                    @click="revertCashAdvancePay()"
                    v-if="cashAdvanceDeductAmount > 0"
                >
                        <i 
                            class="mr-1 fa fa-undo">
                        </i>
                        Revert
                </button>
            </td>
        </tr>
        <tr>
            <th colspan="2">Total:</th>
            <th>{{ totalAmount |  numeral('0,000.00') }}</th>
        </tr>
    </table>
</template>
<script>
export default {
    mounted(){
        this.getEmployeeDeductions();
        this.getSpecialDeductions();
        this.getCashAdvance();
    },  
    data(){
        return{
            deductions: [],
            specialDeductions: [],
            cashAdvance: {},
            cashAdvanceDeductAmount: 0,
            cashAdvanceToDeductAmount: 0,
            payment: {}
        }
    },
    computed: {
        totalAmount: function(){
            let amount = 0;

            this.deductions.forEach(function(value,index){
                amount += parseFloat(value.amount);
            })

            this.specialDeductions.forEach(function(value,index){
                amount += parseFloat(value.amount);
            })

            amount += this.cashAdvanceDeductAmount;

            return amount;
        },

        cashAdvanceAmountToDeduct(){
            let termAmount= parseFloat(this.cashAdvance.amount) / this.cashAdvance.terms;
            if(this.cashAdvance.remaining_balance < termAmount){
                this.cashAdvanceToDeductAmount = this.cashAdvance.remaining_balance;
                return this.cashAdvance.remaining_balance;
            }else{
                this.cashAdvanceToDeductAmount = termAmount;
                return termAmount;
            }
        },
    },

    props: ['employeeId'],
    methods:{
        getEmployeeDeductions(){
            axios.get('/data/employees/'+this.employeeId+'/deductions')
            .then(response => {
                this.deductions = response.data; 
            });
        },

        getSpecialDeductions(){
            axios.get('/data/employees/'+this.employeeId+'/special-deductions')
            .then(response => {
                this.specialDeductions = response.data; 
            });
        },

        getCashAdvance(){
            axios.get('/data/employees/'+this.employeeId+'/cash-advances')
            .then(response => {
                console.log(response.data)
                this.cashAdvance = response.data; 
            });
        },

        payCashAdvance(){
            let date = new Date;
            date = date.toISOString().slice(0, 16);
            
            axios.post('cash-advances/payment',{
                cash_advance_id: this.cashAdvance.id,
                amount: this.cashAdvanceToDeductAmount,
                date: date
            }).then(response =>{
                this.payment = response.data;
                this.cashAdvanceDeductAmount = this.cashAdvanceToDeductAmount;
            })
        },

        revertCashAdvancePay(){
            axios.post('cash-advances/payment/revert',{
                id: this.payment.id
            }).then(reponse =>{
                this.cashAdvanceDeductAmount = 0;
            })
        }
    }
}
</script>