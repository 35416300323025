<template>
  <div class="d-flex justify-content-center align-items-center mb-2">
    <select type="text" class="form-control form-control-sm" name="type" @change="emitType()" v-model="selectedType">
      <option v-for="type in types" :value="type.name" :key="type.id">{{ type.name }}</option>
    </select>

    <button type="button" class="btn btn-sm btn-primary ml-1 rounded-circle"  data-toggle="modal" data-target="#add-special-deduction-type-modal">
      <i class="fa fa-plus"></i>
    </button>
  </div>
</template>
<script>

  import { mapMutations, mapActions, mapState } from 'vuex'

  export default {
    data () {
        return {
            selectedType: ''
        }
    },

    computed: {
      ...mapState({
        types: state => state.specialDeductions.types
      }),
    },

    methods: {
       ...mapActions('specialDeductions', [
        'getSpecialDeductionTypes'
      ]),

      emitType(){
          this.$emit('type-selected', this.selectedType);
      }
    },

    mounted () {
      this.getSpecialDeductionTypes();
      this.selectedType = this.typeVal
    },

    props:[
        'typeVal'
    ]

  }
</script>
