<template>
  <div
    class="modal fade"
    :id="action+ 'modalAdminPassword'"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h4 class="modal-title w-100 font-weight-bold">Restricted Action</h4>
          <button
            type="button"
            class="modalAdminPasswordClose"
            @click="hideModal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body mx-3">
          <div class="md-form mb-4">
            <label data-error="wrong" data-success="right" for="defaultForm-pass">Admin password</label>
            <div class="d-flex flex-row">
              <i class="fas fa-lock fa-2x prefix grey-text m-auto"></i>
              <input
                type="password"
                id="defaultForm-pass"
                class="form-control validate ml-2"
                @input="adminPassword = $event.target.value"
                :value="adminPassword"
                placeholder="Password"
                required
              />
            </div>
            <div v-if="errorMessage" class="mt-4 p-2" style="background-color: red;">
              <p class="m-auto" style="color:white">{{errorMessage}}</p>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button class="btn btn-primary w-100" @click="restoreOrTerminateEmployee">Continue</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      errorMessage: null,
      adminPassword: null,
    };
  },
  props: ["action", "employeeId"],
  methods: {
    restoreOrTerminateEmployee() {
      this.errorMessage = null;
      axios
        .post("/employee/terminate", {
          id: this.employeeId,
          is_restore: this.action === "terminate" ? false : true,
          adminPassword: this.adminPassword,
        })
        .then((response) => {
          if (response.data.status === "ok") {
            this.adminPassword = null;
            $("#refreshButton").click();
            $(".modalAdminPasswordClose").click();
          } else {
            // error
            this.errorMessage = response.data.message;
          }
        });
    },
    hideModal() {
      var id = this.action + "modalAdminPassword";
      $('#'+ id).modal('hide');
    },
  },
};
</script>