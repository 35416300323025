<template>
    <div>
        <h1 v-for="id in employeeIds" :key="id">
            {{ id }}
        </h1>
    </div>
</template>
<script>
export default {
    props: ['employeeIds']
}
</script>