import devlarn from '../../api/devlarn'
import { over } from 'lodash'

// initial state
const state = () => ({
  employee: {},
  userDateLogDetails: [],
  project: {},
  penalties: [],
  dtrMeta: {},
  totalWorkHours: 0,
  dtrTimeAdjustments: [],
  dateTimeLogs: [],
  timeLogToDelete: 0,
  employeeWorkedProjects: [],
  cutOffOvertime: {
    hours: 0
  },
  dtrMetaIdToDelete: 0
})

// getters
const getters = {

}

// actions
const actions = {
  getUserDateLogDetails({ state, commit }) {
    devlarn.getUserDateLogDetails(details => {
      commit('setUserDateLogDetails', details)
    }, state.employee.employee_id, state.employee.date_log)
  },

  getDateTimeLogs({ state, commit }) {
    devlarn.getDateTimeLogs(timeLogs => {
      commit('setDateTimeLogs', timeLogs)
    }, state.employee.employee_id, state.employee.date_log)
  },

  getDtrMeta({ state, commit }) {
    const id = state.employee.employee_id ? state.employee.employee_id : state.employee.id
    devlarn.getDtrMeta(details => {
      commit('setDtrMeta', details)
      commit('setTotalWorkHours', details)
    }, id, state.employee.date_log, state.employee.project_id)
  },

  getEmployeePenalties({ state, commit }) {
    devlarn.getEmployeePenalties(penalties => {
      commit('setEmployeePenalties', penalties)
    }, state.employee.employee_id, state.employee.date_log)
  },

  addEmployeePenalty({ state, dispatch }, penalty) {
    penalty.employee_id = state.employee.employee_id;
    penalty.date = state.employee.date_log;
    devlarn.addEmployeePenalty(() => {
      dispatch('getEmployeePenalties');
    }, penalty)
  },

  removeEmployeePenalty({ state, dispatch }, id) {
    devlarn.removeEmployeePenalty(() => {
      dispatch('getEmployeePenalties');
    }, state.employee.employee_id, id)
  },

  addDtrTimeAdjustment({ state, dispatch }, dtrTimeAdjustment) {
    devlarn.addDtrTimeAdjustment(() => {
      dispatch('getDtrMeta');
      dispatch('getUserDateLogDetails');
    }, dtrTimeAdjustment);
  },

  removeDtrTimeAdjustment({ state, dispatch }, id) {
    devlarn.removeDtrTimeAdjustment(() => {
      dispatch('getDtrMeta');
      dispatch('getUserDateLogDetails');
    }, id);
  },

  deleteDtr({ state, dispatch }, log) {
    devlarn.removeDtr(() => {
      dispatch('getDtrMeta');
      dispatch('getUserDateLogDetails');
    },
      [log.time_in_dtr_id, log.time_out_dtr_id])
  },

  saveOverTime({ state, dispatch }, overTimeInMinutes) {
    devlarn.saveOverTime(() => {
      dispatch('getDtrMeta');
      dispatch('getUserDateLogDetails');
    }, state.employee.employee_id, state.dtrMeta.date, overTimeInMinutes);
  },

  saveApprovedOverTime({ state, dispatch }, approvedOvertimeInMinutes) {
    if (approvedOvertimeInMinutes > state.dtrMeta.total_overtime_hours) {
      approvedOvertimeInMinutes = state.dtrMeta.total_overtime_hours;
    }
    devlarn.saveApprovedOverTime(() => {
      dispatch('getDtrMeta');
    }, state.employee.employee_id, state.dtrMeta.date, approvedOvertimeInMinutes);
  },

  deleteTimeLog({ state, dispatch }) {
    devlarn.deleteTimeLog(() => {
      dispatch('getDateTimeLogs');
    }, state.timeLogToDelete)
  },

  deleteDtrMeta({ state, dispatch }) {
    devlarn.deleteDtrMeta(() => {
        location.reload()
    }, state.dtrMetaIdToDelete)
  },

  saveTotalWorkingHours({ dispatch },  data) {
    devlarn.saveTotalWorkingHours(() => {
        dispatch('getDtrMeta');
    }, data.dtr_meta_id, data.total_work_hours)
  },

  getEmployeeWorkedProjects({ commit, state }) {
    devlarn.getEmployeeWorkedProjects((response) => {
        commit('setEmployeeWorkedProjects', response.data);
    }, state.employee.employee_id, state.employee.date_log)
  },

  saveWorkingHours({state, dispatch}){
    devlarn.saveWorkingHours((response) => {
        dispatch('getDtrMeta');
    }, state.dtrMeta.dtr_summaries)
  },
  getCutOffOvertime({commit}, data){
    devlarn.getCutOffOvertime((response) => {
        commit('setCutOffOvertime', response)
    }, data.employee_id, data.date_log, data.project_id)
  },
  saveCutOffOvertime({commit}, data){
    devlarn.saveCutOffOvertime((response) => {
        commit('setCutOffOvertime', response)
    }, data)
  }
}

// mutations
const mutations = {
  setEmployee(state, employee) {
    state.employee = employee;
  },

  setUserDateLogDetails(state, details) {
    state.userDateLogDetails = details;
  },

  setProject(state, project) {
    state.project = project;
  },

  setDtrMeta(state, dtrMeta) {
    state.dtrMeta = dtrMeta;
  },

  setTotalWorkHours(state, dtrMeta) {
    state.totalWorkHours = dtrMeta.total_normal_work_hours + dtrMeta.approved_overtime + dtrMeta.total_dtr_time_adjustment_minutes;
  },

  setEmployeePenalties(state, penalties) {
    state.penalties = penalties;
  },

  setDtrTimeAdjustments(state, dtrTimeAdjustments) {
    state.dtrTimeAdjustments = dtrTimeAdjustments;
  },

  setDateTimeLogs(state, timeLogs){
    state.dateTimeLogs = timeLogs;
  },

  setTimeLogToDelete(state, timeLogToDelete){
    state.timeLogToDelete = timeLogToDelete;
  },

  setEmployeeWorkedProjects(state, projects){
    state.employeeWorkedProjects = projects;
  },

  setDtrSummaries(state, summaries){
    state.dtrMeta.dtr_summaries[summaries.index].normal_employee_work_hours = summaries.value;
  },
  setCutOffOvertime(state, data){
    state.cutOffOvertime = data
  },
  setDtrMetaIdToDelete(state, value){
      state.dtrMetaIdToDelete = value
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
