<template>
  <div>
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <div class="table-responsive mt-2">
      <table
        id="employees-terminated-table"
        style="width:100%"
        class="table table-striped table-sm"
      >
        <thead>
          <tr>
            <th>Employee ID</th>
            <th>Last Name</th>
            <th>First Name</th>
            <th>Middle Name</th>
            <th>Age</th>
            <th>Category</th>
            <th>Position</th>
            <th>Type</th>
            <th>Project</th>
            <th>Action</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <th>Employee ID</th>
            <th>Last Name</th>
            <th>First Name</th>
            <th>Middle Name</th>
            <th>Age</th>
            <th>Category</th>
            <th>Position</th>
            <th>Type</th>
            <th>Project</th>
            <th>Action</th>
          </tr>
        </tfoot>
      </table>
    </div>

    <terminate-restore-modal :employee-id="employeeId" action="restore"></terminate-restore-modal>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  mounted() {
    this.isLoading = true;
    let vm = this;

    this.table = $("#employees-terminated-table").DataTable({
      serverSide: true,
      processing: true,
      ajax: "/data/employees-terminated",
      stateSave: true,
      lengthMenu: [
        [25, 100, -1],
        [25, 100, "All"],
      ],
      pageLength: 25,
      columns: [
        { data: "id" },
        { data: "last_name" },
        { data: "first_name" },
        { data: "middle_name" },
        { data: "age" },
        { data: "employee_category" },
        {
          data: "position",
          render: function (data, type, row) {
            if (data == null) {
              return "";
            }
            return data.name;
          },
        },
        { data: "employee_type" },
        {
          data: "project",
          render: function (data, type, row) {
            if (data == null) {
              return "";
            }
            return data.name;
          },
        },
        {
          sortable: false,
          render: function (data, type, full, meta) {
            return (
              '<button type="button" data-toggle="modal" data-target="#restoremodalAdminPassword" class="btn btn-danger btn-sm restore-terminated" data-employee-id="' +
              full.id +
              '"><span class="fa fa-redo-alt mr-1"></span>Restore</button>'
            );
          },
        },
      ],
      buttons: [
        {
          extend: "excelHtml5",
          text: '<i class="fa fa-file-excel fa-2x"></i>',
          titleAttr: "Excel",
          title: 'Terminated Employee List',
          exportOptions: {
            modifier: {
              search: "applied",
              order: "applied",
            },
          },
        },
        {
          extend: "csvHtml5",
          text: '<i class="fa fa-file-csv fa-2x"></i>',
          titleAttr: "CSV",
          title: 'Terminated Employee List',
          exportOptions: {
            modifier: {
              search: "applied",
              order: "applied",
            },
          },
        },
        {
          extend: "pdfHtml5",
          text: '<i class="fa fa-file-pdf fa-2x"></i>',
          titleAttr: "PDF",
          title: 'Terminated Employee List',
          exportOptions: {
            modifier: {
              search: "applied",
              order: "applied",
            },
          },
        },
        {
          extend: "print",
          title:
            "<center><p text-black><strong>Devlarn Ventures</strong><br>List of Employees</p></center>",
          text: '<i class="fa fa-print fa-2x"></i>',
          customize: function (win) {
            $(win.document.body).addClass("white-bg");
            $(win.document.body).css("font-size", "10px").prepend("");
            $(win.document.body)
              .find("table")
              .addClass("compact")
              .css("font-size", "inherit");
          },
        },
      ],
       dom: `
            <"row" 
                <"col-6 mt-1"l><"col-6 pr-0"f>
                <"col-4 pl-0 mt-2"B>
                <"col-8 d-flex justify-content-end w-100 toolbar">
            >
            <rtip>
        `,
      drawCallback: function () {
        $(".restore-terminated").click(function () {
          var employee_id = $(this).data("employee-id");
          vm.employeeId = employee_id;
        });
      },
    });

    $("#refreshButton").click(function () {
      vm.table.draw();
    });

    this.isLoading = false;
  },

  data() {
    return {
      isLoading: false,
      fullPage: true,
      adminPassword: null,
      errorMessage: null,
      employeeId: null,
    };
  },

  methods: {
    restoreTerminatedEmployee() {
      this.errorMessage = null;
      this.isLoading = true;
      axios
        .post("/employee/terminate", {
          id: this.employeeId,
          is_restore: true,
          adminPassword: this.adminPassword,
        })
        .then((response) => {
          this.isLoading = false;
          if (response.data.status === "ok") {
            $("#refreshButton").click();
          } else {
            // error
            this.errorMessage = response.data.message;
          }
        });
    },
  },
  components: {
    Loading,
  },
};
</script>