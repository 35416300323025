<template>
  <div>
    <button
      type="button"
      id="view-details-btn"
      class="d-none btn btn-primary"
      data-toggle="modal"
      data-target="#view-details-modal"
    >
      View Details
    </button>
    <div
      class="modal fade"
      id="view-details-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Time Log Details</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-6">
                <div>
                  <label>Name:</label>
                  <h3>
                    <b>{{ employee.full_name }}</b>
                  </h3>

                  <label>Main Project:</label>
                  <h3>
                    <b>{{ project.name }}</b>
                  </h3>
                  <div class="form-group">
                    <label>Time In:</label>
                    <h3>
                      <b>{{ project.time_in | formatTime }}</b>
                    </h3>
                  </div>
                  <div class="form-group">
                    <label>Time Out:</label>
                    <h3>
                      <b>{{ project.time_out | formatTime }}</b>
                    </h3>
                  </div>
                </div>
                <br />
                <h3>
                  <b>Date: {{ employee.date_log | formatDate }}</b>
                </h3>

                <dtr-date-timelogs/>

              </div>
              <div class="col-6">
                <div class="form-group total-working-hours">
                  <label>Total working hours:</label>
                  <div v-for="(summary, index) in dtrMeta.dtr_summaries" :key="index">
                    <div>{{ summary.project }} Project</div>
                    <div class="project-total-working-hours">
                        <input type="number" class="form-control form-control-sm" :value="summary.normal_employee_work_hours/60" @change="setDtrSummariesInit(index, $event.target.value)" step="0.01">
                        <i class="fa fa-save" @click="saveWorkingHoursInit(index)"></i>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Total Overtime:</label>
                  <br />
                  <label>
                    <b>Hours</b>
                  </label>
                  <h3>
                    <div class="d-flex flex-row">
                      <input
                        class="form-control form-control-sm mr-2 w-50"
                        style="text-align: right"
                        type="number"
                        :value="overtimeInHours"
                        step="0.01"
                        @input="overTimeInput = $event.target.value * 60"
                      />
                      <button
                        type="button"
                        class="btn btn-sm btn-secondary"
                        @click="saveOverTime(overTimeInput)"
                      >
                        Save
                      </button>
                    </div>
                  </h3>
                  <br />
                  <label>Approved Overtime:</label>
                  <br />
                  <label>
                    <b>Hours</b>
                  </label>
                  <h3>
                    <div class="d-flex flex-row">
                      <input
                        class="form-control form-control-sm mr-2 w-50"
                        style="text-align: right"
                        type="number"
                        ref="approveInputValue"
                        :value="approvedOvertimeInHours > 0 ? approvedOvertimeInHours : 0 "
                        min="0"
                        step="0.01"
                        :max="overtimeInHours"
                        @input="approvedOvertimeInput = $event.target.value * 60"
                      />
                      <button
                        type="button"
                        class="btn btn-sm btn-secondary"
                        @click="
                          saveApprovedOverTime(approvedOvertimeInput),
                            hideInfoLabel()
                        "
                      >
                        Save
                      </button>
                      <label v-if="isSave" class="form-control-sm text-info"
                        >Sucessful!</label
                      >
                    </div>
                  </h3>
                    <div class="row mt-4">
                        <div class="col p-0">
                            <label class="text-bold">Cut-off Overtime:</label>
                            <div class="row">
                                <input
                                    class="form-control form-control-sm mr-2 w-50"
                                    style="text-align: right"
                                    type="number"
                                    step="0.01"
                                    v-model="cutOffOvertimeHours"
                                />
                                <button
                                    type="button"
                                    class="btn btn-sm btn-secondary"
                                    @click="saveCutOffOvertimePrep()"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                  <label>Penalties:</label>
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Amount</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="penalty in penalties" :key="penalty.id">
                        <td>{{ penalty.description }}</td>
                        <td>{{ penalty.amount | numeral("0,0.00") }}</td>
                        <td>
                          <div
                            class="remove flex-shrink-1 d-flex justify-content-center align-items-center"
                            @click="removeEmployeePenalty(penalty.id)"
                          >
                            <i class="fa fa-times"></i>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <dtr-penalty-add @add-penalty="addEmployeePenalty" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div style="font-size: 10px" class="row mr-4 pt-3" v-if="isAdmin">
                <label>Added by:</label>
                <p>&nbsp;{{ dtrMeta.added_by_name }}</p>
                &nbsp;/&nbsp;
                <label>Updated by:</label>
                <p>&nbsp;{{ dtrMeta.updated_by_name }}</p>
            </div>

            <button type="button" data-toggle="modal" data-target="#addtimemodal" class="btn btn-sm btn-primary"><span class="fa fa-calendar-plus mr-2"></span>
                Add Time Records
            </button>
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              data-dismiss="modal"
            >
              <span class="fa fa-times mr-2"></span>Close
            </button>
            <!-- <button type="button" class="btn btn-sm btn-primary" @click="savePenalty()">
              <span class="fa fa-save mr-2"></span>Save
            </button>-->
          </div>
        </div>
      </div>
    </div>
    <!-- delete modal -->
    <div
      class="modal fade"
      id="deleteTimeLog"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header bg-info">
            <h5 class="modal-title text-white" id="exampleModalLabel">
              Delete Time Log
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Are you sure you want to delete selected record?</p>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                Time In :
                <b>{{ timeLog.time_in }}</b>
              </li>
              <li class="list-group-item">
                Time Out :
                <b>{{ timeLog.time_out }}</b>
              </li>
              <li class="list-group-item">
                Hours :
                <b>{{ timeLog.hours }}</b>
              </li>
              <li class="list-group-item">
                Project :
                <b>{{ timeLog.project_name }}</b>
              </li>
            </ul>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="unsetTimeLog()"
              data-dismiss="modal"
            >
              <span class="fa fa-times mr-1"></span>Cancel
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="deleteDtr(timeLog), unsetTimeLog()"
            >
              <span class="fa fa-trash mr-1"></span>Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      overTimeInput: 0,
      normalWorkHours: 0,
      overtimeHours: 0,
      timeLog: {},
      approvedOvertimeInput: 0,
      isSave: false,
      summaryData: {},
      cutOffOvertimeHours: 0
    };
  },
  computed: {
    ...mapState({
      employee: (state) => state.dtr.employee,
      project: (state) => state.dtr.project,
      timeLogs: (state) => state.dtr.dateTimeLogs,
      userDateLogDetails: (state) => state.dtr.userDateLogDetails,
      dtrMeta: (state) => state.dtr.dtrMeta,
      penalties: (state) => state.dtr.penalties,
      totalWorkHours: (state) => state.dtr.totalWorkHours,
      approvedOvertime: (state) => state.approvedOvertime,
      overtimeInHours: (state) => (state.dtr.dtrMeta.total_overtime_hours / 60).toFixed(2),
      approvedOvertimeInHours: (state) => (state.dtr.dtrMeta.approved_overtime / 60).toFixed(2),
      employeeWorkedProjects: (state) => state.dtr.employeeWorkedProjects,
      cutOffOverTime: (state) => state.dtr.cutOffOvertime,
      isAdmin: state => state.user.isAdmin
    }),


    totalWorkHours: function(){
        const filters = this.$options.filters;
        if(this.dtrMeta.total_normal_employee_work_hours > 0){
            return (this.dtrMeta.total_normal_employee_work_hours /60).toFixed(2);
        }else{
            return filters.hoursDurations(this.timeLogs);
        }

    }
  },

  watch: {
    cutOffOverTime (data) {
        this.cutOffOvertimeHours = data.hours
    }
  },

  methods: {
    ...mapActions("dtr", [
      "addEmployeePenalty",
      "removeEmployeePenalty",
      "addDtrTimeAdjustment",
      "removeDtrTimeAdjustment",
      "deleteDtr",
      "saveOverTime",
      "saveApprovedOverTime",
      "saveTotalWorkingHours",
      "saveTotalProjectEmployeeWorkHours",
      "saveWorkingHours",
      "saveCutOffOvertime"
    ]),

    ...mapMutations("general", [
      "setIsLoadingTrue",
      "setIsLoadingFalse",
    ]),

      ...mapMutations("dtr", [
      "setIsLoadingTrue",
      "setIsLoadingFalse",
      "setDtrSummaries"
    ]),

    setTimeLog(log) {
      this.timeLog = log;
    },
    unsetTimeLog() {
      this.timeLog = {};
    },
    checkApprovedOvertime(value) {
      let overTime = 0;

      this.overTimeInput == 0
        ? (overTime = this.dtrMeta.total_overtime_hours)
        : (overTime = this.overTimeInput);

      if (value > overTime) this.$refs.approveInputValue.value = overTime / 60;
    },
    hideInfoLabel() {
      this.isSave = true;
      setTimeout(() => (this.isSave = false), 2000);
    },

    setDtrSummariesInit(index, value){
        this.setDtrSummaries({
            index: index,
            value: value * 60
        });
    },

    saveWorkingHoursInit(){
        this.saveWorkingHours();
    },

    saveCutOffOvertimePrep(){
        this.saveCutOffOvertime({
            employee_id: this.employee.employee_id,
            project_id: this.employee.project_id,
            start_datetime: this.employee.date_log,
            end_datetime: this.employee.date_log,
            hours: this.cutOffOvertimeHours
        })
    }
  },
};
</script>
<style scoped>
.remove {
  height: 24px;
  width: 24px;
  background-color: red;
  border-radius: 50%;
  color: white;
  margin-left: 4px;
  cursor: pointer;
}

.total-working-hours input {
    max-width: 176px;
    margin-right: 8px;
}


.total-working-hours i{
    font-size: 24px;
}

.project-total-working-hours {
    display: flex;
    align-items:center
}

.fa {
    cursor: pointer;
}

.text-bold {
    font-weight: bold;
}
</style>
