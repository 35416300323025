<template>
<div>
    <button type="button" id="view-cash-advance-details-btn" class="d-none btn btn-primary" data-toggle="modal" data-target="#view-cash-advance-details-modal">View Details</button>
    <div class="modal fade" id="view-cash-advance-details-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Cash Advance Details</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <ul class="list-group">
                        <li class="list-group-item">Date: <strong>{{ cashAdvance.date | formatDate }}</strong></li>
                        <li class="list-group-item">Amount: <strong> {{ cashAdvance.amount | numeral('0,0.00') }}</strong></li>
                        <li class="list-group-item">Terms: <strong>{{ cashAdvance.terms }}</strong></li>
                        <li class="list-group-item"> Payments: 
                            <table class="table">
                                <tr>
                                    <th>Date</th>
                                    <th>Amount</th>
                                </tr>
                                
                                <tr v-for="payment in cashAdvance.payments" :key="payment.id">
                                    <td>{{ payment.payment_date | formatDate }}</td>
                                    <td> {{ payment.amount | numeral('0,0.00') }}</td>
                                </tr>

                                 <tr>
                                    <th>Total</th>
                                    <th> {{ totalAmount | numeral('0,0.00') }}</th>
                                </tr>
                                
                            </table>
                        </li>
                    </ul>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal"><span class="fa fa-times mr-2"></span>Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    data(){
        return{
            'cashAdvance': {},
        }
    },

    computed:{
        totalAmount: function(){
            let sum = 0;
            if(this.cashAdvance.payments){
                this.cashAdvance.payments.forEach(function(value, index){
                    sum += parseFloat(value.amount);
                })
            }
            
            return sum;
        }
    },

    methods:{
        getCashAdvanceDetails(id){
            axios.get('/cash-advances/'+id+'/details')
            .then(response => {
                this.cashAdvance = response.data;
            });
        }
    },
}
</script>