<template>
    <div class="table-responsive">
        <loading
            :active.sync="isLoading"
            :is-full-page="fullPage">
        </loading>

        <table id="users-table" style="width:100%" class="table table-striped table-sm">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Avatar</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tfoot>
                <tr>
                    <th>Name</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Avatar</th>
                    <th>Action</th>
                </tr>
            </tfoot>
        </table>

        <button type="button" id="delete-user-btn" class="d-none btn btn-success" data-toggle="modal" data-target="#delete-user-modal">Delete</button>
        <div class="modal fade" id="delete-user-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Confirm</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                       Are you sure you want to delete this user?
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal"><span class="fa fa-times mr-1"></span>Close</button>
                        <button type="button" class="btn btn-danger btn-sm" @click="deleteUser()" data-dismiss="modal"><span class="fa fa-save mr-1"></span>Delete</button>
                    </div>
                </div>
            </div>
        </div>

        <button type="button" id="edit-user-btn" class="d-none btn btn-success" data-toggle="modal" data-target="#edit-user-modal">Edit</button>
        <div class="modal fade" id="edit-user-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">User Details</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <label>Name:</label>
                        <input class="form-control mb-2 name" v-bind:class="{error:errors.name}" type="text" v-model="user.name" placeholder="Name" required />
                        <label>Email:</label>
                        <input class="form-control mb-2 email" v-bind:class="{error:errors.email}" type="email" v-model="user.email" placeholder="Email" required/>
                        <label>Username:</label>
                        <input class="form-control mb-2 username" v-bind:class="{error:errors.username}" type="text" v-model="user.username" placeholder="Username" required/>
                        <label>Password (leave password blank if no change):</label>
                        <input class="form-control mb-2 password" v-bind:class="{error:errors.password}" type="password" v-model="user.password" placeholder="Password"/>
                        <label>Confirm Password:</label>
                        <input class="form-control mb-2 password-confirmation" v-bind:class="{error:errors.password}" type="password" v-model="user.password_confirmation" placeholder="Confirm password"/>
                        <label>Role:</label>
                        <select class="form-control mb-2 role" v-bind:class="{error:errors.role}" v-model="user.role">
                            <option>Select Role</option>
                            <option value="time-checker">Time Checker</option>
                            <option value="treasurer">Treasurer</option>
                            <option value="accountant">Accountant</option>
                            <option value="admin">Admin</option>
                        </select>
                        <label>Avatar:</label>
                        <img :src="user.avatar_path" class="user-img-table mb-2"/>
                        <input type="file" class="d-none" id="avatar" @change="setAvatar"/>
                        <button type="button" class="btn btn-sm btn-primary mb-2" @click="changeAvatar()"><span class="fa fa-portrait mr-1"></span>Change</button>
                        <br/>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal"><span class="fa fa-times mr-1"></span>Close</button>
                        <button type="button" class="btn btn-primary btn-sm" @click="updateUser()"><span class="fa fa-save mr-1"></span>Update</button>
                    </div>
                </div>
            </div>
        </div>


        <add-permission-user-component ref="addPermissionUser" :user-id="user.id"></add-permission-user-component>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        mounted() {
            let vm = this;
            let table = $('#users-table').DataTable({
                serverSide: true,
                processing: true,
                ajax:"/data/users",
                columns: [
                    { data: 'name' },
                    { data: 'username' },
                    { data: 'email' },
                    { data: 'role' },
                    {
                        sortable: false,
                        "render": function ( data, type, full, meta ) {
                            return '<img  src="'+full.avatar_path+'" class="user-img-table"/>';
                        }
                    },
                    {
                        sortable: false,
                        "render": function ( data, type, full, meta ) {
                            var html = '';

                            if(vm.isAdmin){
                                html = `
                                    <button type="button" class="btn btn-danger btn-sm delete-user mr-2" data-user-id="`+full.id+`">
                                        <span class="fa fa-user-edit mr-1"></span>
                                        Delete
                                    </button>
                                `
                            }

                            html = html + `
                                            <button type="button" class="btn btn-primary btn-sm edit-user mr-2" data-user-id="`+full.id+`">
                                                <span class="fa fa-user-edit mr-1"></span>
                                                Edit
                                            </button>

                                            <button type="button" class="btn btn-primary btn-sm add-permission-user" data-user-id="`+full.id+`">
                                                <span class="fa fa-plus mr-1"></span>
                                                Add Permission
                                            </button>
                                        `;
                            return html;
                        }
                    },
                ],
                "drawCallback": function( settings ) {
                    $('.delete-user').click( function() {
                        vm.user.id = $(this).data('user-id');
                        $("#delete-user-btn").click();
                    });

                    $('.edit-user').click( function() {
                        vm.user.id = $(this).data('user-id');
                        vm.getUserDetails();
                        $("#edit-user-btn").click();
                    });

                    $('.add-permission-user').click( function() {
                        vm.$refs.addPermissionUser.setUserId( $(this).data('user-id') )
                        $("#add-permission-user-btn").click();
                    });
                }

            });

            this.table = table;
        },


        data(){
            return{
                isLoading: false,
                fullPage: true,
                user: {},
                avatar: '',
                table: null,
                errors:{
                    name: false,
                    email: false,
                    username: false,
                    password: false,
                    role: false,
                    permission_level: false
                },
                toFocus: '',
            }
        },

        computed:{

        },

        methods:{
            changeAvatar(){
                $('#avatar').click();
            },

            setAvatar(event){
                this.avatar = event.target.files[0];
                this.user.avatar_path = URL.createObjectURL(this.avatar);

                let data = new FormData();
                data.append('avatar', this.avatar, this.avatar.data);
                data.append('_token', this.csrf)

                axios.post('/data/users/'+this.user.id+'/change-avatar',
                    data,
                    {'headers':{
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    this.isLoading = false;
                    this.user = response.data;
                    this.avatar =
                    this.table.draw();
                });
            },

            getUserDetails(){
                this.isLoading = true;
                axios.get('/data/users/'+this.user.id+'/details').then(response => {
                    this.isLoading = false;
                    this.user = response.data;
                });
            },

            checkFields(){
                if(this.user.name == undefined || this.user.name == ''){
                    this.errors.name = true;
                    this.toFocus = '.name';
                    return false;
                }else{
                    this.errors.name = false;
                }

                if(this.user.email == undefined || this.user.email == ''){
                    this.errors.email = true;
                    this.toFocus = '.email';
                    return false;
                }else{
                    this.errors.email = false;
                }

                if(this.user.username == undefined || this.user.username == ''){
                    this.errors.username = true;
                    this.toFocus = '.username';
                    return false;
                }else{
                    this.errors.username = false;
                }

                if(this.user.password != this.user.password_confirmation){
                    this.errors.password = true;
                    this.errors.password_confirmation = true;
                    this.toFocus = '.password';
                    return false;
                }else{
                    this.errors.password = false;
                }

                if(this.user.role == undefined || this.user.role == ''){
                    this.errors.role = true;
                    this.toFocus = '.role';
                    return false;
                }else{
                    this.errors.role = false;
                }

                if(this.user.permission_level == undefined || this.user.permission_level == ''){
                    this.errors.permission_level = true;
                    this.toFocus = '.permission-level';
                    return false;
                }else{
                    this.errors.permission_level = false;
                }

                return true;
            },

            updateUser(){
                if(!this.checkFields()){
                    $(this.toFocus).focus();
                    return false;
                }

                this.isLoading = true;
                axios.post('/data/users/'+this.user.id+'/update', this.user).then(response => {
                    this.isLoading = false;
                    console.log(response);
                    this.user = response.data;
                    this.table.draw();
                });
            },

            deleteUser(){
                this.isLoading = true;
                axios.post('/data/users/'+this.user.id+'/delete').then(response => {
                    this.isLoading = false;
                    console.log(response);
                    this.table.draw();
                });
            }

        },

        props:[
            'csrf', 'isAdmin'
        ],

        components: {
            Loading
        },
    }
</script>
<style scoped>
    .error{
        border: 1px solid red;
    }
</style>

