<template>
  <span
    v-if="totalUnreadMessageCount"
    class="badge badge-danger"
    style="font-size:x-small"
  >{{totalUnreadMessageCount}}</span>
</template>
<script>
export default {
  props: ["totalUnreadMessageCount"],
};
</script>