<template>
  <div id="modal-content" ref="modalContent" class="modal-body">
    <form enctype="multipart/form-data">
      <!--1st container -->
      <div class="container-fluid">
        <div
          v-if="errorMessage"
          v-html="errorMessage"
          class="d-flex justify-content-center alert alert-danger pt-4"
        ></div>

        <h4>Personal Details</h4>

        <div class="form-group d-flex flex-row-reverse">
          <div>
            <img
              v-if="profilePicPreviewUrl"
              :src="profilePicPreviewUrl"
              style="height: 150px; width: 150px"
            />
            <i v-else class="fas fa-user-tie fa-border" style="font-size:100px;color:black;"></i>

            <div class="d-flex m-auto">
              <input
                type="file"
                ref="profilePicInput"
                style="display: none;"
                @change="onProfilePicChange"
              />

              <button
                type="button"
                class="btn btn-primary d-flex p-auto"
                style="width: 150px; text-align:center"
                @click="selectProfilePic"
              >
                <span class="fa fa-image mt-1 mr-2"></span>Select picture
              </button>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <label for="first_name">First Name:</label>
            <input type="text" class="form-control" id="first_name" v-model="first_name" required />
          </div>

          <div class="col">
            <label for="middle_name">Middle Name:</label>
            <input type="text" class="form-control" id="middle_name" v-model="middle_name" required />
          </div>

          <div class="col">
            <label for="last_name">Last Name:</label>
            <input type="text" class="form-control" id="last_name" v-model="last_name" required />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-2">
            <label for="contact_number">Contact Number:</label>
            <input type="text" class="form-control" id="contact_number" v-model="contact_number" />
          </div>
          <div class="col-3">
            <label for="birth_date">Birthday:</label>
            <input type="date" class="form-control" id="birth_date" v-model="birth_date" required />
            <!--
              <datepicker   v-model="birth_date" name="birth_date"></datepicker>
            -->
          </div>
          <div class="col-3">
            <label for="email">Email:</label>
            <input type="text" class="form-control" id="email" v-model="email" />
          </div>
          <div class="col-4">
            <label for="address">Address:</label>
            <input type="text" class="form-control" id="address" v-model="address" required />
          </div>

        </div>

        <div class="form-group row">
          <div class="col">
            <label for="birth_place">Birth Place:</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">Same as Address</span>
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    v-model="check_address"
                    @change="checkBirthPlace()"
                    aria-label="Checkbox for following text input"
                  />
                </div>
              </div>
              <input type="text" class="form-control" id="birth_place" v-model="birth_place" />
            </div>
          </div>
          <div class="col-2">
            <label for="philheath_number">Philhealth #:</label>
            <input
              type="text"
              class="form-control"
              id="philheath_number"
              v-model="philheath_number"
              required
            />
          </div>
          <div class="col-2">
            <label for="pagibig_number">Pag-ibig #:</label>
            <input
              type="text"
              class="form-control"
              id="pagibig_number"
              v-model="pagibig_number"
              required
            />
          </div>

          <div class="col-2">
            <label for="tin_number">TIN #:</label>
            <input type="text" class="form-control" id="tin_number" v-model="tin_number" required />
          </div>
        </div>

        <div class="form-group row">
          <div class="col-3">
            <label for="sss_number">SSS #:</label>
            <input type="text" class="form-control" id="sss_number" v-model="sss_number" required />
          </div>

          <div class="col-3">
            <label for="umid_number">UMID #:</label>
            <input type="text" class="form-control" id="umid_number" v-model="umid_number" required />
          </div>
          <div class="col-2">
            <label for="drivers_license">Driver's License #:</label>
            <input type="text" class="form-control" id="drivers_license" v-model="drivers_license" />
          </div>
          <div class="col-2">
            <label for="nation_id_number">National ID #:</label>
            <input
              type="text"
              class="form-control"
              id="nation_id_number"
              v-model="national_id_number"
            />
          </div>
          <div class="col-2">
            <label for="nation_id_number">PRC ID #:</label>
            <input type="text" class="form-control" id="nation_id_number" v-model="prc_number" />
          </div>
        </div>

        <div class="form-group row">
          <div class="col-3">
            <label for="employee_insurance">Insurance:</label>
            <input
              type="text"
              class="form-control"
              name="employee-insurance"
              id="employee_insurance"
              v-model="insurance"
            />
          </div>
          <div class="col-3">
            <label for="employee_civil_status">Civil Status:</label>
            <select class="form-control" id="employee_civil_status" v-model="civil_status">
              <option>Single</option>
              <option>Married</option>
              <option>Widow</option>
            </select>
          </div>
          <div class="col-3">
            <label for="employee_gender">Gender:</label>
            <select class="form-control" id="employee_gender" v-model="gender">
              <option>Male</option>
              <option>Female</option>
            </select>
          </div>
          <div class="col-3">
            <label for="employee_religion">Religion:</label>
            <select class="form-control" id="employee_religion" v-model="religion">
              <option>Roman Catholic</option>
              <option>Islam</option>
              <option>Evangelicals (PCEC)</option>
              <option>Iglesia ni Cristo</option>
              <option>Seventh-day Adventist</option>
              <option>Aglipayan</option>
              <option>Bible Baptist Church</option>
              <option>United Church of Christ in the Philippines</option>
              <option>Jehovah's Witnesses</option>
              <option>Non-Roman Catholic and Protestant (NCCP)</option>
              <option>Others</option>
            </select>
          </div>
        </div>
      </div>
      <!-- separator -->
      <div class="container-fluid" style="background-color: #F0F0F0; height: 1px"></div>

      <!--2nd container -->

      <div class="container-fluid mt-3">
        <h4>Employment Details</h4>
        <div class="form-group row">
          <div class="col-3">
            <label for="inputCategory">Category:</label>
            <select class="form-control" id="inputCategory" v-model="employee_category">
                <option v-for="(category, index) in employeeCategories" :key="index">{{ category }}</option>
            </select>
          </div>
          <div class="col-3">
            <label for="inputEmployeeType">Type:</label>
            <select class="form-control" id="inputEmployeeType" v-model="employee_type">
              <option>Provation</option>
              <option>Regular</option>
              <option>Project Base</option>
            </select>
          </div>
          <div class="col-3">
            <label for="inputSalaryPeriod">Salary Period:</label>
            <select
              class="form-control"
              id="inputSalaryPeriod"
              v-model="salary_period"
              @change="computeHourlyRate()"
            >
              <option>Daily</option>
              <option>Weekly</option>
              <option>Monthly</option>
            </select>
          </div>

          <div class="col-3">
            <label for="inputPosition">Position:</label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                id="inputPosition"
                @input="getPositions()"
                v-model="positionName"
                required
                autocomplete="off"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  data-toggle="modal"
                  data-target="#position-modal"
                  type="button"
                >
                  <span class="fa fa-plus"></span>
                </button>
              </div>
            </div>
            <ul class="list-group">
              <li
                class="list-group-item mt-1 bg-info text-white"
                v-for="position in positions"
                :key="position.id"
                @click="setPosition(position)"
              >{{ position.name }}</li>
            </ul>
            <input type="hidden" name="position_id" v-model="position_id" />
          </div>
        </div>

        <div class="form-group row">
          <div class="col-3">
            <label>Deductions:</label>
              <div class="input-group" v-for="deduction in deductions" v-bind:key="deduction.id">
                 <div class="input-group-prepend">
                  <div class="input-group-text">
                    <input class="mr-1" type="checkbox"  :id="'checkDeduction'+ deduction.id" @click="setCheckDeduction(deduction.id)"/>
                    <small style="font-size:11px;"> {{ deduction.deduction_name }}</small>
                  </div>
                </div>
                  <input type="text" :id="'inputDeductionAmount'+ deduction.id"
                    class="form-control"
                    v-model="deduction.amount"
                    aria-label="0.00" @input="setCheckDeduction(deduction.id)" @click="setCheckDeduction(deduction.id)">
              </div>
          </div>

          <div class="col-3">
            <label for="inputProject">Project:</label>
            <input
              type="text"
              class="form-control"
              v-model="projectName"
              placeholder="Project Name"
              required
              @input="getProjects()"
              autocomplete="off"
            />
            <ul class="list-group">
              <li
                class="list-group-item employee-list mt-1 bg-info text-white"
                v-for="project in projects"
                :key="project.id"
                @click="setProject(project);"
              >{{ project.name }}</li>
            </ul>
            <input type="hidden" name="project_id" id="project-id" v-model="project_id" />
          </div>

          <div class="col-2">
            <label for="inputSelectCompany">Company:</label>
            <select id="inputSelectCompany" class="form-control" v-model="company_id">
              <option
                v-for="option in companies"
                v-bind:key="option.id"
                v-bind:value="option.id"
              >{{ option.name }}</option>
            </select>
          </div>

          <div class="col-2">
            <label for="inputEmployeeStatus">Employee Status:</label>
            <select class="form-control" v-model="employee_status">
              <option>Active</option>
              <option>Inactive</option>
            </select>
          </div>
          <div class="col-2">
            <label for="inputLeaveCredits">Leave Credits:</label>
            <input
              class="form-control"
              type="number"
              v-model="leave_creadits"
              placeholder="Credits"
            />
          </div>
        </div>

        <div class="form-group row">
          <div class="col-3"></div>
          <div class="col">
            <label for="nation_id_number">Date Hired:</label>
            <input type="date" class="form-control" id="date_hired" v-model="date_hired" />
          </div>
          <div class="col">
            <label for="inputGrossMonthlySalary">Salary:</label>
            <input
              class="form-control"
              type="number"
              placeholder="0"
              @input="computeHourlyRate()"
              v-model="gross_monthly_salary"
            />
          </div>
          <div class="col">
            <label>Computed Hourly Rate:</label>
            <input class="form-control" type="number" v-model="hourly_rate" readonly />
          </div>

          <div class="col">
            <label>Allowance:</label>
            <input class="form-control" type="number" v-model="allowance" placeholder="Allowance" />
          </div>
        </div>
      </div>
      <!-- separator -->
      <div class="container-fluid" style="background-color: #F0F0F0; height: 1px"></div>
      <!--3rd container -->

      <div class="container-fluid mt-3">
        <h4>Emergency Contacts</h4>
        <div class="form-group row">
          <div class="col-3">
            <label for="inputEmergencyContactName">Name:</label>
            <input class="form-control" v-model="emergency_name" type="text" placeholder />
          </div>
          <div class="col-3">
            <label for="inputEmergencyContactNumber">Contact Number:</label>
            <input class="form-control" v-model="emergency_contact_number" type="text" placeholder />
          </div>
          <div class="col-3">
            <label for="inputEmergencyContactRelation">Relation:</label>
            <input class="form-control" v-model="emergency_person_relation" type="text" placeholder />
          </div>
          <div class="col-3">
            <label for="inputEmergencyContactRelation">Address:</label>
            <input class="form-control" v-model="emergency_person_address" type="text" placeholder />
          </div>
        </div>
      </div>

      <!-- separator -->
      <div class="container-fluid" style="background-color: #F0F0F0; height: 1px"></div>
      <!-- 4th container -->
      <div class="container-fluid mt-3">
        <h4>Educational Background</h4>

        <h5 class="mt-2">Elementary</h5>
        <div class="form-group row">
          <div class="col-4">
            <label for="inputElementarySchoolName">Name of School:</label>
            <input
              class="form-control"
              v-model="ed_background_elem_school_name"
              type="text"
              placeholder
            />
          </div>
          <div class="col-3">
            <label for="inputElementarySchoolYearAttended">Year Attended:</label>
            <input
              class="form-control"
              v-model="ed_background_elem_school_year"
              type="text"
              placeholder
            />
          </div>
          <div class="col-3">
            <label for="inputElementarySchoolAward">Honor/Awards:</label>
            <input class="form-control" v-model="ed_background_elem_awards" type="text" placeholder />
          </div>
        </div>

        <h5 class="mt-2">Secondary</h5>
        <div class="form-group row">
          <div class="col-4">
            <label for="inputSecondarySchoolName">Name of School:</label>
            <input
              class="form-control"
              v-model="ed_background_second_school_name"
              type="text"
              placeholder
            />
          </div>
          <div class="col-3">
            <label for="inputSecondarySchoolYearAttended">Year Attended:</label>
            <input
              class="form-control"
              v-model="ed_background_second_school_year"
              type="text"
              placeholder
            />
          </div>
          <div class="col-3">
            <label for="inputSecondarySchoolAward">Honor/Awards:</label>
            <input
              class="form-control"
              v-model="ed_background_second_awards"
              type="text"
              placeholder
            />
          </div>
        </div>

        <h5 class="mt-2">Tertiary</h5>
        <div
          class="form-group row"
          v-for="(educationalBackground,k) in educationalBackgroundsList"
          :key="k"
        >
          <div class="col-3">
            <input
              class="form-control"
              v-model="educationalBackground.school_name"
              type="text"
              readonly
            />
          </div>
          <div class="col-3">
            <input class="form-control" v-model="educationalBackground.degree" type="text" readonly />
          </div>
          <div class="col-2">
            <input class="form-control" v-model="educationalBackground.year" type="text" readonly />
          </div>
          <div class="col-3">
            <input class="form-control" v-model="educationalBackground.honor" type="text" readonly />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-3">
            <input
              class="form-control"
              v-model="ed_background_tert_school_name"
              type="text"
              placeholder="Name of School"
              required
            />
          </div>
          <div class="col-3">
            <input
              class="form-control"
              v-model="ed_background_tert_degree"
              type="text"
              placeholder="Degree/Course"
              required
            />
          </div>
          <div class="col-2">
            <input
              class="form-control"
              v-model="ed_background_tert_school_year"
              type="text"
              placeholder="Year Attended"
            />
          </div>
          <div class="col-3">
            <input
              class="form-control"
              v-model="ed_background_tert_awards"
              type="text"
              placeholder="Honor/Awards"
            />
          </div>
          <div class="col-1 pl-0 ml-0">
            <button
              type="button"
              class="btn btn-info btn-sm"
              @click.prevent="saveEducationBackground();"
            >
              <span class="fa fa-plus-circle mr-1"></span>Save
            </button>
          </div>
        </div>
        <h5 class="mt-2">Others</h5>
        <div class="form-group row" v-for="(vocationalCourse,k) in vocationalCoursesList" :key="k">
          <div class="col-3">
            <input class="form-control" v-model="vocationalCourse.school_name" type="text" readonly />
          </div>
          <div class="col-3">
            <input class="form-control" v-model="vocationalCourse.name" type="text" readonly />
          </div>
          <div class="col-2">
            <input class="form-control" v-model="vocationalCourse.year" type="text" readonly />
          </div>
          <div class="col-3">
            <input class="form-control" v-model="vocationalCourse.awards" type="text" readonly />
          </div>
        </div>

        <div class="form-group row">
          <div class="col-3">
            <input
              class="form-control"
              v-model="vocational_name"
              type="text"
              placeholder="Name of School"
            />
          </div>
          <div class="col-3">
            <input
              class="form-control"
              v-model="vocational_school_name"
              type="text"
              placeholder="Degree/Course"
            />
          </div>
          <div class="col-2">
            <input
              class="form-control"
              v-model="vocational_school_year"
              type="text"
              placeholder="Year Attended"
            />
          </div>
          <div class="col-3">
            <input
              class="form-control"
              v-model="vocational_awards"
              type="text"
              placeholder="Honor/Awards"
            />
          </div>
          <div class="col-1 pl-0 ml-0">
            <button
              type="button"
              class="btn btn-info btn-sm"
              @click.prevent="saveVocationalBackground();"
            >
              <span class="fa fa-plus-circle mr-1"></span>Save
            </button>
          </div>
        </div>
      </div>

      <!-- separator -->
      <div class="container-fluid" style="background-color: #F0F0F0; height: 1px"></div>
      <!-- 5th container -->
      <div class="container-fluid mt-3">
        <h4 class="d-inline">Attached Documents</h4>
        <input
          style="display: none"
          type="file"
          ref="attachedDocuments"
          id="attachedDocuments"
          accept=".pdf, .doc, .docx"
          multiple
          @change="onAttachedDocumentsChanged"
        />
        <button
          type="button"
          class="btn btn-primary d-inline ml-3"
          data-toggle="modal"
          data-target="#attach-file-modal"
        >
          <span class="fa fa-paperclip mr-2"></span>Attach File
        </button>

        <div class="form-group row mt-3">
          <div v-for="doc in attachFiles" :key="doc.id" class="mx-3" style="width: 90px">
            <i
              class="fas fa-times-circle d-flex justify-content-end"
              @click="removeAttachedDocument(doc.id)"
            ></i>
            <a :href="doc.file_path" target="_blank">
              <i class="fas fa-paperclip fa-border" style="font-size:48px;color:black;"></i>
            </a>
            <br />
            <label>{{ doc.name }}</label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          @click="removeAllAttachedFiles()"
        >
          <span class="fa fa-times mr-2"></span>Close
        </button>
        <button id="add_employee" type="button" class="btn btn-primary" @click="addEmployee">
          <span class="fa fa-save mr-2"></span>Add
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  mounted() {
    console.log(this.attachFiles);
    console.log("Add employee Component mounted.");

    //call only when input project this.getProjects();
    // get deductions
    axios.get("/api/v1/deductions").then(response => {
      this.deductions = response.data.data;
    });
    //auto id
    // this.getEmployeeLastId();
  },
  data() {
    // TODO
    return {
      profilePicture: null,
      first_name: "",
      middle_name: "",
      last_name: "",
      contact_number: "",
      address: "",
      birth_date: null,
      birth_place: "",
      philheath_number: "",
      pagibig_number: "",
      tin_number: "",
      sss_number: "",
      umid_number: "",
      drivers_license: "",
      national_id_number: "",
      prc_number: "",
      email: "",

      employee_category: null,
      employee_type: null,
      salary_period: null,
      employee_position: null,
      project_id: null,
      employee_status: "Active",
      leave_creadits: null,
      gross_monthly_salary: null,
      date_hired: "",
      hourly_rate: 0,
      emergency_name: null,
      allowance: 0,
      emergency_contact_number: null,
      emergency_person_relation: null,
      emergency_person_address: null,
      religion: "",
      insurance: "",
      civil_status: "",
      gender: "",

      ed_background_elem_school_name: null,
      ed_background_elem_school_year: null,
      ed_background_elem_awards: null,

      ed_background_second_school_name: null,
      ed_background_second_school_year: null,
      ed_background_second_awards: null,

      ed_background_tert_school_name: null,
      ed_background_tert_school_year: null,
      ed_background_tert_degree: null,
      ed_background_tert_awards: null,

      vocational_name: null,
      vocational_school_name: null,
      vocational_school_year: null,
      vocational_awards: null,

      errorMessage: null,
      profilePicPreviewUrl: null,
      attachedDocuments: Array(),
      projects: [],
      deductions: [],
      selectedDeductions: [],
      positions: [],
      projectName: "",
      positionName: "",
      position_id: "",
      check_address: false,
      educational_backgrounds: [],
      educationalBackgroundsList: [],
      vocational_courses: [],
      vocationalCoursesList: [],
      company_id: "",
      companies: {},
      added_by: this.currentUserId
    };
  },

  methods: {
    setCheckDeduction(selectedDeductionId){
      var checkDeductionId = 'checkDeduction' + selectedDeductionId;
      var inputDeductionAmount =  'inputDeductionAmount' + selectedDeductionId;
      var amount = document.getElementById(inputDeductionAmount).value;

      if(document.getElementById(checkDeductionId).checked){  //check if deduction is checked
          //remove deduction object if exist
          this.selectedDeductions =  this.removeDeductionObject(selectedDeductionId);
          // add new updated object to deduction array
          this.selectedDeductions.push({
            id: selectedDeductionId,
            amount: amount,
          });
      }else{
        //if not checked
       //check if deduction was added and remove it
        this.selectedDeductions =  this.removeDeductionObject(selectedDeductionId);
      }
    },

    removeDeductionObject(removeDeductionObjectID){
       var selectedDeductionsCopy = this.selectedDeductions;
       $.each(this.selectedDeductions, function(index, value) {
            if (value && value.id == removeDeductionObjectID) {
              selectedDeductionsCopy.splice(index, 1);
            }
          });

      return selectedDeductionsCopy;
    },

    saveEducationBackground() {
      if (
        this.ed_background_tert_school_name !== null ||
        this.ed_background_tert_degree !== null
      ) {
        //add to view list
        this.educationalBackgroundsList.push({
          employee_id: null,
          school_name: this.ed_background_tert_school_name,
          degree: this.ed_background_tert_degree,
          year: this.ed_background_tert_school_year,
          honor: this.ed_background_tert_awards
        });

        axios
          .post("/educational-backgrounds/store", {
            ed_background_tert_school_name: this.ed_background_tert_school_name,
            ed_background_tert_school_year: this.ed_background_tert_school_year,
            ed_background_tert_degree: this.ed_background_tert_degree,
            ed_background_tert_awards: this.ed_background_tert_awards
          })
          .then(response => {
            this.educational_backgrounds = this.educational_backgrounds.concat(
              response.data.data
            );
            this.ed_background_tert_school_name = "";
            this.ed_background_tert_school_year = "";
            this.ed_background_tert_awards = "";
            this.ed_background_tert_degree = "";
          });
      }
    },

    saveVocationalBackground() {
      //add to view list
      if (
        this.vocational_name !== null &&
        this.vocational_school_name !== null
      ) {
        this.vocationalCoursesList.push({
          school_name: this.vocational_name,
          name: this.vocational_school_name,
          year: this.vocational_school_year,
          awards: this.vocational_awards
        });
        axios
          .post("/vocational-courses/store", {
            vocational_name: this.vocational_name,
            vocational_school_name: this.vocational_school_name,
            vocational_school_year: this.vocational_school_year,
            vocational_awards: this.vocational_awards
          })
          .then(response => {
            this.vocational_courses = this.vocational_courses.concat(
              response.data.data
            );
            this.vocational_name = "";
            this.vocational_school_name = "";
            this.vocational_school_year = "";
            this.vocational_awards = "";
          });
      }
    },

    clearFormInputsData() {
      Object.assign(this.$data, this.$options.data()); //reset data property
    },

    checkBirthPlace() {
      if (this.check_address == true) {
        this.birth_place = this.address;
      } else {
        this.birth_place = "";
      }
    },

    getPositions() {
      axios.get("data/positions/" + this.positionName).then(response => {
        this.positions = response.data.data;
      });
    },

    setPosition(position) {
      this.positions = [];
      this.positionName = position.name;
      this.employee_position = position.id;
    },

    getProjects() {
      axios.get("/api/v1/projects/" + this.projectName).then(response => {
        this.projects = response.data.data;
      });
    },

    setProject(project) {
      this.projects = [];
      this.projectName = project.name;
      this.project_id = project.id;
      this.company_id = project.company_id ? project.company_id : null;

      if (this.company_id == '' || this.company_id == null) {
        //enable company input and load list
        axios.get("/companies/show").then(response => {
           this.companies = [];
          this.companies = response.data.data;
        });
      } else {
        //load project default company
        axios
          .get("data/companies/" + this.company_id + "/details")
          .then(response => {
            this.companies = response.data;
          });
      }
    },

    computeHourlyRate() {
      const filters = this.$options.filters;
      let value = 0.0;

      if (this.salary_period == "Daily" || this.salary_period == "Weekly") {
        value =
          this.gross_monthly_salary > 0 ? this.gross_monthly_salary / 8 : 0;
      } else {
        value =
          this.gross_monthly_salary > 0
            ? this.gross_monthly_salary / (30 * 8)
            : 0;
      }

      this.hourly_rate = value.toFixed(2);
    },

    getEmployeeLastId() {
      axios.get("/v1/employees/get-last-id").then(response => {
        console.log(response.data);
      });
    },

    selectProfilePic($event) {
      this.$refs.profilePicInput.click();
    },
    selectDocuments(event) {
      this.$refs.attachedDocuments.click();
    },
    onProfilePicChange(data) {
      const file = data.target.files[0];
      this.profilePicPreviewUrl = URL.createObjectURL(file);
      this.profilePicture = file;
    },
    onAttachedDocumentsChanged(data) {
      var files = data.target.files;

      console.log(files);
      if (files.length > 0) {
        for (var i = 0; i < files.length; i++) {
          var file = files[i];
          var k = file.name;
          // store selected file to attachedDocuments and replace any existed file with same key
          this.attachedDocuments[k] = file;
          // after add dapat mo update ang UI bugo.
        }
      }
      console.log("onAttachedDocumentsChanged");
      console.log(this.attachedDocuments);
    },

    removeAttachedDocument(id) {
      this.isLoading = true;
      axios
        .post("/attach-files/destroy", {
          id: id
        })
        .then(response => {
          this.isLoading = false;

          let attachFilesCopy = this.attachFiles;
          $.each(this.attachFiles, function(index, value) {
            if (value && value.id == id) {
              attachFilesCopy.splice(index, 1);
            }
          });

          console.log(response);

          this.attachFiles = attachFilesCopy;
        });
    },

    removeAllAttachedFiles() {
      // clear the error message
      this.errorMessage = null;
      let vm = this;
      $.each(this.attachFiles, function(index, value) {
        vm.removeAttachedDocument(value.id);
      });
    },
    toFormData(obj, form, namespace) {
      let fd = form || new FormData();
      let formKey;

      for (let property in obj) {
        if (obj.hasOwnProperty(property) && obj[property]) {
          if (namespace) {
            formKey = namespace + "[" + property + "]";
          } else {
            formKey = property;
          }

          // if the property is an object, but not a File, use recursivity.
          if (obj[property] instanceof Date) {
            fd.append(formKey, obj[property].toISOString());
          } else if (
            typeof obj[property] === "object" &&
            !(obj[property] instanceof File)
          ) {
            this.toFormData(obj[property], fd, formKey);
          } else {
            // if it's a string or a File object
            fd.append(formKey, obj[property]);
          }
        }
      }

      return fd;
    },
    addEmployee() {
      // todo check required input here like project_id
      // send inputs to endpoint
      const axios = require("axios");
      // append this attached files to data
      this.$data.attachFiles = this.attachFiles;
      let formData = this.toFormData(this.$data);
      axios({
        method: "post",
        url: "/api/v1/employees/store",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(response => {
        console.log(response.data);
        // TODO display error message to user
        // error
        if (response.data.status === "ok") {
          console.log("employee added!");
          $("#newEmployeeModal").modal("toggle");
          $("#refreshButton").click(); //refresh datatable
          this.clearFormInputsData(); //clear form inputs
        } else {
          console.log(response.data);
          const messages = response.data.message;
          this.errorMessage = " <h4>Please fix:</h4>";
          this.errorMessage = this.errorMessage + "<ul>";
          for (var key in messages) {
            if (!messages.hasOwnProperty(key)) continue;
            var obj = messages[key];
            this.errorMessage =
              this.errorMessage + "<li>" + key + " - " + obj + "</li>";
          }
          $("#newEmployeeModal").animate({ scrollTop: 0 }, 300, "linear");
        }
      });
    },

    getEmployeeCategories() {
      let options = "";
      $.each(this.employeeCategories, function (index, value) {
        options +=
          '<option value="' + value + '">' + value + "</option>";
      });
      return options;
    },
  },

  props: ["attachFiles", 'employeeCategories', 'currentUserId']
};
</script>
