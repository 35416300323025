<template>
    <div>
        <loading
            :active.sync="isLoading"
            :is-full-page="fullPage">
        </loading>


        <label>Search Employee:</label>
        <input type="text" class="form-control mb-2" v-model="name" placeholder="Employee Name" @input="getAvailableEmployeesByName()"/>
        <ul class="list-group">
            <li class="list-group-item" v-for="(employee, index) in employees" :key="index">{{ employee.first_name }} {{ employee.middle_name }} {{ employee.last_name }} <button type="button" class="btn btn-success" @click="addEmployeeToProject(employee.id)"><span class="fa fa-user-plus mr-1"></span>Add</button></li>
        </ul>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {

        mounted() {
           this.getAvailableEmployees();
        },

        data(){
            return({
                employees: [],
                name: '',
                isLoading: false,
                fullPage: true
            })
        } ,

        methods:{
            getAvailableEmployees(){
                axios.get('/get-employees')
                .then(response => {
                    this.employees = response.data.data;
                });
            },

            getAvailableEmployeesByName(){
                axios.get('/get-employees-by-name?name='+this.name)
                .then(response => {
                    this.employees = response.data.data;
                });
            },

            addEmployeeToProject(employee_id){
                this.isLoading = true;
                axios.post('/add-employee-to-project',{
                    project_id: this.projectId,
                    employee_id: employee_id
                })
                .then(response => {
                    this.getAvailableEmployees();
                    this.isLoading = false;
                });
            }
        },

        props:[
            'projectId'
        ],

        components: {
            Loading
        },
    }
</script>


