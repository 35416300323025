<template>
  <div class="row justify-content-center">
    <div class="col-md-4">
      <div class="card">
        <div class="card-header">
          <div class>
            <form class="form-inline d-flex md-form form-sm mt-0">
              <input
                class="form-control form-control-sm mx-1"
                type="text"
                placeholder="Search..."
                aria-label="Search"
                @input="debouncedConversationSearchOnChange"
                v-model="conversationQuery"
              />
              <i class="fas fa-search ml-3" aria-hidden="true" @click="getConversations"></i>
            </form>
          </div>
          <div>
            <button
              class="btn btn-primary mt-2 center-block"
              style="width: 100%"
              data-toggle="modal"
              data-target="#newMessage"
            >
              <i class="fas fa-plus" aria-hidden="true"></i>
              New Message
            </button>
          </div>
        </div>
        <div class="card-body inbox-chat">
          <div
            v-for="item in conversations"
            class="chat-list item"
            v-bind:class="{'active-chat':selectedConversation!=null && selectedConversation.id == item.id}"
            :key="item.id"
          >
            <div class="chat-people" @click="showMessages(item)">
              <div class="chat-img">
                <span
                  v-if="getUnreadCount(item)"
                  class="badge badge-danger"
                >Unread ({{getUnreadCount(item)}})</span>
                <div v-else style="height: 20px"></div>
                <img :src="getRecipient(item).avatar_path" alt="avatar" />
              </div>
              <div class="chat-user-details">
                <h6 class="user-list">
                  <b>{{ getRecipient(item).name }}</b>
                </h6>
                <p
                  style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-size:smaller"
                >{{ getRecipient(item).email }}</p>
                <p
                  style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; color: grey"
                >{{ item.lastMessage.message }}</p>
                <span
                  data-v-80d584ac
                  style="font-size: xx-small;"
                  class="chat_date badge badge-success float-right"
                >{{getRelativeTime(item.lastMessage.created_at)}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="card">
        <div class="card-header">
          Messages
          <strong></strong>
        </div>
        <div class="card-body">
          <div class="messaging">
            <div class="msg-history" id="messageList" ref="messageList">
              <!-- TO DO: get current user -->
              <div
                v-for="item in messages"
                :class="item.user.id == user.id ? 'outgoing-msg' : 'incoming-msg'"
                :key="item.id"
              >
                <div v-if="item.user.id !== user.id " class="incoming-msg-img">
                  <img
                    class="chat-avatar"
                    v-if="item.user.id !== user.id "
                    :src="item.user.avatar_path"
                    onerror="src='/storage/avatar/default.png'"
                    alt="avatar"
                  />
                </div>
                <div :class="item.user.id == user.id ? 'sent-msg' : 'received-msg'">
                  <div class="received-with-msg">
                    <!-- <h6>
                      <small>{{ item.user.name }}</small>
                    </h6> -->
                    <p>{{ item.message }}</p>
                    <span class="badge badge-success">{{ getRelativeTime(item.created_at) }}</span>
                  </div>
                </div>
                <!-- <div v-if="item.user.id === user.id " class="outgoing-msg-img">
                  <img class="chat-avatar"
                    :src="'/storage/'+item.user.avatar_path"
                    onerror="src='/storage/avatar/default.png'"
                    alt="sunil"
                  />
                </div>-->
              </div>
            </div>
            <div class="type-msg">
              <div class="input-msg-write">
                <textarea
                  name
                  @keyup.enter="sendMessage(getRecipient(selectedConversation))"
                  v-model="messageText"
                  placeholder="Type a message"
                ></textarea>
              </div>
            </div>
            <div class="row-fluid">
              <button
                class="msg-send-btn btn btn-primary"
                name
                id
                title="Send Message"
                @click="sendMessage(getRecipient(selectedConversation))"
                type="button"
              >
                <i class="fas fa-chevron-circle-right" aria-hidden="true"></i> Send Message
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- New message modal -->
    <div
      class="modal fade"
      id="newMessage"
      tabindex="-1"
      role="dialog"
      aria-labelledby="newMessage"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">New Message</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="recipient-name" class="col-form-label">Recipient:</label>
                <div v-if="recipient">
                  <button class="btn btn-primary" v-on:click="clearRecipient">{{recipient.name}}</button>
                </div>
                <div v-else>
                  <input
                    type="text"
                    class="form-control"
                    id="recipient-name"
                    placeholder="Search user..."
                    @input="getUsers($event)"
                  />
                  <div class="collapse.show contact-list" id="userList">
                    <div
                      class="row d-flex flex-row user-card align-items-center"
                      v-for="user in users"
                      v-bind:key="user.id"
                      v-on:click="recipient = user"
                    >
                      <div
                        :style="'background-image: url('+user.avatar_path+')'"
                        alt="avatar"
                        class="user-img"
                      ></div>

                      <div class="details">
                        <div>
                          <strong>{{user.name}}</strong>
                        </div>
                        <div>{{user.email}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="message-text" class="col-form-label">Message:</label>
                <textarea class="form-control" ref="newMessageText"></textarea>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button
              type="button"
              class="btn btn-primary"
              :disabled="recipient == null"
              v-on:click="sendNewMessage(recipient)"
            >Send message</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user"],
  mounted() {
    this.getConversations();
    this.listenChatEvent();
  },

  data() {
    return {
      recipient: null,
      conversationQuery: "",
      conversations: [],
      messages: [],
      users: [],
      messageText: "",
      selectedConversation: null
    };
  },
  methods: {
    debouncedConversationSearchOnChange: _.debounce(function() {
      this.getConversations();
    }, 500),
    sendNewMessage(recipient) {
      var message = this.$refs.newMessageText.value;
      this.messageText = message;
      this.sendMessage(recipient);
    },
    sendMessage(recipient) {
      //console.log("sendMessage..");
      if (!this.messageText || this.messageText.trim() === "") {
        return;
      }
      axios
        .post("/api/v1/conversations/send-message", {
          message: this.messageText.trim(),
          senderId: this.user.id,
          recipientId: recipient.id
        })
        .then(response => {
          //console.log(response.data);
          this.getConversations();
        });
      this.messageText = null;
      this.$refs.newMessageText.value = null;
      this.recipient = null;
      this.users = null;
    },
    getConversations() {
      // console.log("getConversations");
      axios
        .get("/api/v1/conversations/", {
          params: {
            userId: this.user.id,
            query: this.conversationQuery
          }
        })
        .then(response => {
          this.conversations = response.data.data;
          if (this.conversations.length) {
            if (this.selectedConversation == null) {
              this.showMessages(this.conversations[0]);
            } else {
              this.showMessages(this.selectedConversation);
            }
          }
        });
    },
    showMessages(conversation) {
      //console.log("refresh selected conversation message");
      this.selectedConversation = conversation;
      // clear unread count 
      conversation.userOneUnreadCount = 0
      conversation.userTwoUnreadCount = 0
      axios
        .get("/api/v1/conversations/" + conversation.id + "/messages")
        .then(response => {
          this.messages = response.data.data;

          // scroll to bottom
          setTimeout(() => {
            this.$refs.messageList.scrollTop = this.$refs.messageList.scrollHeight;
          }, 500);
          this.markConversationAsRead(conversation);
        });
    },
    getRecipient(conversation) {
      return conversation.user_one.id == this.user.id
        ? conversation.user_two
        : conversation.user_one;
    },
    getUsers(event) {
      var query = event == null ? "" : event.target.value;
      axios
        .get("/api/v1/users", {
          params: {
            q: query,
            except: this.user.id
          }
        })
        .then(response => {
          this.users = response.data.data;
          // this.$refs.userListToggle.click();
        });
    },
    clearRecipient() {
      this.recipient = null;
      this.getUsers();
    },
    markConversationAsRead(conversation) {
      conversation.unread = false;
      var id =
        conversation.user_one.id == this.user.id
          ? conversation.user_two.id
          : conversation.user_one.id;
      axios
        .put("/api/v1/conversations/" + conversation.id + "/markAsRead", {
          user_id: id
        })
        .then(response => {
          this.$parent.getUnreadMessageCount(this.user.id);
        });
    },
    getUnreadCount(conversation) {
      return conversation.user_one.id == this.user.id
        ? conversation.userTwoUnreadCount
        : conversation.userOneUnreadCount;
    },
    listenChatEvent() {
      window.Echo.channel("chat-channel").listen("ChatEvent", data => {
        console.log("new conversation reload conversation list");
        if (data.receiver.id == this.user.id) {
          //console.log("reloading...");
          this.getConversations();
        }
      });
    },
    getRelativeTime(dateString) {
      var current = new Date();
      var date = new Date(dateString);
      var msPerMinute = 60 * 1000;
      var msPerHour = msPerMinute * 60;
      var msPerDay = msPerHour * 24;
      var msPerMonth = msPerDay * 30;
      var msPerYear = msPerDay * 365;

      var elapsed = current - date;
      if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + " seconds ago";
      } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + " minutes ago";
      } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + " hours ago";
      } /* if (elapsed < msPerMonth) */ else {
        // return "approximately " + Math.round(elapsed / msPerDay) + " days ago";

        return date.toLocaleString();
      }

      // else if (elapsed < msPerYear) {
      //   return (
      //     "approximately " + Math.round(elapsed / msPerMonth) + " months ago"
      //   );
      // } else {
      //   return (
      //     "approximately " + Math.round(elapsed / msPerYear) + " years ago"
      //   );
      // }
    }
  }
};
</script>
<style scoped>
.incoming-msg-img {
  display: inline-block;
  width: auto;
  height: auto;
}
.chat-avatar {
  width: 50px;
  height: 50px;
  padding: 2px;
}

.incoming-msg-img {
  display: inline-block;
  width: auto;
  height: auto;
}
.received-msg {
  display: inline-block;
  padding: 0 0 0 0;
  vertical-align: top;
}
.received-with-msg p {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 15px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
}

.received-with-msg {
  width: 80%;
}
.mesgs {
  padding: 10px 10px 0 10px;
  width: auto;
}

.sent-msg p {
  background: #05728f none repeat scroll 0 0;
  border-radius: 15px;
  font-size: 14px;
  margin: 0;
  color: #fff;
  padding: 5px 10px 5px 12px;
  width: 100%;
}
.outgoing-msg {
  overflow: hidden;
  margin: 26px 0 26px;
}
.sent-msg {
  float: right;
  width: 50%;
}
.input-msg-write textarea {
  overflow-y: scroll;
  background: #d6d6d6;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  width: 100%;
  min-height: 60px;
  overflow-y: scroll;
  padding: 20px;
}

.type-msg {
  border-top: 1px solid #c4c4c4;
  position: relative;
}
.msg-send-btn {
  border: medium none;
  color: #fff;
  float: left;
  padding: 10px;
  cursor: pointer;
}
.messaging {
  padding: 0 0 50px 0;
}
.msg-history {
  height: 400px;
  overflow-x: auto;
}
.container {
  max-width: 100%;
  margin: auto;
}
img {
  max-width: 100%;
}
.inbox-users {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 50%;
  border-right: 1px solid #c4c4c4;
}
.inbox-messages {
  border: 1px solid #c4c4c4;
  clear: both;
  overflow: hidden;
}

.chat-img {
  float: left;
  width: 25%;
}

.chat-img img {
  border-radius: 50%;
  height: 48px;
  width: 48px;
}
.chat-user-details {
  text-align: left;
  /* padding: 5px; */
  width: 95%;
}

.user-list {
  cursor: hand;
}
.chat-people {
  overflow: hidden;
  clear: both;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: pointer;
}
.item {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 5px;
}
.inbox-chat {
  height: 400px;
  overflow-y: scroll;
}

.active-chat {
  background: #ebebeb;
}
.card-body.inbox-chat {
  padding: 0;
}
.contact-list {
  overflow-y: scroll;
  max-height: 200px;
  overflow-y: scroll;
}

.user-card {
  margin: 8px;
  padding: 8px 16px;
  border-radius: 48px;
  background-color: #38c172;
  color: white;
}

.user-card .user-img {
  height: 48px;
  width: 48px;
  background-size: cover;
  background-position: center;
  border-radius: 24px;
  border: 1px solid white;
}

.user-card .details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
</style>