<template>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">Add Employee</h5>

        <div>
            <button
                type="button"
                class="btn btn-sm btn-secondary close-select-employee"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span class="fa fa-times mr-1"></span>Cancel
            </button>
            <button type="submit" class="btn btn-sm btn-primary" @click="setEmployeeIds()">
                <span class="fa fa-plus mr-1"></span>Add
            </button>
        </div>

    </div>

    <div class="modal-body">
      <table class="table table-bordered table-sm w-100" id="employee-multiple-select-table">
        <thead>
          <tr>
            <th>
              <input id="check-all-checkboxes" type="checkbox" @click="toggleCheckAll()" />
            </th>
            <th>Employee ID</th>
            <th>Last Name</th>
            <th>First Name</th>
            <th>Middle Name</th>
            <th>Age</th>
            <th>Category</th>
            <th>Type</th>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</template>
<script>

  import { mapState, mapMutations, mapActions } from 'vuex'

  export default {
    mounted() {

    },

    computed: {
        ...mapState({
            selectedEmployees: state => state.payroll.selectedEmployees,
            fromDate: state => state.payroll.fromDate,
            toDate: state => state.payroll.toDate
        }),
    },

    data() {
      return {
        projects: [],
        projectOptions: "",
        table: {},
        employeeIds: [],
        employees: [],
        employeeKeyValue: {},
        dataUrl: ''
      };
    },

    watch: {
        toDate: function(value) {
            if (value) {
                this.dataUrl = "/data/payroll/employees-multiple-select?from_date="+this.fromDate+"&to_date="+this.toDate
                this.getProjects();
                this.prepareDataTable();
            }
        },
        // selectedEmployees: function(value) {
        //     if(value.length > 0){

        //     }
        // }
    },

    methods: {
      ...mapMutations('payroll', [
        'setSelectedEmployees',
        'setPayrollProjectId'
      ]),

      ...mapActions('payroll', [
        'generatePayrollEntry'
      ]),

      getProjects() {
        axios.get("/api/v1/projects").then(response => {
          this.projects = response.data.data;
          let vm = this;
          this.projects.forEach(function(project, index) {
            vm.projectOptions = vm.projectOptions.concat(
              '<option value="' + project.id + '">' + project.name + "</option>"
            );
          });
          this.setFilters();
        });
      },

      toggleCheckAll() {
        let vm = this;
        $(".employee-id-checkbox").prop(
          "checked",
          !$(".employee-id-checkbox").prop("checked")
        );
        $(".employee-id-checkbox").each(function(index, checkbox) {
          if ($(checkbox).prop("checked")) {
            vm.employeeIds.push($(checkbox).val());
            vm.employees.push(vm.employeeKeyValue[$(checkbox).val()]);
          } else {
            vm.employeeIds = [];
            vm.employees = [];
          }
        });
      },

      setEmployeeIds() {
        this.setSelectedEmployees(this.employees);
        this.generatePayrollEntry();
        this.employeeIds = [];
        this.employees = [];
        this.employeeKeyValue = [];
        $("#check-all-checkboxes").prop("checked", false);
        if (this.isEmpty(this.table)) {
          this.prepareDataTable();
          this.table.draw();
        }

        this.table.ajax.reload();
        $(".close-select-employee").click();
      },

      setFilters() {
        $("div.sme-toolbar").html(
          `
                  <div class="d-flex flex-row align-items-center">
                      <label for="project" class="label-m0 mr-1">
                          Project:
                      </label>
                      <select id="project-select" name="project_id" class="form-control form-control-sm mr-2">
                          <option value="">All</option>
                          ` +
            this.projectOptions +
            `
                      </select>


                      <label for="category" class="label-m0 mr-1">
                          Category:
                      </label>
                      <select id="category-select" name="category_id" class="form-control form-control-sm mr-2">
                          <option value="">All</option>
                          <option>Admin</option>
                          <option>Civil</option>
                          <option>Sub-Contractor</option>

                      </select>


                      <label for="category" class="label-m0 mr-1">
                          Type:
                      </label>
                      <select id="type-select" name="type_id" class="form-control form-control-sm ">
                          <option value="">All</option>
                          <option>Probation</option>
                          <option>Regular</option>
                          <option>Project Base</option>
                      </select>

                  </div>
              `
        );

        let vm = this;
        $(document).ready(function() {
          $("#project-select").change(function() {
            vm.table
              .columns(0)
              .search(this.value)
              .draw();
            vm.setPayrollProjectId(this.value);
          });

          $("#category-select").change(function() {
            vm.table
              .columns(1)
              .search(this.value)
              .draw();
          });

          $("#type-select").change(function() {
            vm.table
              .columns(2)
              .search(this.value)
              .draw();
          });
        });
      },
      isEmpty(obj) {
        for (var key in obj) {
          if (obj.hasOwnProperty(key)) return false;
        }
        return true;
      },

      prepareDataTable() {
        let vm = this;
        let table = $("#employee-multiple-select-table").DataTable({
          serverSide: true,
          processing: true,
          ajax: vm.dataUrl,
          lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]],
          columns: [
            {
              data: 'id',
              sortable: false,
              render: function(data, type, full, meta) {
                var html =
                  '<input type="checkbox" class="employee-id-checkbox" name="employee_id" value="' +
                  full.id +
                  '" /> ';
                vm.employeeKeyValue[full.id] = full;
                return html;
              }
            },
            { data: "id" },
            { data: "last_name" },
            { data: "first_name" },
            { data: "middle_name" },
            { sortable: true, data: "age" },
            { data: "employee_category" },
            { data: "employee_type" }
          ],

          dom: `
                      <"row"
                          <"col-2"l><"col-7 sme-toolbar"><"col-2"f>
                      >
                      <rtip>
                  `,

          drawCallback: function(settings) {
            $(document).ready(function() {
              $(".employee-id-checkbox").click(function() {
                if ($(this).prop("checked")) {
                  if (vm.employeeIds.indexOf(this.value) === -1) {
                    vm.employeeIds.push(this.value);
                    vm.employees.push(vm.employeeKeyValue[this.value]);
                  }
                } else {
                  let index = vm.employeeIds.indexOf(this.value);
                  vm.employeeIds.splice(index, 1);
                  vm.employees.slice(index, 1);
                }

                if ($(".employee-id-checkbox").length === vm.employeeIds.length) {
                  $("#check-all-checkboxes").prop("checked", true);
                } else {
                  $("#check-all-checkboxes").prop("checked", false);
                }
              });
            });
          }
        });

        //initialize vue data property
        this.table = table;
        this.table.draw();
      }
    }
  }
</script>
