import devlarn from '../../api/devlarn'
import { min } from 'lodash';
import moment from 'moment';
// initial state
const state = () => ({
    employeeNameId: "",
    employeeId: 0,
    employees: [],

    projectNameId: "",
    projectId: 0,
    projects: [],

    date: "",
    time: "",
    transaction: "",

    isEmployee: false,
    isProject: false,

    isLoading: false,
    fullPage: true,
    timeLog: "",
    timeLogs: [],
    calculatedTravelTime: 0,
    timeAdjustment: null,
    timeAdjustmentAddOrSub: "",
    timeAdjustmentMinutes: "",
    timeAdjustmentRemark: "",
    isWithPay: false,
    isLeave: false,
})

// getters
const getters = {

}

// actions
const actions = {
    searchEmployees({ state, commit }, employeeNameId) {
        state.employeeNameId = employeeNameId;
        state.isEmployee = false;
        devlarn.searchEmployees(employees => {
            commit('setEmployees', employees);
        }, state.employeeNameId);
    },

    searchProjects({ state, commit }, projectNameId) {
        state.projectNameId = projectNameId;
        state.isProject = false;
        devlarn.searchProjects(projects => {
            commit('setProjects', projects);
        }, state.projectNameId);
    },

    addTimeLog({ state, commit }) {
        if (state.timeLog != "") {
            commit('addTimeLogTime');
        }
        let payloads = {
            employee_id: state.employeeId,
            project_id: state.projectId,
            // _token: this.csrf,
            date: state.date,
            time_logs: state.timeLogs,
            transaction: state.transaction,
            timeAdjustment: state.timeAdjustment,
            isLeave: state.isLeave,
            isWithPay : state.isWithPay,
            timeAdjustment: state.timeAdjustment
        };

        if (
            state.isProject &&
            state.isEmployee &&
            state.date &&
            state.timeLogs.length > 0
        ) {
            state.isLoading = true;
            devlarn.addTimeLog(response => {
                state.isLoading = false;
                if (response.data.status === "failed") {
                    alert(response.data.message);
                } else {
                    console.log("closing modal ...");
                    $(".close").click();
                }
            }, payloads);

        } else {
            alert("Missing inputs");
            console.log(payloads);
        }
    },

    getTravelTime({ state, commit }) {
        console.log('getTravelTime timelogs=' + state.timeLogs.length)
        if (state.timeLogs.length > 0
            && state.employeeId != 0
            && state.projectId != 0
            && state.date != "") {
            // query database and calculate travel time
            var timeLogIn = state.timeLogs[0].time;
            var timeLogOut;
            if (state.timeLogs.length > 1) {
                timeLogOut = state.timeLogs[state.timeLogs.length - 1].time;
            }
            devlarn.getTimeDifferenceFromPreviousTime(calculatedTravelTime => {
                commit('setCalculatedTravelTime', calculatedTravelTime)
            }, state.employeeId, state.projectId, state.date, timeLogIn, timeLogOut);

        } else {
            commit('setCalculatedTravelTime', 0)
        }
    },

}

// mutations
const mutations = {
    setEmployees(state, employees) {
        state.employees = employees;
    },

    setEmployee(state, employee) {
        state.isEmployee = true;
        state.employeeNameId =
            employee.first_name +
            " " +
            employee.middle_name +
            " " +
            employee.last_name;
        state.employeeId = employee.id;
        state.employees = [];
    },

    setProjects(state, projects) {
        state.projects = projects
    },

    setProject(state, project) {
        state.isProject = true;
        state.projectNameId = project.name;
        state.projectId = project.id;
        state.projects = [];
    },

    clearInputs(state) {
        state.employeeNameId = null;
        state.projectNameId = null;
        state.date = null;
        state.timeLog = "";
        state.timeLogs = [];
        state.transaction = "";
        state.employeeId = 0;
        state.projectId = 0;
        state.calculatedTravelTime = 0;
        state.timeAdjustment = null;
        state.timeAdjustmentMinutes = "";
        state.timeAdjustmentRemark = "";
        state.isWithPay = false;
        state.isLeave = false;
    },

    addTimeLogTime(state) {
        if (state.timeLog != "") {
            var timeLog = {
                time: state.timeLog,
                transaction: state.transaction
            }
            state.timeLogs.push(timeLog);
            state.timeLogs.length = state.timeLogs.length++;
            state.timeLog = "";
            state.transaction = "";
            // sort
            state.timeLogs.sort(function (a, b) {
                var aa = moment(a.time, 'HH:mm').unix();
                var bb = moment(b.time, 'HH:mm').unix();
                return aa - bb;
            });
        }
    },

    setDate(state, date) {
        state.date = date
    },

    setTimeLog(state, timeLog) {
        state.timeLog = timeLog;
    },

    clearEmployeeInput(state) {
        state.employeeId = 0;
        state.employeeNameId = "";
    },

    clearProjectInput(state) {
        state.projectId = 0;
        state.projectNameId = "";
    },

    setCalculatedTravelTime(state, calculatedTravelTime) {
        state.calculatedTravelTime = calculatedTravelTime;
    },

    setTimeAdjustmentMinutes(state, minutes) {
        state.timeAdjustmentMinutes = minutes;
    },

    setTimeAdjustmentRemark(state, remark) {
        state.timeAdjustmentRemark = remark;
    },

    setTimeAdjustment(state, timeAdjustment) {
        if (timeAdjustment != null && timeAdjustment.minutes && timeAdjustment.remark) {
            state.timeAdjustment = timeAdjustment;
        } else {
            state.timeAdjustment = null;
        }
    },

    removeTimeLog(state, index) {
        state.timeLogs.splice(index, 1)
    },
    setIsLeave(state, val){
        state.isLeave = val;
        state.isWithPay =  false;
    },
    setIsWithPay(state, value){
        state.isWithPay =  value;
    },
    setTimeLogTransaction(state, transaction) {
        state.transaction = transaction
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
