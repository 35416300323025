import devlarn from '../../api/devlarn'

// initial state
const state = () => ({
  specialDeductionDetails: {},
  types: []
})

// getters
const getters = {
  
}

// actions
const actions = {
  updateSpecialDeductionDetails ({}, details) {
    devlarn.updateSpecialDeductionDetails(details)
  },

  getSpecialDeductionTypes({commit}) {
    devlarn.getSpecialDeductionTypes(types => {
      commit('setTypes', types)
    })
  },
}

// mutations
const mutations = {
  setSpecialDeductionDetails(state, details){
    state.specialDeductionDetails = details;
  },

  setTypes(state, types){
    state.types = types;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}