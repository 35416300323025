<template>
</template>

<script>
    export default {
        mounted() {
            var vm = this;
            let table = $('#employee-list-table').DataTable({
                serverSide: true,
                processing: true,
                ajax:"/data/projects/"+this.projectId+"/employees",
                columns: [
                    { data: 'id' },
                    { data: 'last_name' },
                    { data: 'first_name' },
                    { data: 'middle_name' },
                    { data: 'employee_category' },
                    { data: 'position' },
                    { 
                        sortable: false,
                        render: function(data, type, full, meta) {
                            if(full.added_to_biometrics === 0 || full.added_to_biometrics === undefined ){
                                return  '<center><i class="fa fa-times biometrics-times"></i></center>';
                            }else{
                                return  '<center><i class="fa fa-check biometrics-check"></i></center>';
                            }
                        }
                        
                    },
                    {
                        sortable: false,
                        "render": function ( data, type, full, meta ) {
                            var html = `
                                <button type="button" class="btn btn-sm btn-danger" data-toggle="modal" data-target="#delete-employee-modal-`+full.id+`">
                                   <span class="fa fa-trash-alt mr-1"></span> Remove
                                </button>
                                <!-- Modal -->
                                <div class="modal fade" id="delete-employee-modal-`+full.id+`" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">Delete Employee</h5>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <form method="post" action="/remove-employee-from-project">
                                                <input type="hidden" name="employee_id" value="`+full.id+`"/> 
                                                <input type="hidden" name="_token" value="`+vm.csrf+`"/>
                                                <div class="modal-body">
                                                    Are you sure you want to remove `+full.first_name+`?
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary" data-dismiss="modal"><span class="fa fa-times mr-1"></span>Cancel</button>
                                                    <button type="sumbit" class="btn btn-danger"><span class="fa fa-trash-alt mr-1"></span>Remove</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>`;

                            if(full.added_to_biometrics !== 1){
                                html = html.concat(
                                `
                                    <button 
                                        type="button" 
                                        class="btn btn-primary btn-sm add-to-biometrics-button m-1" 
                                        data-employee-id="`+full.id+`">
                                            <span class="fa fa-plus mr-1"></span>
                                            Add to Biometrics
                                    </button>
                                `);
                            }else{
                                html = html.concat(
                                `
                                    <button 
                                        type="button" 
                                        class="btn btn-danger btn-sm remove-to-biometrics-button m-1" 
                                        data-employee-id="`+full.id+`">
                                            <span class="fa fa-minus mr-1"></span>
                                            Remove from Biometrics
                                    </button>
                                `);
                            }
                            
                            return html;
                        }
                    }
                ],

                drawCallback: function(){
                    $(".add-to-biometrics-button").click(function() {
                        var employee_id = $(this).data("employee-id"); 
                        vm.addToBiometrics(employee_id);
                    });

                    $(".remove-to-biometrics-button").click(function() {
                        var employee_id = $(this).data("employee-id"); 
                        vm.removeToBiometrics(employee_id);
                    });  
                }
            });

            this.table = table;

        },

        data(){
            return{
                table: {}
            }
        },

        props:[
            'projectId',
            'csrf'
        ],

        methods: {
            addToBiometrics(employee_id){
                axios.post('/employees/biometrics', {
                    employee_id: employee_id
                }).then(response => {
                    this.table.draw( false );
                });
            },

            removeToBiometrics(employee_id){
                axios.post('/employees/biometrics', {
                    employee_id: employee_id,
                    action: 'remove'
                }).then(response => {
                    this.table.draw( false );
                });
            }
        }
    }
</script>
