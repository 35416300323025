<template>
  <div>
    <notifications group="app" />
  </div>
</template>
<script>
import Vue from "vue";
import moment from 'moment';

export default {
  props: ["userId"],
  mounted() {
    // initial unread count
    this.$parent.getUnreadMessageCount(this.userId);
    this.listenChatEvent();
    this.listenNotificationEvent();
    this.listenDtrImportProgressEvent();
  },
  data() {
    return {};
  },
  methods: {
    listenChatEvent() {
      console.log("listening chat-channel ChatEvent...");
      // eventId = userId
      // listen for chat events
      window.Echo.channel("chat-channel").listen("ChatEvent", (data) => {
        console.log(data);
        if (data.receiver.id == this.userId) {
          Vue.notify({
            group: "app",
            duration: 60 * (60 * 1000),
            position: "top left",
            title:
              '<img src="' +
              data.sender.avatar_path +
              '" style="width: 38px; height: 38px;padding: 2px; border-radius: 50%;"/>   ' +
              data.sender.name,
            text: '<div class="ml-4">' + data.message + "</div>",
          });

          this.$parent.getUnreadMessageCount(this.userId);
          var audio = new Audio("/storage/sounds/notification.mp3");
          audio.play();
        }
      });
    },
    listenNotificationEvent() {
      console.log("listening notification-channel NotificationEvent...");
      window.Echo.channel("notification-channel").listen(
        "NotificationEvent",
        (data) => {
          console.log(data);
          var time = moment(new Date()).format('DD-MM-YYYY h:mm:ss a');
          Vue.notify({
            group: "app",
            duration: 60 * (60 * 1000),
            position: "top left",
            // title:
            //   '<img src="' +
            //   data.sender.avatar_path +
            //   '" style="width: 38px; height: 38px;padding: 2px; border-radius: 50%;"/>   ' +
            //   data.sender.name,
            text: '<div class="ml-4">' + data.message + "<p>"+ time +"</p>"+ "</div>",
          });
  //        var audio = new Audio("/storage/sounds/notification.mp3");
    //      audio.play();
          if(data.message == 'Done importing daily time records'){
            alert(data.message + ' ' + time);
          }
        }
      );
    },
   listenDtrImportProgressEvent() {
      console.log("listening dtr-import-channel DtrImportProgressEvent...");
      window.Echo.channel("dtr-import-channel").listen(
        "DtrImportProgressEvent",
        (data) => {
          console.log(data.message);
        }
      );
    },
  },
};
</script>