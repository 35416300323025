<template>
  <div class="table-responsive">
    <table
      id="report-list-table"
      class="table table-striped table-sm dt-responsive nowrap"
      style="width:100%"
    >
      <thead>
        <tr>
          <th rowspan="2">#</th>
          <th rowspan="2">Name</th>
          <th rowspan="2">Designation</th>
          <th rowspan="2">Monthly Rate</th>
          <th rowspan="2">Calendar Days</th>
          <th colspan="2">Rate</th>
          <th rowspan="2">Work Days</th>
          <th rowspan="2">Amount</th>
          <th colspan="2">Absent/Late/Undertime</th>
          <th rowspan="2">Basic Rate</th>
          <th colspan="2">Special Holidays</th>
          <th colspan="2">Overtime</th>
          <th colspan="2">Discrepancy</th>
          <th rowspan="2">Gross Pay</th>
          <th colspan="6">Deductions</th>
          <th rowspan="2">Net After Deductions</th>
        </tr>
        <tr>
          <th>Per Day</th>
          <th>Per Hour</th>
          <th>Hours</th>
          <th>Amount</th>
          <th>Days</th>
          <th>Amount</th>
          <th>Days</th>
          <th>Amount</th>
          <th>Days</th>
          <th>Amount</th>
          <th>SSS</th>
          <th>PhilHealth</th>
          <th>Penalty</th>
          <th>Cash Advance</th>
          <th>Water</th>
          <th>Electricity</th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
  </div>
</template>
<script>
export default {
  mounted() {
    var table = $("#report-list-table").DataTable({
      serverSide: true,
      processing: true,
      scrollY: "500px",
      scrollX: true,
      scrollCollapse: true,
      ajax: "/data/reports",
      order: [[1, "asc"]],
      columns: [
        { data: "name", sortable: false },
        { data: "name" },
        { data: "employee_position" },
        { data: "gross_monthly_salary" },
        { data: "no_of_days" },
        { data: "rate_per_day" },
        { data: "hourly_rate" },
        { data: "work_days" },
        { data: "amount" },
        { data: "alu_hours" },
        { data: "alu_amount" },
        { data: "basic_rate" },
        { data: "special_days" },
        { data: "special_amount" },
        { data: "overtime_days" },
        { data: "overtime_amount" },
        { data: "discrepancy_days" },
        { data: "discrepancy_amount" },
        { data: "gross_pay" },
        { data: "deduction_sss" },
        { data: "deduction_philhealth" },
        { data: "deduction_penalty" },
        { data: "deduction_cash_advance" },
        { data: "deduction_water" },
        { data: "deduction_electricity" },
        { data: "net_after_deductions" }
      ],
      dom: `
                        <"row"
                            <"col-6"l><"col-6 pr-0"f><"d-flex justify-content-end w-100 toolbar">
                        >
                        <rtip>
                    `
    });

    $(document).ready(function() {
      $("#example").DataTable({
        scrollX: true
      });
    });

    table
      .on("order.dt search.dt", function() {
        table
          .column(0, { search: "applied", order: "applied" })
          .nodes()
          .each(function(cell, i) {
            cell.innerHTML = i + 1;
          });
      })
      .draw();

    /* Custom filtering function which will search data in column four between two values */
    $(document).ready(function() {
      let startDate = "";
      let endDate = "";
      let startEndDates = "";

      $("#start-date").change(function() {
        startDate = this.value;
        startEndDates = startDate + "|" + endDate;
        table
          .columns(3)
          .search(startEndDates)
          .draw();
      });

      $("#end-date").change(function() {
        endDate = this.value;
        startEndDates = startDate + "|" + endDate;
        table
          .columns(3)
          .search(startEndDates)
          .draw();
      });
    });

    $("div.toolbar").html(
      `
                    
                    <label for="startdate" class="ml-2">
                        Start Date
                        <input type="date" id="start-date" name="startdate" class="form-control form-control-sm">
                    </label>
                
                    <label for="enddate" class="ml-2">
                        End Date
                        <input type="date" id="end-date" name="enddate" class="form-control form-control-sm">
                    </label>
                        
                `
    );
  }
};
</script>