<template>
    <div class="row">
        <loading
            :active.sync="isLoading"
            :can-cancel="false"
            :is-full-page="true">
        </loading>
        <div class="table-responsive">
            <table  id="payroll-list-table"  style="width:100%" class="table table-striped table-sm">
                <thead>
                    <tr>
                        <th>Employee ID</th>
                        <th>Employee Name</th>
                        <th>Category</th>
                        <th>Project</th>
                        <th>From Date</th>
                        <th>To Date</th>
                        <th>Gross Salary</th>
                        <th>Total Deductions</th>
                        <th>Total Penalty</th>
                        <th>Net Salary</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tfoot>
                    <tr>
                        <th>Employee ID</th>
                        <th>Employee Name</th>
                        <th>Category</th>
                        <th>Project</th>
                        <th>From Date</th>
                        <th>To Date</th>
                        <th>Gross Salary</th>
                        <th>Total Deductions</th>
                        <th>Total Penalty</th>
                        <th>Net Salary</th>
                        <th>Action</th>
                    </tr>
              </tfoot>
            </table>
        </div>

        <payroll-details/>
        <payroll-delete/>
        <payroll-bulk-delete/>
    </div>
</template>
<script>
    import { mapMutations, mapState, mapActions } from 'vuex'
    import Loading from 'vue-loading-overlay';
    export default {
        mounted() {

            this.getProjects();
            this.setIsAdmin(this.isAdmin);

            let vm = this;
            let startDate = 0;
            let endDate = 0;

            function formatCurrency(value){
                const filters = vm.$options.filters;
                return filters.numeral(value, '0,0.00');
            }

            function formatDate(value){
                const filters = vm.$options.filters;
                return filters.formatDate(value);
            }

            var table = $('#payroll-list-table').DataTable({
                serverSide: true,
                processing: true,
                ajax:"/data/payroll/employees",
                columns: [
                    { data: 'employee_id' },
                    {
                        data: 'last_name',
                        sortable: true,
                        render: function(data, type, full){
                            return full.full_name
                        }
                    },
                    {
                        data: 'category',
                        sortable: true
                    },
                    {
                        data: 'project_name',
                        sortable: true
                    },
                    {
                        data: 'from_date',
                        sortable: true,
                        "render": function ( data, type, full, meta ) {
                            return formatDate(full.from_date);
                        }
                    },
                    {
                        data: 'to_date',
                        sortable: true,
                        "render": function ( data, type, full, meta ) {
                             return formatDate(full.to_date);
                        }
                    },
                    {
                        data: 'gross_salary',
                        sortable: true,
                        "render": function ( data, type, full, meta ) {
                            return ''+formatCurrency(full.gross_salary);
                        }
                    },
                    {
                        data: 'total_deductions',
                        sortable: true,
                        "render": function ( data, type, full, meta ) {
                            return ''+formatCurrency(full.total_deductions);
                        }
                    },
                    {
                        data: 'total_penalty',
                        sortable: true,
                        "render": function ( data, type, full, meta ) {
                            return ''+formatCurrency(full.total_penalty);
                        }
                    },
                    {
                        data: 'net_salary',
                        sortable: true,
                        "render": function ( data, type, full, meta ) {
                            return ''+formatCurrency(full.net_salary);
                        },
                    },
                    {
                        sortable: false,
                        "render": function ( data, type, full, meta ) {

                            var html = `
                                <button type="button" class="btn btn-primary btn-sm view-payroll-details" data-data='`+JSON.stringify(full)+`'>
                                    <span class="fa fa-info-circle mr-1"></span> View </button>
                                <button type="button" class="btn btn-danger btn-sm delete-payroll" data-payroll-id='`+full.payroll_id+`'>
                                    <span class="fa fa-info-circle mr-1"></span>
                                    Delete
                                </button>
                            `

                            ;
                            return html;
                        }
                    }
                ],
                "dom":
                    `
                        <"row"
                            <"col-6"l><"col-6"f><"col-12 toolbar">
                        >
                        <rtip>
                    `,

                "drawCallback": function( settings ) {
                    $('.view-payroll-details').click( function() {
                        vm.setPayslip($(this).data('data'));
                        vm.getEmployeePenaltiesFromDateRange();
                        $("#view-payroll-details-btn").click();
                    });

                    $('.delete-payroll').click( function() {
                        vm.setDeletePayrollId($(this).data('payroll-id'));
                        $("#delete-payroll-btn").click();
                    });
                }

            });


            $("div.toolbar").html(
                `
                    <div class="row justify-content-end m-0">

                        <label for="startdate" class="mr-2">
                            Project
                            <select type="date" id="project" name="project_id" class="form-control form-control-sm">
                                <option value="0">All</option>
                            </select>
                        </label>

                        <label for="startdate" class="mr-2">
                            Category
                             <select type="date" id="employee-category" name="employee_category" class="form-control form-control-sm">
                                <option value="">All</option>
                                ` + this.getEmployeeCategories() + `
                            </select>
                        </label>

                        <label for="startdate" class="mr-2">
                            From Date
                            <input type="date" id="start-date" name="startdate" class="form-control form-control-sm">
                        </label>

                        <label for="enddate" class="mr-3">
                            To Date
                            <input type="date" id="end-date" name="enddate" class="form-control form-control-sm">
                        </label>

                        <label for="bulkdelete" class="mr-2 d-flex flex-column">
                            Mass Delete
                            <button type="button" id="bulkdelete" name="bulkdelete" class="btn btn-sm btn-danger bulkdelete"><span
                            <span class="fa fa-trash mr-1"></span>
                                Mass Delete
                            </button>
                        </label>

                    </div>
                `
            );

            $(document).ready(function(){

                $('#project').change( function() {
                    table.columns(1).search(this.value).draw();
                    vm.setProjectId(this.value);
                });

                $('#employee-category').change( function() {
                    table.columns(2).search(this.value).draw();
                    vm.setEmployeeCategory(this.value);
                });

                $('#start-date').change( function() {
                    startDate = this.value;
                    var thisDate = startDate +'|' + endDate;
                    table.columns(3).search(thisDate).draw();
                    vm.setFromDate(this.value);
                });

                $('#end-date').change( function() {
                    endDate = this.value;
                    var thisDate = startDate +'|' + endDate;
                    table.columns(3).search(thisDate).draw();
                    vm.setToDate(this.value);
                });

                $('#bulkdelete').click(function(){
                    $('#bulk-delete-payroll-btn').click();
                })

            });

        },

        computed: {
            ...mapState({
                isLoading: state => state.general.isLoading,
                projects: state => state.general.projects,
            })
        },

        watch: {
            projects: function () {
               this.setProjectOptions();
            },
        },

        methods: {
            ...mapMutations('payroll', [
                'setPayslip',
                'setDeletePayrollId',
                'setProjectId',
                'setEmployeeCategory',
                'setFromDate',
                'setToDate'
            ]),

            ...mapMutations('user', [
                'setIsAdmin'
            ]),

            ...mapActions('payroll',[
                'getEmployeePenaltiesFromDateRange',
                'bulkDeletePayroll'
            ]),

            ...mapActions('general',[
                'getProjects'
            ]),

            setProjectOptions(){
                this.projects.forEach(function(project, index) {
                   $('#project').append($('<option>', {
                        value: project.id,
                        text : project.name
                    }));
                });
            },

            getEmployeeCategories() {
                let options = "";
                $.each(this.employeeCategories, function (index, value) {
                    options +=
                    '<option value="' + value + '">' + value + "</option>";
                });
                return options;
            },

        },

        components: {
            Loading
        },

        props: [
            'employeeCategories', 'isAdmin'
        ]
    }
</script>
