

// initial state
const state = () => ({
  isAdmin: false
})

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  setIsAdmin(state, val) {
    state.isAdmin = val;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
