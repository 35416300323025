<template>
  <div>
    <button class="btn btn-sm btn-primary mb-2 d-none" id="delete-cut-off-overtime-btn" data-toggle="modal" data-target="#delete-cut-off-overtime-modal">
        <i class="fa fa-cogs"></i>
        Process Payroll
    </button>
    <!-- Modal -->
    <div class="modal fade" id="delete-cut-off-overtime-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Are you sure you want to delete this entry?</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-sm btn-default" data-dismiss="modal" aria-label="Close">
                Cancel
              </button>
              <button type="button" class="btn btn-sm btn-danger" @click="deleteCutOff()">
                Delete
              </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  methods: {
    ...mapActions('cutOffOvertime', [
      'deleteCutOffOvertimeEntry'
    ]),

    deleteCutOff(){
      this.deleteCutOffOvertimeEntry();
      location.reload();
    }
  }
}
</script>
