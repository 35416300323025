<template>
  <div
    class="modal fade addtimemodal-modal-lg"
    id="addtimemodal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add Time Record</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group row">
              <div class="col">
                <div class="row">
                  <div class="col-3">
                    <label>Employee Name:</label>
                  </div>

                  <div class="col-9">
                    <div class="d-flex flex-row justify-content-between align-items-center">
                      <div v-if="employeeId != 0" class="d-flex flex-row w-100">
                        <input
                          type="text"
                          :value="employeeNameId"
                          readonly
                          class="form-control form-control-sm"
                        />
                        <div class="remove-input-wrapper" @click="clearEmployeeInput()">
                          <i class="fa fa-times"></i>
                        </div>
                      </div>
                      <input
                        v-else
                        :class="{'required-field-input': !isEmployee}"
                        type="text"
                        :value="employeeNameId"
                        class="form-control form-control-sm"
                        placeholder="Search Employee Name / ID"
                        @input="searchEmployees($event.target.value)"
                      />
                      <i
                        :class="{'d-none': isEmployee}"
                        class="ml-1 fa fa-times required-field-mark"
                      ></i>
                    </div>
                  </div>
                </div>

                <ul class="list-group mt-1">
                  <li
                    v-for="employee in employees"
                    v-bind:key="employee.id"
                    class="list-group-item d-flex flex-row justify-content-between"
                  >
                    <div>
                      {{ employee.first_name }}
                      {{ employee.middle_name }}
                      {{ employee.last_name }}
                    </div>
                    <button
                      type="button"
                      class="btn btn-primary btn-sm"
                      @click="handleSelectEmployee(employee)"
                    >Select</button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <div class="col">
                <div class="row">
                  <div class="col-3">
                    <label>Project Name:</label>
                  </div>

                  <div class="col-9">
                    <div class="d-flex flex-row justify-content-between align-items-center">
                      <div v-if="projectId != 0" class="d-flex flex-row w-100">
                        <input
                          type="text"
                          :value="projectNameId"
                          readonly
                          class="form-control form-control-sm"
                        />
                        <div class="remove-input-wrapper" @click="clearProjectInput()">
                          <i class="fa fa-times"></i>
                        </div>
                      </div>
                      <input
                        v-else
                        :class="{'required-field-input': !isProject }"
                        type="text"
                        :value="projectNameId"
                        class="form-control form-control-sm"
                        placeholder="Search Project Name / ID"
                        @input="searchProjects($event.target.value)"
                      />
                      <i
                        :class="{'d-none': isProject}"
                        class="ml-1 fa fa-times required-field-mark"
                      ></i>
                    </div>
                  </div>
                </div>

                <ul class="list-group mt-1">
                  <li
                    v-for="project in projects"
                    v-bind:key="project.id"
                    class="list-group-item d-flex flex-row justify-content-between"
                  >
                    <div>{{ project.name }}</div>
                    <button
                      type="button"
                      class="btn btn-primary btn-sm"
                      @click="handleSelectProject(project)"
                    >Select</button>
                  </li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <div class="row">
                  <label for class="col-3 col-form-label">Date:</label>
                  <div class="col-9 d-flex flex-row justify-content-between align-items-center">
                    <input
                      :class="{'required-field-input':date == ''}"
                      type="date"
                      :value="date"
                      @change="handlSelectDate($event.target.value)"
                      class="form-control form-control-sm"
                    />
                    <i :class="{'d-none': date != ''}" class="ml-1 fa fa-times required-field-mark"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col">
                <label>
                  <b>Time Logs:</b>
                </label>
                <div class="row">
                  <label for class="col-3 col-form-label">Leave:</label>
                  <div class="col-9 d-flex flex-row">
                    <div class="form-check form-check-inline mr-1">
                      <input type="radio" class="form-check-input mr-1" :checked="isLeave ? 'checked' : ''" @click="handleIsLeave(true)" value="true" id="leaveInlineRadioOptionYes" name="leaveInlineRadioOptionYes">
                      <label class="form-check-label mr-3" for="leaveInlineRadioOptionYes">Yes</label>
                    </div>
                    <div class="form-check form-check-inline mr-1">
                      <input type="radio" class="form-check-input mr-1" :checked="isLeave ? '' : 'checked'" @click="handleIsLeave(false)" value="false" id="leaveInlineRadioOptionNo" name="leaveInlineRadioOptionNo">
                      <label class="form-check-label" for="leaveInlineRadioOptionNo">No</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
             <div v-if="isLeave" class="form-group row">
              <div class="col">
                <div class="row">
                  <label for class="col-3 col-form-label">Paid Leave:</label>
                  <div class="col-9 d-flex flex-row">
                    <div class="form-check form-check-inline mr-1">
                      <input type="radio" class="form-check-input mr-1" :checked="isWithPay ? 'checked' : ''" @click="setIsWithPay(true)" value="true" id="leaveIsWithPayYes" name="leaveIsWithPayOptionYes">
                      <label class="form-check-label mr-3" for="leaveIsWithPayOptionYes">Yes</label>
                    </div>
                    <div class="form-check form-check-inline mr-1">
                      <input type="radio" class="form-check-input mr-1" :checked="isWithPay ? '' : 'checked'" @click="setIsWithPay(false)" value="false" id="leaveIsWithPayNo" name="leaveIsWithPayOptionNo">
                      <label class="form-check-label mr-3" for="leaveIsWithPayOptionNo">No</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col">
                <div class="row">
                  <label class="col-3 col-form-label font-weight-bold">Time Logs:</label>
                  <div class="col-9">
                    <ul class="list-group list-group-flush">
                      <li v-for="(tLog, index) in timeLogs" :key="index" class="list-group-item pl-0 pr-0 mr-0 ml-0"> 
                         <div class="row">
                           <div class="col-sm-3 pl-0 pr-0 mr-0 ml-0">
                              <span class="font-weight-bold">Time: </span> {{ tLog.time }} 
                           </div>
                           <div class="col-sm-7">
                             <span class="font-weight-bold">Transacation: </span> {{ tLog.transaction }}
                            </div>
                          <div class="col-sm-1">
                            <div class="remove-input-wrapper"><i class="fa fa-times" @click="handleRemoveTimeLog(index)"></i></div> 
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div> 
                  <div class="col-3"></div>
                  <div class="col-9 d-flex flex-row">
                    <div class="d-flex flex-row w-100">
                      <div class="col-sm-3 pl-0 pr-0 mr-0 ml-0">
                        <input
                          type="time"
                          :value="timeLog"
                          class="form-control"
                          @input="setTimeLog($event.target.value)" 
                        />
                      </div>
                      <div class="col-sm-7">
                        <textarea
                          rows="3"
                          type="text"
                          :value="transaction"
                          class="form-control"
                          placeholder="Transaction"
                          @input="setTimeLogTransaction($event.target.value)"
                        />
                      </div>
                      <div class="col-sm-1">
                        <div class="add-time-log-wrapper" @click="handleAddTimeLogTime()">
                          <i class="fa fa-plus"></i>
                        </div> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
            <div class="form-group row">
              <label class="col-3 col-form-label mb-2 font-weight-bold">Time Adjustments: </label>
               <div class="col-9 d-flex flex-row w-100"> 
                <div v-if="calculatedTravelTime > 0 && timeAdjustment == null">
                  <label>Calculated Time difference from previous Time Log:</label>
                  <div class="d-flex flex-row mb-4 mt-1">
                    <input
                      type="text"
                      :value="calculatedTravelTime + ' minutes'"
                      readonly
                      class="form-control form-control-sm"
                      style="width: 100px"
                    />
                    <button
                      type="button"
                      class="btn btn-sm btn-primary ml-2"
                      @click="handleAddAsTravelTime"
                    >Add as travel time</button>
                  </div>
                </div>
                <div v-if="timeAdjustment != null" class="d-flex flex-row w-100 mx-2 mt-2">
                  <b>{{timeAdjustment.minutes}} minutes</b>-
                  <p>{{timeAdjustment.remark}}</p>
                  <div
                    class="remove-input-wrapper flex-shrink-1 d-flex justify-content-center align-items-center ml-4"
                    @click="setTimeAdjustment(null)"
                  >
                    <i class="fas fa-times"></i>
                  </div>
                </div>
                <div v-else class="d-flex flex-row w-100">
                  <div class="col-sm-3 pl-0 pr-0 mr-0 ml-0">
                    <input
                      type="number"
                      :value="timeAdjustmentMinutes"
                      class="form-control"
                      @input="setTimeAdjustmentMinutes($event.target.value)"
                      placeholder="Minutes" 
                    />
                  </div>
                  <div class="col-sm-7">
                    <textarea
                      type="text"
                      :value="timeAdjustmentRemark"
                      @input="setTimeAdjustmentRemark($event.target.value)"
                      placeholder="Remark"
                      class="form-control"
                    />
                  </div>
                  <div class="col-sm-1">
                    <div
                      class="add-time-log-wrapper"
                      @click="setTimeAdjustment({addOrSub: addOrSub, minutes: timeAdjustmentMinutes, remark:timeAdjustmentRemark})"
                    >
                      <i class="fa fa-plus"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">
            <span class="fa fa-times mr-2"></span>Cancel
          </button>
          <button type="button" class="btn btn-sm btn-primary" @click="addTimeLog()">
            <span class="fa fa-save mr-2"></span>Add
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";

import "vue-loading-overlay/dist/vue-loading.css";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState({
      employeeNameId: (state) => state.addDtr.employeeNameId,
      employees: (state) => state.addDtr.employees,
      projectNameId: (state) => state.addDtr.projectNameId,
      projects: (state) => state.addDtr.projects,
      isProject: (state) => state.addDtr.isProject,
      date: (state) => state.addDtr.date,
      isLoading: (state) => state.addDtr.isLoading,
      fullPage: (state) => state.addDtr.fullPage,
      isEmployee: (state) => state.addDtr.isEmployee,
      timeLogs: (state) => state.addDtr.timeLogs,
      timeLog: (state) => state.addDtr.timeLog,
      transaction: (state) => state.transaction,
      employeeId: (state) => state.addDtr.employeeId,
      projectId: (state) => state.addDtr.projectId,
      calculatedTravelTime: (state) => state.addDtr.calculatedTravelTime,
      timeAdjustment: (state) => state.addDtr.timeAdjustment,
      timeAdjustmentAddOrSub: (state) => state.addDtr.timeAdjustmentAddOrSub,
      timeAdjustmentMinutes: (state) => state.addDtr.timeAdjustmentMinutes,
      timeAdjustmentRemark: (state) => state.addDtr.timeAdjustmentRemark,
      isWithPay: (state) => state.addDtr.isWithPay,
      isLeave: (state) => state.addDtr.isLeave,
    }),
  },
  mounted() {
    $("#addtimemodal").on("hide.bs.modal", () => {
      //clear inputs
      this.clearInputs();
    });
  },

  methods: {
    ...mapActions("addDtr", [
      "searchEmployees",
      "searchProjects",
      "addTimeLog",
      "getTravelTime",
    ]),
    ...mapMutations("addDtr", [
      "setTransaction",
      "setDate",
      "clearInputs",
      "setProject",
      "setEmployee",
      "setTimeLog",
      "addTimeLogTime",
      "clearEmployeeInput",
      "clearProjectInput",
      "setTimeAdjustmentAddOrSub",
      "setTimeAdjustmentMinutes",
      "setTimeAdjustmentRemark",
      "setTimeAdjustment",
      "setCalculatedTravelTime",
      "removeTimeLog",
      "setIsLeave",
      "setIsWithPay",
      "setTimeLogTransaction"
    ]),
    handleSelectEmployee(employee) {
      this.setEmployee(employee);
      this.getTravelTime();
    },
    handleSelectProject(project) {
      this.setProject(project);
      this.getTravelTime();
    },
    handlSelectDate(date) {
      this.setDate(date);
      this.getTravelTime();
    },
    handleAddTimeLogTime() {
      this.addTimeLogTime();
      this.getTravelTime();
    },
    handleAddAsTravelTime() {
      this.setTimeAdjustment({
        minutes: this.calculatedTravelTime,
        remark: "Travel Time",
      });
    },
    handleRemoveTimeLog(index) {
      this.removeTimeLog(index);
      this.getTravelTime();
    },
    handleIsLeave(value){
      this.setIsLeave(value);
    },
    handleWithPay(value){
      this.setIsWithPay(value);
    }
},

  props: ["csrf"],

  components: {
    Loading,
  },
};
</script>
<style scoped>
.add-time-log-wrapper {
  min-height: 24px;
  min-width: 24px;
  max-height: 24px;
  max-width: 24px;
  border-radius: 50%;
  background-color: #005086;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  margin-left: 8px;
  cursor: pointer;
}
.remove-input-wrapper {
  min-height: 24px;
  min-width: 24px;
  max-height: 24px;
  max-width: 24px;
  border-radius: 50%;
  background-color: red;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  margin-left: 8px;
  cursor: pointer;
}
</style>
