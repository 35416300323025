<template>
    <div>
        <loading
            :active.sync="isLoading"
            :is-full-page="fullPage">
        </loading>

        <button type="button" id="add-permission-user-btn" class="d-none btn btn-success" data-toggle="modal" data-target="#add-permission-user">Add Permission</button>
        <div class="modal fade" id="add-permission-user" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Add Permissions</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ul class="list-group">
                            <li class="list-group-item d-flex flex-row align-items-center" v-for="permission in permissions" :key="permission.id">
                                <input type="checkbox" class="permission-checkbox mr-2" :value="permission.name" @click="addPermission()"/> {{ permission.name }}
                            </li>
                        </ul>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal"><span class="fa fa-times mr-1"></span>Close</button>
                        <button type="button" class="btn btn-primary btn-sm" @click="savePermissions()"><span class="fa fa-plus mr-1" ></span>Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {


        mounted(){
            this.getPermissions()
        },

        data(){
            return {
                permissions:[],
                addedPermissions: [],
                userPermissions: [],
                userId: 0,
                isLoading: false,
                fullPage: true,
            }
        },

        methods:{
            getPermissions(){
                axios.get('/data/get-permissions')
                .then(response => {
                    this.permissions = response.data;
                });
            },

            addPermission(){
                let addedPermissions = []
                $('.permission-checkbox').each(function(index, checkbox){
                    if( $(checkbox).prop('checked') ){
                        addedPermissions.push($(checkbox).val())
                    }
                })

                this.addedPermissions = addedPermissions;

                console.log(this.addedPermissions);
            },

            getUserPermission(){
                axios.get('/data/user-permissions/'+this.userId)
                .then(response => {
                    this.userPermissions = response.data;
                    this.checkUserPermissions();
                });
            },

            savePermissions(){
                this.isLoading = true;
                axios.post('/data/save-permissions',{
                    user_id: this.userId,
                    permissions: this.addedPermissions
                })
                .then(response => {
                    this.isLoading = false;
                    this.userPermissions = response.data;
                    this.checkUserPermissions();
                });
            },

            checkUserPermissions(){
                $('input:checkbox').prop('checked', false);
                this.userPermissions.forEach(function(permission, index){
                    console.log(permission.name);
                    $('input:checkbox[value='+permission.name+']').prop('checked', true);
                })
            },

            setUserId(user_id){
                this.userId = user_id;
                this.getUserPermission();
            }
        },

        components: {
            Loading
        },

    }
</script>
