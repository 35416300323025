<template>
  <div>
    <h6><b>Normal Deductions</b></h6>
    <ul class="list-group" v-if="employeeDeductions.length > 0">
      <li
        class="list-group-item d-flex align-items-center"
        v-for="deduction in employeeDeductions"
        v-bind:key="deduction.id"
      >
        <input
          type="checkbox"
          class="mr-2 deductions"
          :data-id="deduction.id"
          :data-amount="deduction.amount"
          :checked="true"
          @change="deductionCheckChanged(deduction.deduction.id, $event.target, deduction.amount)"
        />
        <span>
            {{deduction.deduction.deduction_name}}
        </span>
        <span>
            <b>(</b>
        </span>
        <span>
            <input type="text" class="form-control form-control-sm" :value="deduction.amount" @change="updateDeduction({id: deduction.id, amount: $event.target.value})"/>
        </span>
        <span>
            )
        </span>

      </li>
    </ul>

    <hr/>

    <h6><b>Special Deductions</b></h6>
    <table class="table table-sm table-bordered">
      <tr>
        <th>Name</th>
        <th>Amount to Deduct</th>
      </tr>
      <tr v-for="specialDeduction in employeeSpecialDeductions" v-bind:key="specialDeduction.id">
        <td>{{ specialDeduction.type }}</td>
        <td class="d-flex align-items-center">

          <input
            class="form-control form-control-sm special-deductions"
            :data-id="specialDeduction.id"
            type="text"
            @change="updateSpecialDeduction({id: specialDeduction.id, amount: $event.target.value, payroll_id: selectedEmployee.id})"
            v-model="specialDeduction.amount_per_deduction"/>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'
export default {
  data() {
      return {
          deductions: []
      }
  },

  computed: {
    ...mapState({
        selectedEmployee: state => state.payroll.selectedEmployee,
        employeeDeductions: state => state.payroll.employeeDeductions,
        employeeSpecialDeductions: state => state.payroll.employeeSpecialDeductions,
        adjustments: state => state.payroll.adjustments
    }),

    ...mapGetters('payroll', {
      employeeAdjustmentDeductions: 'employeeAdjustmentDeductions',
      employeeAdjustmentSpecialDeductions: 'employeeAdjustmentSpecialDeductions',
    }),

  },

    watch: {
        employeeDeductions: function(){
            this.initDeductionAdjustments();
        },
        // employeeSpecialDeductions: function(){
        //     this.initSpecialDeductionAdjustments();
        // }
    },

  methods: {
    ...mapMutations('payroll', [
      'setAdjustments',
      'setTotalDeductions',
      'setGrossSalaries',
      'setEmployeeNetSalaries',
      'toggleIsLoading'
    ]),

    ...mapActions('payroll', [
        'updatePayroll',
        'updateSpecialDeductionPayment',
        'getEmployeeSpecialDeductions'
    ]),

    initDeductionAdjustments(){
        let adjustment = this.adjustments.find(x=> x.id === this.selectedEmployee.id)

        if(!adjustment){
            adjustment = {
                id: this.selectedEmployee.id,
                deductions: [],
                specialDeductions: []
            }
        }

        if(adjustment.deductions.length !== this.employeeDeductions.length){
            this.employeeDeductions.forEach(x => {
                adjustment.deductions.push({id: x.id, amount: x.amount})
            })
        }

        this.setAdjustments(adjustment);
        this.setTotalDeductions();
        this.setGrossSalaries();
        this.setEmployeeNetSalaries();
    },


    initSpecialDeductionAdjustments(){
        let adjustment = this.adjustments.find(x=> x.id === this.selectedEmployee.id)

        if(!adjustment){
            adjustment = {
                id: this.selectedEmployee.id,
                deductions: [],
                specialDeductions: []
            }
        }

        if(adjustment.specialDeductions.length !== this.employeeSpecialDeductions.length){
            this.employeeSpecialDeductions.forEach(x => {
                adjustment.specialDeductions.push({id: x.id, amount: x.amount_per_deduction})
            })
        }

        this.setAdjustments(adjustment);
        this.setTotalDeductions();
        this.setGrossSalaries();
        this.setEmployeeNetSalaries();
    },

    updateDeduction(value){
        if(this.employeeDeductions.length > 0){
            let adjustment = this.adjustments.find(x => x.id === this.selectedEmployee.id);

            if(adjustment){
                let deduction = adjustment.deductions.find(x => x.id === value.id)
                let index = adjustment.deductions.findIndex(x => x.id === value.id)
                deduction.amount = value.amount;
                adjustment.deductions[index] = deduction;
            }

            this.setAdjustments(adjustment)
            this.setTotalDeductions();
            this.setGrossSalaries();
            this.setEmployeeNetSalaries();
        }
    },

      updateSpecialDeduction(value){
        // if(this.employeeSpecialDeductions.length > 0){
        //    let adjustment = this.adjustments.find(x => x.id === this.selectedEmployee.id);

        //     if(adjustment){
        //         let specialDeduction = adjustment.specialDeductions.find(x => x.id === value.id)
        //         let index = adjustment.specialDeductions.findIndex(x => x.id === value.id)
        //         specialDeduction.amount = value.amount;
        //         adjustment.specialDeductions[index] = specialDeduction;
        //     }

        //     this.setAdjustments(adjustment);
        //     this.setTotalDeductions();
        //     this.setGrossSalaries();
        //     this.setEmployeeNetSalaries();
        // }
        this.updateSpecialDeductionPayment(value);
        // this.getEmployeeSpecialDeductions(this.selectedEmployee.employee_id);
        // this.toggleIsLoading();
    },

    setAdjustmentsValues(){
        this.entry = {
            id: this.selectedEmployee.id,
            deductions: this.deductionChanges,
            specialDeductions: this.specialDeductionChanges
        }
    },

    deductionCheckChanged(id, el, amount) {
        if($(el).is(':checked')){
            let payload = {
                id: this.selectedEmployee.id,
                field: 'total_deductions_add',
                amount: amount,
                deduction_id: id
            }
            this.updatePayroll(payload)
        }else{
            let payload = {
                id: this.selectedEmployee.id,
                field: 'total_deductions_sub',
                amount: amount,
                deduction_id: id
            }
            this.updatePayroll(payload)
        }
    },

    checkDeductions(id){
        const deductions = JSON.parse(this.selectedEmployee.deductions)
        const index = deductions.findIndex(x => x === id)
        if (index > -1){
            return true
        } else {
            return false
        }
    },

    deductionAmount(id){
        let adjustment = this.adjustments.find(x => x.id === this.selectedEmployee.id);

        if(adjustment && adjustment.deductions.length > 0){
          let deduction = adjustment.deductions.find((x) => x.id === id);
          return deduction.amount;
        }
    },

    specialDeductionAmount(id){
        let adjustment = this.adjustments.find(x => x.id === this.selectedEmployee.id);

        if(adjustment && adjustment.specialDeductions.length > 0){
          let deduction = adjustment.specialDeductions.find((x) => x.id === id);
          return deduction.amount;
        }
    }
  },
}
</script>
