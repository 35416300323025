<template>
    <div>
       <loading
            :active.sync="isLoading"
            :is-full-page="fullPage">
        </loading>

      <button type="button"
              class="btn btn-sm btn-primary my-1 mx-2"
              style="width: 100px"
              data-toggle="modal"
              @click="getEmploymentHistories()"
              data-target="#view-employment-history-modal">
      <span class="fa fa-eye mr-1"></span>
        Employment History
      </button>
      <div class="modal fade" id="view-employment-history-modal" tabindex="-1" role="dialog" aria-labelledby="editEmployeeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="container-fluid modal-title" id="editEmployeeModalLabel">Employment History</h5>

            <button class="btn btn-sm btn-primary" type="button" @click="printEmploymentHistories()" aria-label="Close">
              <i class="fa fa-print"></i>
              Print
            </button>

            <button type="button" class="close" @click="closeModal()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
              <table class="table table-sm table-bordered mb-5">
                  <tr>
                      <th>Effective Date</th>
                      <!-- <th>Is Done</th> -->
                      <th>Status</th>
                      <th>Position</th>
                      <th>Project</th>
                      <th>Company</th>
                      <th>Salary</th>
                      <th>Allowance Type</th>
                      <th>Allowance Amount</th>
                      <th>Action</th>
                  </tr>
                  <tr v-for="history in histories" :key="history.id">
                      <td>{{ history.effective_date | formatDate }}</td>
                      <!-- <td>{{ history.is_done }}</td> -->
                      <td>{{ history.status }}</td>
                      <td>{{ history.position_name }}</td>
                      <th>{{ history.project_name }}</th>
                      <th>{{ history.company_name }}</th>
                      <td v-if="isAdmin"> {{ history.salary | numeral('0,0.00')}}</td>
                      <td v-if="!isAdmin"> ****</td>
                      <td>{{ history.allowance_type }}</td>
                      <td> {{ history.allowance_amount | numeral('0,0.00')}}</td>
                      <td>
                        <button
                            class="btn btn-sm btn-warning"
                            type="button"
                            @click="setEdit(history)"
                        >
                            Edit
                        </button>
                        <button
                            class="btn btn-sm btn-danger"
                            type="button"
                            data-toggle="modal"
                            @click="confirmDelete(history.id)"
                        >
                            Delete
                        </button>
                      </td>
                  </tr>
              </table>
              <deleteHistory ref="deleteHistory"  @refresh-histories="getEmploymentHistories"></deleteHistory>


              <h6>Add Employment History</h6>
               <hr/>
              <form id="employees-histories-store" method="post" action="/employees.histories/store">
                  <div class="form-group">
                    <label>Effective Date:</label>
                    <input type="date" class="form-control form-control-sm" :class="{error: showEffectiveDateError }" v-model="effectiveDate" placeholder="Date"/>
                    <small class="error" v-if="showEffectiveDateError">This field is required.</small>
                  </div>

                  <div class="form-group">
                    <label>Type:</label>
                    <select type="text"  class="form-control form-control-sm" v-model="type" :class="{error: showTypeError}">
                      <option value="">Select Type</option>
                      <option>Probation</option>
                      <option>Regular</option>
                      <option>Project Base</option>
                    </select>
                    <small class="error" v-if="showTypeError">This field is required.</small>
                  </div>

                  <employees-fields-position ref="employeePosition" @set-position="setPosition" :error="showPositionError"/>

                  <small class="error" v-if="showPositionError">This field is required.</small>

                  <employees-fields-project ref="employeeProject" @set-project="setProject" :error="showProjectError"/>

                  <div class="form-group">
                    <label>Company:</label>
                    <select type="text"  class="form-control form-control-sm" v-model="companyId" :class="{error: showTypeError}">
                      <option value="0">Select Company</option>
                      <option v-for="company in companies" :key="company.id" :value="company.id">{{ company.name }}</option>
                    </select>
                    <small class="error" v-if="showTypeError">This field is required.</small>
                  </div>

                  <div class="form-group">
                    <label>Salary:</label>
                    <input type="number"  class="form-control form-control-sm" :class="{error: showSalaryError}" v-model="salary" placeholder="Salary" step="0.01" min="0"/>
                    <small class="error" v-if="showSalaryError">This field is required.</small>
                  </div>

                  <div class="form-group">
                    <label>Allowance Type:</label>
                    <select name="allowance_type"  class="form-control form-control-sm" :class="{error: showAllowanceTypeError}" v-model="allowanceType">
                      <option value="none">None</option>
                      <option value="fixed">Fixed</option>
                      <option value="attendance-base">Attendance Base</option>
                    </select>
                    <small class="error" v-if="showAllowanceTypeError">This field is required.</small>
                  </div>

                  <div class="form-group">
                    <label>Allowance Amount:</label>
                    <input type="number"  class="form-control form-control-sm" :class="{error: showAllowanceAmount}" v-model="allowanceAmount" placeholder="Salary" step="0.01" min="0"/>
                    <small class="error" v-if="showAllowanceAmount">This field is required.</small>
                  </div>

              </form>

              <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-primary" @click="store()"><i class="fa fa-save mr-2"></i>Submit</button>
              </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import Vue from 'vue'
  import {mapState} from 'vuex';
  import moment from 'moment'

  Vue.component('deleteHistory', require('./deleteModal.vue').default);

  export default {
    mounted(){
      this.getCompanies();
    },

    data(){
      return {
        history_id: 0,
        selectedHistoryId: 0,
        effectiveDate: '',
        type: '',
        positionId: 0,
        projectId: 0,
        companyId: 0,
        positionName: '',
        projectName: '',
        companyName: '',
        companies: [],
        salary: 0,
        allowanceType: 'fixed',
        allowanceAmount: 0,
        passed: false,
        showEffectiveDateError: false,
        showTypeError: false,
        showPositionError: false,
        showProjectError: false,
        showSalaryError: false,
        showAllowanceAmount:0,
        showAllowanceTypeError: false,
        isLoading: false,
        fullPage: true,
        histories: []
      }
    },

    computed:  {
        ...mapState({
            isAdmin: (state) => state.user.isAdmin,
        }),
    },

    methods: {
        confirmDelete(historyId){
            console.log(historyId)
            this.$refs.deleteHistory.history_id = historyId
            $("#delete-history-modal").modal('show');
        },

        setEdit(history){
            console.log(history)
            this.history_id = history.id
            this.effectiveDate = moment(history.effective_date).format('YYYY-MM-DD')
            this.positionId = history.position_id
            this.$refs.employeePosition.positionName = history.position.name
            this.projectId = history.project_id
            this.$refs.employeeProject.projectName = history.project.name
            this.companyId = history.company_id
            this.type = history.status
            this.salary = history.salary
            this.allowanceType = history.allowance_type
            this.allowanceAmount = history.allowance_amount
            this.employeeId = history.employee_id
        },

        closeModal(){
            $("#view-employment-history-modal").modal('hide');
        },

      store(){
        this.validateFields();
        if(this.passed){
          this.isLoading = true;
          axios.post('/employees/'+this.employeeId +'/histories',{
            history_id: this.history_id,
            effective_date: this.effectiveDate,
            position_id: this.positionId,
            project_id: this.projectId,
            company_id: this.companyId,
            type: this.type,
            salary: this.salary,
            allowance_type: this.allowanceType,
            allowance_amount: this.allowanceAmount,
            employee_id: this.employeeId
          }).then(response => {
            this.getEmploymentHistories();
            this.isLoading = false;
          });
        }
      },

      validateFields(){
        let check = false;

        this.showEffectiveDateError = false;
        this.showTypeError = false;
        this.showPositionError = false;
        this.showSalaryError = false;

        this.effectiveDate == '' ? this.showEffectiveDateError = true :
        this.type == '' ? this.showTypeError = true :
        this.positionId == 0 ? this.showPositionError = true :
        this.salary == 0 ? this.showSalaryError = true : check = true;

        this.passed = check;

      },

      getEmploymentHistories(){
        axios.get('/employees/'+this.employeeId +'/histories?employee_id='+this.employeeId,{
          employee_id: this.employeeId
        }).then(response => {
          this.histories = response.data;
        });
      },

      setPosition(id){
        this.positionId = id;
      },

      setProject(project){
        this.projectId = project.id;
      },

      getCompanies(){
        axios.get('/companies/show').then(response => {
          console.log('companies');
          console.log(response);
          this.companies = response.data.data;
        });
      },

      printEmploymentHistories(){
        window.open('/employees/'+this.employeeId+'/histories/print');
      }
    },

    props:['employeeId'],

    components: {
      Loading
    }
  }
</script>
<style scoped>
  small.error {
    color: red;
  }

  .form-control.error {
    border-color: red;
  }
</style>
