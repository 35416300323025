<template>
</template> 
<script>
export default {
    mounted(){  
            
             let vm = this;
             
             function formatCurrency(value){
                const filters = vm.$options.filters;
                return filters.numeral(value, '0,0.00');
            }
             var table = $('#deduction-table').DataTable({
                serverSide: true,
                processing: true,
                ajax:"/data/deduction",
                columns: [  
                    { data: 'deduction_name' },
                    { data: 'amount' ,
                    sortable: false,
                    "render": function (data, type, full, meta){
                            return ''+formatCurrency(data);
                    } 
                    },
                    { sortable: false,
                        "render": function (  data, type, full, meta ) { 
                            var actions= ` <button type="button" class="btn btn-sm btn-primary edit-deduction"><span class="fa fa-edit mr-1"></span>Edit</button>
                              <button type="button" id="`+full.id+`" class="btn btn-sm btn-danger confirm-delete"><span class="fa fa-trash mr-1"></span>Delete</button>
                            <!-- Modal -->
                            <div class="modal fade" id="confirm-Modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">Delete Deduction</h5>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>   
                                               <div id="body-modal-msg" class="modal-body">
                                                    
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" name="del-Deduction" id="del-Deduction" class="delete btn btn-danger">Remove</button>
                                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                                </div> 
                                        </div>
                                    </div>
                                </div>
                                `                      
                              return actions; 

                            }                           
                    }
                ]
            });   
         
        var deduction_id=''; 
            //edit deduction
           $(document).ready(function() { 
                var table = $('#deduction-table').DataTable(); 
                $('#deduction-table tbody').on('click', 'tr', function () {
                //get data from row clicked
                var data = table.row( this ).data();
                   $(document).on('click','.edit-deduction',function(){  
                    $('#deduction-name').val(data.deduction_name);
                    $('#amount').val(data.amount);
                    $('#edit-deduction-form').attr('action','/deduction/' + data.id + '/update');
                    $('#edit-modal').modal('show');
                   });

                //delete deduction
                $(document).on('click','.confirm-delete',function(){
                    deduction_id = $(this).attr('id');
                    $("#body-modal-msg").empty();
                    $("#body-modal-msg").append('<p>Are you sure you want to delete ' + data.deduction_name + " ?");
                    $('#confirm-Modal').modal('show');                
                    }),

                    $(document).on('click', '.delete', function(){   
                        axios.post('/deduction/destroy',{
                            id: deduction_id
                        }).then(response => { 
                            table.draw();  
                            $("#confirm-Modal").modal("hide");   
                        }); 
                    });    
                    } );

                } ); 
        }   
}
</script>
