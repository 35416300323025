import devlarn from '../../api/devlarn'
import Vue from 'vue'
import moment from 'moment'

// initial state
const state = () => ({
  selectedEmployees: [],
  selectedEmployee: null,
  employeeSummaries: [],
  employeeDeductions: [],
  employeeSpecialDeductions: [],
  employeeId: 0,
  employeeAllowance: 0,
  fromDate: null,
  toDate: null,
  totalDeductions: [],
  totalPenalties: [],
  adjustments: [],
  payrollSummary: [],
  allowances: [],
  netSalaries: [],
  grossSalaries: [],
  cutOffOvertimeHours: [],
  penalties: [],
  employeePenalties: [],
  overtimes: [],
  isLoading: false,
  payslip: {},
  deletePayrollId: 0,
  employeeSpecialHolidayAmounts: [],
  otDiscrepancyHours: [],
  otDiscrepancyAmounts: [],
  discrepancies: [],
  dtrSummaries: [],
  projectId: 0,
  employeeCategory: 'All',
  processPayrollCutOffOvertimeDate: moment().format('YYYY-MM-DD'),
  payrollDeductions: [],
  payrollSpecialDeductions: [],
  payrollProjectId: 0,
  payrollEntries: [],
  payrollId: null
})

// getters
const getters = {
  employeeAdjustmentDeductions: state => {
    let adjustment = state.adjustments.find(x => x.id === state.selectedEmployee.employee_id)
    if(adjustment !== undefined){
      return adjustment.deductions
    }else{
      return undefined;
    }
  },

  employeeAdjustmentSpecialDeductions: state => {
    let adjustment = state.adjustments.find(x => x.id === state.selectedEmployee.employee_id)
    if(adjustment !== undefined){
      return adjustment.specialDeductions
    }else{
      return undefined;
    }
  },

  selectedEmployeeTotalDeductions: state => {
    let deduction = state.totalDeductions.find(x => x.id === state.selectedEmployee.employee_id)
    if(deduction !== undefined){
      return deduction.total
    }else{
      return 0;
    }
  },

  selectedEmployeeTotalPenalties: state => {
    let total = 0;
    state.employeeSummaries.forEach((x) => {
      total += parseFloat(x.total_penalty);
    });

    return total;
  },

  netSalary: state => {
    let netSalary = state.netSalaries.find((x) => x.id === state.selectedEmployee.employee_id)
    return netSalary ? netSalary.netSalary : 0;
  },

  grossSalary: state => {
    let grossSalary = state.grossSalaries.find((x) => x.id === state.selectedEmployee.employee_id)
    return grossSalary ? grossSalary.grossSalary : 0;
  },
  otDiscrepancyHour: state => {
    let otDiscrepancyHour = state.otDiscrepancyHours.find((x) => x.id === state.selectedEmployee.employee_id)
    return otDiscrepancyHour ? parseFloat(otDiscrepancyHour.ot_discrepancy_hour) : 0;
  },
  otDiscrepancyAmount: state => {
    let otDiscrepancyAmount = state.otDiscrepancyAmounts.find((x) => x.id === state.selectedEmployee.employee_id)
    return otDiscrepancyAmount ? parseFloat(otDiscrepancyAmount.ot_discrepancy_amount) : 0;
  },
  discrepancyAmount: state => {
    let discrepancy = state.discrepancies.find((x) => x.id === state.selectedEmployee.employee_id)
    return discrepancy ? parseFloat(discrepancy.discrepancy_amount) : 0;
  },
  cutOffOvertimeHour: state => {
    let hour = state.cutOffOvertimeHours.find((x) => x.employee_id === state.selectedEmployee.employee_id)
    return hour ? parseFloat(hour.hours) : 0;
  },
  holidayAmount: state => {
    let total = 0
    console.log(state.employeeSummaries)
    state.employeeSummaries.forEach(x => {
        console.log(x.holiday_additional)
        total = total + x.holiday_additional
    })
    console.log(total, 'total')
    return total
    // let employeeSpecialHolidayAmount = state.employeeSpecialHolidayAmounts.find((x) => x.id === state.selectedEmployee.employee_id)
    // return employeeSpecialHolidayAmount ? parseFloat(employeeSpecialHolidayAmount.amount) : 0;
  }
}

// actions
const actions = {
  getEmployeeDtrSummary ({state, commit}, id) {
    let url = "/api/v1/employees/" + id + "/dtr-summary?from_date=" + state.fromDate + "&to_date=" +state.toDate + "&project_id=" + state.payrollProjectId ;
    devlarn.getEmployeeDtrSummary(summaries => {
      commit('setEmployeeSummaries', summaries)
      commit('setEmployeeDtrSummary', summaries);
      commit('setTotalDeductions');
      commit('setOvertimes');
      commit('setPenalties');
      commit('setGrossSalaries');
      commit('setEmployeeNetSalaries');
    }, url )
  },

  getEmployeeDeductions({state, commit}, id){
    devlarn.getEmployeeDeductions(deductions => {
        commit('setEmployeeDeductions', deductions)
        commit('setTotalDeductions');
        commit('setOvertimes');
        commit('setPenalties');
        commit('setGrossSalaries');
        commit('setEmployeeNetSalaries');

    }, id )
  },

  getEmployeeSpecialDeductions({state, commit}, id){
    devlarn.getEmployeeSpecialDeductions(deductions => {
        commit('setEmployeeSpecialDeductions', deductions)
        commit('setTotalDeductions');
        commit('setOvertimes');
        commit('setPenalties');
        commit('setGrossSalaries');
        commit('setEmployeeNetSalaries');
        commit('toggleIsLoading');
    }, id, state.payrollProjectId )
  },

  getEmployeeAllowance({state, commit}){
    devlarn.getEmployeeAllowance(amount => {
      let allowance = {
        id: state.selectedEmployee.employee_id,
        amount: amount
      }

      commit('setEmployeeAllowance', amount);
      commit('setAllowances', allowance);

    }, state.selectedEmployee.employee_id, state.fromDate, state.toDate )
  },

  getEmployeeSpecialHolidayAmount({state, commit}){
    devlarn.getEmployeeSpecialHolidayAmount( response => {
        const data = {
            id: state.selectedEmployee.employee_id,
            amount: response.data
        }
        commit('setEmployeeSpecialHolidayAmounts', data)
        commit('setEmployeeNetSalaries')
    }, state.selectedEmployee.employee_id, state.fromDate, state.toDate )
  },

  processPayroll({state, commit}){
    let payload = [];
    state.selectedEmployees.forEach((x) => {
      let salary = x.gross_monthly_salary;
      let salaryPeriod = x.salary_period;
      let hourlyRate = x.hourly_rate;
      let grossSalary = state.grossSalaries.find((y) => y.id === x.id);
      let totalDeductions = state.totalDeductions.find((y) => y.id === x.id);
      let netSalary = state.netSalaries.find((y) => y.id === x.id);
      let adjusment = state.adjustments.find((y) => y.id === x.id);
      let penalty = state.penalties.find((y) => y.id === x.id);
      let overtime = state.overtimes.find((y) => y.id === x.id);
      let discrepancy = state.discrepancies.find((y)=> y.id === x.id);
      let dtrSummary = state.dtrSummaries.find((y) => y.id === x.id);

      let entry = {
        from_date: state.fromDate,
        to_date: state.toDate,
        employee_id: x.id,
        project_id: state.payrollProjectId,
        gross_salary: parseFloat( grossSalary ? grossSalary.grossSalary : 0).toFixed(2),
        penalty: parseFloat(penalty ? penalty.penalty : 0).toFixed(2),
        overtime: overtime ? overtime.overtime_hours : 0,
        total_deductions: totalDeductions ? totalDeductions.total : 0,
        net_salary: parseFloat(netSalary ? netSalary.netSalary : 0).toFixed(2),
        gross_monthly_salary: parseFloat(salary).toFixed(2),
        salary_period: salaryPeriod,
        hourly_rate: parseFloat(hourlyRate).toFixed(2),
        discrepancy_amount: parseFloat(discrepancy ? discrepancy.discrepancy_amount : 0),
        adjustments: adjusment ? adjusment : null,
        dtr_summary: dtrSummary ? dtrSummary : null
      }

      payload.push(entry);
    })

    devlarn.savePayroll(response => {
    }, payload)
  },

  deletePayroll({state, commit}){
    commit('toggleIsLoading');
    devlarn.deletePayroll(() => {
      commit('toggleIsLoading');
    }, state.deletePayrollId);
  },

  getEmployeePenaltiesFromDateRange({ state, commit }) {
    devlarn.getEmployeePenaltiesFromDateRange(penalties => {
      commit('setEmployeePenalties', penalties)
    }, state.payslip.employee_id, state.payslip.from_date, state.payslip.to_date)
  },

  bulkDeletePayroll({state, commit}){
    commit('toggleIsLoading');
    devlarn.bulkDeletePayroll(()=>{
        commit('toggleIsLoading');
        location.reload();
    },state.projectId, state.employeeCategory, state.fromDate, state.toDate);
  },
  getCutOffOvertimes({state, commit}){
    commit('toggleIsLoading');
    const payload = {
        date: state.processPayrollCutOffOvertimeDate,
        employees: state.selectedEmployees.map( x => { return x.id }),
        project_id: state.payrollProjectId
    }
    devlarn.getCutOffOverTimes((value)=>{
        commit('toggleIsLoading');
        commit('setCutOffOvertimeHours', value)
    }, payload);
  },
  getPayrollDeductions({commit}, payrollId){
    commit('toggleIsLoading');
    devlarn.getPayrollDeductions((data)=>{
        commit('toggleIsLoading');
        commit('setPayrollDeductions', data)
    }, payrollId);
  },

  getPayrollSpecialDeductions({commit}, payrollId){
    commit('toggleIsLoading');
    devlarn.getPayrollSpecialDeductions((data)=>{
        commit('toggleIsLoading');
        commit('setPayrollSpecialDeductions', data)
    }, payrollId);
  },
  disableDtrMeta({state, dispatch, commit}, id){
    devlarn.disableDtrMeta((response) => {
        dispatch('getDtrMeta');

        //remove from summaries the log
        const summaries = state.employeeSummaries.filter(x => x.id !== id)
        commit('setEmployeeSummaries', summaries);
        commit('setTotalDeductions');
        commit('setOvertimes');
        commit('setPenalties');
        commit('setGrossSalaries');
        commit('setEmployeeNetSalaries');
    }, id)
  },
  generatePayrollEntry({state, commit, dispatch}){
    commit('toggleIsLoading')
    const employeeIds = state.selectedEmployees.map(x => x.id)
    devlarn.generatePayrollEntry((response) => {
        commit('setPayrollEntries', response.data)
        commit('setSelectedEmployee', response.data[0])
        dispatch('getEmployeeDeductions', response.data[0].id);
        dispatch('getEmployeeSpecialDeductions', state.selectedEmployee.employee_id);
    }, employeeIds, state.payrollProjectId, state.processPayrollCutOffOvertimeDate, state.fromDate, state.toDate)
  },

  updatePayroll({state, commit}, payload) {
    commit('toggleIsLoading')
    devlarn.updatePayroll((response) => {
        commit('setSelectedEmployee', response.data)
        commit('toggleIsLoading')

        const index = state.payrollEntries.findIndex(x => x.employee_id === response.data.employee_id)
        const payrollEntries = state.payrollEntries
        payrollEntries[index] = response.data
        commit('setPayrollEntries', payrollEntries)
    }, payload)
  },
  updateSpecialDeductionPayment({state, commit}, payload){
    devlarn.updateSpecialDeductionPayment((response) => {

        commit('setSelectedEmployee', response.data)
        const index = state.payrollEntries.findIndex(x => x.employee_id === response.data.employee_id)
        const payrollEntries = state.payrollEntries
        payrollEntries[index] = response.data
        commit('setPayrollEntries', payrollEntries)
    }, payload)
  }
}

// mutations
const mutations = {
    setSelectedEmployees(state, employees) {
        var merge = (a, b, p) => a.filter( aa => ! b.find ( bb => aa[p] === bb[p]) ).concat(b);
        state.selectedEmployees = merge(state.selectedEmployees, employees, "id");
    },

    setEmployeeSummaries(state, summaries){
        state.employeeSummaries = summaries;
    },

    clearSelectedEmployees(state){
        state.selectedEmployees = [];
    },

    setDateRange(state, payload){
        state.fromDate = payload.fromDate;
        state.toDate = payload.toDate;
    },

    setSelectedEmployee(state, payload){
        state.selectedEmployee = payload;
    },

    setEmployeeDeductions(state, payload){
        state.employeeDeductions = payload
    },

    setAdjustments(state, payload){
        let index = state.adjustments.findIndex(x => x.id === payload.id);
        if(index > -1){
        // state.adjustments[index] = payload
            Vue.set(state.adjustments, index, payload)
        }else{
            state.adjustments.push( payload )
        }
    },
    setEmployeeDtrSummary(state, payload){
        if(payload.length > 0){
        var id = payload[0].employee_id;
        let index = state.dtrSummaries.findIndex(x => x.id === id);
        if(index > -1){
            state.dtrSummaries[index] = {
            id: id,
            summary: payload,
            }
        }else{
            state.dtrSummaries.push({
            id: id,
            summary: payload,
            } )
        }
        }
    },
    setEmployeeSpecialDeductions(state, payload){
        state.employeeSpecialDeductions = payload
    },

    setTotalDeductions(state){
        let totalDeductions = 0
        let adjustments = state.adjustments.find(x => x.id === state.selectedEmployee.employee_id)

        if(adjustments !== undefined){

            if( adjustments.deductions ){
                adjustments.deductions.forEach((x) => {
                    totalDeductions += parseFloat(x.amount);
                })
            }

            if( adjustments.specialDeductions ){
                adjustments.specialDeductions.forEach((x) => {
                    totalDeductions += parseFloat(x.amount);
                })
            }
        }

        let index = state.totalDeductions.findIndex(x => x.id === state.selectedEmployee.employee_id)

        if(index > -1){
        // state.totalDeductions[index] = { id: state.selectedEmployee.employee_id, total: totalDeductions }
        Vue.set(state.totalDeductions, index, { id: state.selectedEmployee.employee_id, total: totalDeductions })
        }else{
        state.totalDeductions.push({ id: state.selectedEmployee.employee_id, total: totalDeductions })
        }
    },

    setOvertimes(state){
        let overtimeHours = 0
        state.employeeSummaries.forEach((x) => {
            overtimeHours += isNaN(parseFloat(x.approved_overtime)) ? 0 : parseFloat(x.approved_overtime) ;
        })

        let index = state.overtimes.findIndex((x) => x.id === state.selectedEmployee.employee_id)

        if(index > -1){
        Vue.set(state.overtimes, index, {id: state.selectedEmployee.employee_id, overtime_hours: overtimeHours})
        }else{
        state.overtimes.push({id: state.selectedEmployee.employee_id, overtime_hours: overtimeHours})
        }
    },

    setPenalties(state){
        let penalty = 0
        state.employeeSummaries.forEach((x) => penalty += parseFloat(x.total_penalty) )
        let index = state.penalties.findIndex((x) => x.id === state.selectedEmployee.employee_id)

        if(index > -1){
        Vue.set(state.penalties, index, {id: state.selectedEmployee.employee_id, penalty: penalty})
        }else{
        state.penalties.push({id: state.selectedEmployee.employee_id, penalty: penalty})
        }
    },

    setGrossSalaries(state){
        let grossSalary = 0
        state.employeeSummaries.forEach((x) => grossSalary += x.gross_earning )
        let cutOffOvertime = state.cutOffOvertimeHours.find((x) => x.employee_id === state.selectedEmployee.employee_id)
        let otDiscrepancyAmount = state.otDiscrepancyAmounts.find((x) => x.id === state.selectedEmployee.employee_id)
        let holidayAmount = state.employeeSpecialHolidayAmounts.find((x) => x.id === state.selectedEmployee.employee_id)
        let discrepancy = state.discrepancies.find((x) => x.id === state.selectedEmployee.employee_id)

        let holidayAmountTotal = 0;
        if(holidayAmount){
            holidayAmountTotal = holidayAmount.amount;
        }

        let otDiscrepancyAmountTotal = 0;
        if(otDiscrepancyAmount){
            otDiscrepancyAmountTotal = otDiscrepancyAmount.ot_discrepancy_amount ? parseFloat(otDiscrepancyAmount.ot_discrepancy_amount) : 0;
        }

        let discrepancyAmount = 0;
        if(discrepancy){
            discrepancyAmount = discrepancy.discrepancy_amount ? parseFloat(discrepancy.discrepancy_amount) : 0;
        }

        if(cutOffOvertime){
            grossSalary = grossSalary + (cutOffOvertime.hours * state.selectedEmployee.hourly_rate)
        }

        grossSalary = grossSalary + otDiscrepancyAmountTotal + discrepancyAmount;

        if (state.selectedEmployee.employee.employee_category == 'Admin' && state.selectedEmployee.employee.salary_period == 'Monthly'){
            grossSalary = grossSalary + state.selectedEmployee.admin_monthly_addition;
            console.log(grossSalary);
            console.log('grossSalary');
        }

        let index = state.grossSalaries.findIndex((x) => x.employee_id === state.selectedEmployee.employee_id)

        if(index > -1){
            Vue.set(state.grossSalaries, index, {id: state.selectedEmployee.employee_id, grossSalary: grossSalary})
        }else{
            state.grossSalaries.push({id: state.selectedEmployee.employee_id, grossSalary: grossSalary})
        }
    },

    toggleIsLoading(state){
        state.isLoading = !state.isLoading;
    },

    setPayslip(state, details){
        state.payslip = details;
    },

    setDeletePayrollId(state, id){
        state.deletePayrollId = id;
    },

    setEmployeePenalties(state, payload){
        state.employeePenalties = payload;
    },

    setOtDiscrepancyHours(state, hours){
        let index = state.otDiscrepancyHours.findIndex((x) => x.id === state.selectedEmployee.employee_id)
        if(index > -1){
            Vue.set(state.otDiscrepancyHours, index, {id: state.selectedEmployee.employee_id, ot_discrepancy_hour: hours})
        }else{
            state.otDiscrepancyHours.push({id: state.selectedEmployee.employee_id, ot_discrepancy_hour: hours})
        }
    },

    setOtDiscrepancyAmounts(state, amount){
        let index = state.otDiscrepancyAmounts.findIndex((x) => x.id === state.selectedEmployee.employee_id)
        if(index > -1){
            Vue.set(state.otDiscrepancyAmounts, index, {id: state.selectedEmployee.employee_id, ot_discrepancy_amount: amount})
        }else{
            state.otDiscrepancyAmounts.push({id: state.selectedEmployee.employee_id, ot_discrepancy_amount: amount})
        }
    },

    setDiscrepancies(state, amount){
        let index = state.discrepancies.findIndex((x) => x.id === state.selectedEmployee.employee_id)
        if(index > -1){
            Vue.set(state.discrepancies, index, {id: state.selectedEmployee.employee_id, discrepancy_amount: amount})
        }else{
            state.discrepancies.push({id: state.selectedEmployee.employee_id, amount: amount})
        }
    },

    adjustNetSalary(state, amount){
        let netSalary = state.netSalaries.find((y) => y.id === state.selectedEmployee.employee_id);
        if(amount){
            netSalary.netSalary += parseFloat(amount);
        }

        let index = state.netSalaries.findIndex((x) => x.id === state.selectedEmployee.employee_id)

        if(index > -1){
        Vue.set(state.netSalaries, index, netSalary)
        }
    },

    setEmployeeNetSalaries(state){
        let index = state.netSalaries.findIndex((x) => x.id === state.selectedEmployee.employee_id)
        let deductions = state.totalDeductions.find((x) => x.id === state.selectedEmployee.employee_id)
        let discrepancy = state.discrepancies.find((x) => x.id === state.selectedEmployee.employee_id)
        let penalty = state.penalties.find((x) => x.id === state.selectedEmployee.employee_id)
        let cutOffOvertime = state.cutOffOvertimeHours.find((x) => x.employee_id === state.selectedEmployee.employee_id)
        let otDiscrepancyAmount = state.otDiscrepancyAmounts.find((x) => x.id === state.selectedEmployee.employee_id)
        let holidayAmount = state.employeeSpecialHolidayAmounts.find((x) => x.id === state.selectedEmployee.employee_id)

        let netSalary = 0.0;
        state.employeeSummaries.forEach((x)=>{
            netSalary += parseFloat(x.net_earning);
        });

        let holidayAmountTotal = 0;
        if(holidayAmount){
            holidayAmountTotal = holidayAmount.amount;
        }

        let otDiscrepancyAmountTotal = 0;
        if(otDiscrepancyAmount){
            otDiscrepancyAmountTotal = otDiscrepancyAmount.ot_discrepancy_amount ? parseFloat(otDiscrepancyAmount.ot_discrepancy_amount) : 0;
        }

        let discrepancyAmount = 0;
        if(discrepancy){
            discrepancyAmount = discrepancy.discrepancy_amount ? parseFloat(discrepancy.discrepancy_amount) : 0;
        }

        let deductionAmount = 0;
        if(deductions){
            deductionAmount = deductions.total;
        }

        netSalary = netSalary - deductionAmount + otDiscrepancyAmountTotal + discrepancyAmount - penalty.penalty;

        if(cutOffOvertime){
            netSalary = netSalary + (cutOffOvertime.hours * state.selectedEmployee.hourly_rate)
        }

        if(index > -1){
            Vue.set(state.netSalaries, index, {id: state.selectedEmployee.employee_id, netSalary: netSalary})
        }else{
            state.netSalaries.push({id: state.selectedEmployee.employee_id, netSalary: netSalary})
        }
    },

    setAllowances(state, entry){
        let allowances = state.allowances;
        let allowance = allowances.find(x => x.id === state.selectedEmployee.employee_id)

        if(allowance){
        let index = allowances.findIndex(x => x.id === state.selectedEmployee.employee_id)
        allowances[index] = entry;
        }else{
        allowances.push(entry)
        }

        state.allowances = allowances;
    },

    setEmployeeAllowance(state, amount){
        state.employeeAllowance = amount;
    },

    setProjectId(state, id){
        state.projectId = id;
    },

    setEmployeeCategory(state, id){
        state.employeeCategory = id;
    },

    setFromDate(state, date){
        state.fromDate = date;
    },

    setToDate(state, date){
        state.toDate = date;
    },

    setProcessPayrollCutOffOvertimeDate(state, date){
        state.processPayrollCutOffOvertimeDate = date
    },

    setCutOffOvertimeHours(state, value){
        state.cutOffOvertimeHours = value
    },
    setPayrollDeductions(state, value){
        state.payrollDeductions = value
    },
    setPayrollDeductions(state, value){
        state.payrollSpecialDeductions = value
    },
    setEmployeeSpecialHolidayAmounts(state, value){
        let index = state.employeeSpecialHolidayAmounts.findIndex((x) => x.id === state.selectedEmployee.employee_id)
        if(index > -1){
            Vue.set(state.employeeSpecialHolidayAmounts, index, value)
        }else{
            state.employeeSpecialHolidayAmounts.push(value)
        }
    },
    setPayrollProjectId(state, value){
        state.payrollProjectId = value
    },
    setPayrollEntries(state, value){
        state.payrollEntries = value;
    }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
