<template>
  <div class="row">
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <div class="table-responsive">
      <table id="companies-table" style="width:100%" class="display table table-striped table-sm">
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <th>Company Name</th>
            <th>Action</th>
          </tr>
        </tfoot>
      </table>
    </div>

    <button
      type="button"
      id="edit-company-btn"
      class="d-none"
      data-toggle="modal"
      data-target="#edit-company-modal"
    >Edit</button>
    <div
      class="modal fade"
      id="edit-company-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Company Details</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label>Company Name:</label>
            <input
              class="form-control mb-2 company-name"
              v-bind:class="{error:errors.name}"
              type="text"
              v-model="company.name"
              placeholder="Compnay Name"
              required
            />

            <div class="modal-footer">
              <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">
                <span class="fa fa-times mr-1"></span>Close
              </button>
              <button type="button" class="btn btn-primary btn-sm" @click="updateCompany()">
                <span class="fa fa-save mr-1"></span>Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data: function() {
    return {
      isLoading: false,
      fullPage: false,
      company: {},
      errors: {
        name: false
      },
      toFocus: "",
      table: null
    };
  },

  mounted() {
    let vm = this;

    let table = $("#companies-table").DataTable({
      serverSide: true,
      processing: true,
      ajax: "data/companies",
      columns: [
        {
          data: "name",
          sortable: true
        },
        {
          sortable: false,
          render: function(data, type, full, meta) {
            var html =
              `<button 
                                            type="button"
                                            class="btn btn-success btn-sm edit-user" 
                                            data-cp-id="` +
              full.id +
              `" 
                                        ><span class="fa fa-edit mr-1"></span>
                                            Edit
                                        </button>`;

            return html;
          }
        }
      ],
      dom: `
                        <"row"
                            <"col-6"l><"col-6 pr-0"f><"d-flex justify-content-end w-100 toolbar">
                        >
                        <rtip>
                    `,
      drawCallback: function(settings) {
        $(".edit-user").click(function() {
          vm.company.id = $(this).data("cp-id");
          vm.getCompanyDetails();
          $("#edit-company-btn").click();
        });
      }
    });

    this.table = table;
  },

  methods: {
    getCompanyDetails() {
      //
      this.isLoading = true;
      axios
        .get("data/companies/" + this.company.id + "/details")
        .then(response => {
          this.isLoading = false;
          this.company = response.data.data;
        });
    },

    checkFields() {
      if (this.company.name == "") {
        this.errors.name = true;
        this.toFocus = ".company-name";
        return false;
      }
      return true;
    },
    updateCompany() {
      if (!this.checkFields()) {
        $(this.toFocus).focus();
        return false;
      }

      this.isLoading = true;
      axios
        .post("data/companies/" + this.company.id + "/update", this.company)
        .then(response => {
          this.isLoading = false;
          $("#edit-company-modal").modal("hide");
          this.table.draw();
        });
    }
  },

  components: {
    Loading
  }
};
</script>