<template> 
<!-- Modal -->
<div class="modal fade"  id="position-modal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Positions</h5>
        <button type="button" class="close" data-dismiss="modal" data-target="#position-modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form>
        <div class="modal-body">  
            <label for="position">Pisition Name</label>
            <div class="input-group mb-1">
              <input v-model="positionNew" id="position_name" name="position" class="form-control" />
              <button class="btn btn-outline-info ml-1" @click.prevent="savePosition()"><span class="fa fa-save"></span></button>
            </div>  
            <div style="width:100%; height: 310px; overflow: scroll;"> 
              <ul class="list-group list-group">
                <li class="list-group-item disabled">
                 <strong>List of Positions</strong>
                </li>
                <li v-for="positionItem in positionData"
                :key="positionItem.id" 
                 class="list-group-item"> {{ positionItem.name }} <span
                  class="fa fa-trash mt-1 mr-2 float-right"
                  title="Delete"
                  @click="deletePosition(positionItem)"
                ></span></li> 
              </ul>
              
          </div> 
        </div>
      
        <div class="modal-footer">
            <button class="btn btn-secondary" data-toggle="modal"  data-dismiss="modal" ><span class="mr-1 fa fa-times"></span>Close</button>
       </div>
      </form>
    </div>
  </div>
</div>  
</template>
<script>
export default {
    mounted(){
      this.getPositions();
    },

    data() {
      return{
        positionData : [], 
        positionItemId: '',
        positionNew: '',
      }
    },

    methods: {
        getPositions() {
        axios.get("data/get-positions").then(response => { 
            this.positionData = response.data.data; 
        });
        }, 
    
    savePosition() {
      axios.post("position/store",{
      positionName : this.positionNew,
      }).then(response => { 
        this.getPositions(); 
       this.positionNew=''; 
      });
    },

    deletePosition(positionItem) { 
      this.positionItemId = positionItem.id; 
      axios.post("position/delete", {
          id: this.positionItemId,
        }).then(response => {
           this.getPositions(); 
        });
    },

    }
}
</script>