<template>
    <div class="row">
        <button type="button" @click="getPayrollDeductions(payslip.id)" id="view-payroll-details-btn" class="d-none btn btn-primary" data-toggle="modal" data-target="#view-payroll-details-modal">View Details</button>
        <div class="modal fade" id="view-payroll-details-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content" id="employee-payroll-detail" v-if="payslip.employee != undefined">
                    <div class="modal-header">
                        <div>
                            <img src="/storage/logo-2.png" width="100%" />
                            <h5 class="modal-title" id="exampleModalLabel">Payslip</h5>
                            <p class="m-0">Name: <strong>{{ payslip.employee.full_name }}</strong></p>
                            <p class="m-0">Payroll Period: <strong>{{ payslip.from_date | formatDate}} - {{ payslip.to_date | formatDate }}</strong></p>
                            <p class="m-0" v-if="isAdmin">Processed By: <strong>{{ payslip.processed_by_name }}</strong></p>
                        </div>
                        <button type="button" class="close no-print" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ul class="list-group">
                            <li class="list-group-item">Basic Pay: <strong>{{ payslip.employee.gross_monthly_salary | numeral('0,0.00') }} / {{ salaryPeriod }}</strong></li>
                            <li class="list-group-item">Calendar Days: <strong>30</strong></li>
                            <li class="list-group-item">Number of Working Days: <strong>{{ payslip.number_of_days }}</strong></li>
                            <li class="list-group-item">Overtime: <strong>{{  parseInt(payslip.total_overtime_hours) }} hours</strong></li>
                            <!-- <li class="list-group-item">Special Holiday: <strong>{{ numberOfWorkingDays }}</strong></li>
                            <li class="list-group-item">Legal Holiday: <strong>{{ numberOfWorkingDays }}</strong></li>
                            <li class="list-group-item">Discrepancy: <strong>{{ numberOfWorkingDays }}</strong></li> -->
                            <li class="list-group-item">Absent/Late/Undertime: <strong>{{ payslip.total_penalty | numeral('0,0.00') }}</strong></li>
                            <li class="list-group-item"><b>GROSS:</b></li>
                            <li class="list-group-item">Less Deductions:</li>
                            <li class="list-group-item" v-for="deduction in payslip.employee.deductions" :key="deduction.id">
                                <div style="width: 40px;display:inline-block"></div>
                                <b>{{ deduction.deduction_name}} - {{ getDeductionAmount(deduction) | numeral('0,0.00')}}</b>
                            </li>
                            <li class="list-group-item" v-for="deduction in specialDeductions" :key="deduction.id">
                                <div style="width: 40px;display:inline-block"></div>
                                <b>{{ deduction.description }} - {{ getSpecialDeductionAmount(deduction) | numeral('0,0.00') }}</b>
                            </li>
                            <li class="list-group-item">Penalties:</li>
                            <li class="list-group-item" v-for="penalty in penalties" :key="penalty.id">
                                <div style="width: 40px;display:inline-block"></div>
                                <b>{{ penalty.description }} - {{ penalty.amount | numeral('0,0.00')}}</b>
                            </li>
                            <li class="list-group-item d-flex justify-content-between"><div>Added Discrepancy Amount:</div> <div><strong>{{ payslip.discrepancy_amount | numeral('0,0.00') }}</strong></div></li>
                            <li class="list-group-item d-flex justify-content-between"><div>Gross Salary:</div> <div><strong>{{ payslip.gross_salary | numeral('0,0.00') }}</strong></div></li>
                            <li class="list-group-item d-flex justify-content-between"><div>Total Deductions:</div> <div><strong>{{ payslip.total_deductions | numeral('0,0.00') }}</strong></div></li>
                            <li class="list-group-item d-flex justify-content-between"><div>Net Salary:</div> <div><strong>{{ payslip.net_salary | numeral('0,0.00') }}</strong></div></li>
                        </ul>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-secondary no-print" data-dismiss="modal">Close</button>
                        <button class="btn btn-primary btn-sm" @click="printPayrollDetails()">Print</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { mapState, mapActions } from 'vuex'
import moment from 'moment';
export default {
    data(){
        return {
            specialDeductions: [],
            deductions: [],
            adjustments: {},
        }
    },

    computed: {
        ...mapState({
            payslip: state => state.payroll.payslip,
            penalties: state => state.payroll.employeePenalties,
            payrollDeductions: state => state.payroll.payrollDeductions,
            isAdmin: state => state.user.isAdmin
        }),

        salaryPeriod: function(){
            return this.payslip.salary_period != 'Monthly' ? 'Daily' : 'Monthly';
        },

        daysInMonth: function(){
            return  moment(this.payslip.from_date, 'YYYY-MM-DD').daysInMonth();
        },

        numberOfWorkingDays: function(){
            let a = moment(this.payslip.from_date, 'YYYY-MM-DD');
            let b = moment(this.payslip.to_date, 'YYYY-MM-DD');

            return b.diff(a, 'days')+1;
        },

        filteredDeductions(){
            let filteredDeductions = [];
            let vm = this;

            if(vm.adjustments.deductions === undefined){
                return vm.deductions;
            }else{
                vm.deductions.forEach(function(deduction){
                    if( vm.adjustments.deductions.includes(deduction.id)){
                        filteredDeductions.push(deduction);
                    }
                });

                return filteredDeductions;
            }
        }
    },

    methods:{

        ...mapActions('payroll', [
            'getPayrollDeductions'
        ]),

        printPayrollDetails(){

            var mywindow = window.open('', 'myWindow', 'height=800,width=800');
            mywindow.document.write('<html>');
            mywindow.document.write(document.getElementsByTagName('head')[0].innerHTML);
            mywindow.document.write('<body >');
            mywindow.document.write('<h1 class="ml-3 mt-3">' + document.title  + '</h1>');
            mywindow.document.write('<div style="width: 400px">');
            mywindow.document.write(document.getElementById('employee-payroll-detail').innerHTML);
            mywindow.document.write('</div>');
            mywindow.document.write('</body></html>');

            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/

            setTimeout(function(){
                mywindow.print();
                mywindow.close();
            }, 500);


            // mywindow.close();


        },

        getSpecialDeductionAmount(deduction){
            if(this.adjustments.specialDeductions !== undefined){
                let specialDeduction = this.adjustments.specialDeductions.find((x) => x.id === deduction.id);
                console.log(specialDeduction);
                if(specialDeduction){
                    return specialDeduction.amount;
                }else{
                    return deduction.amount_per_deduction;
                }
            }else{
                return deduction.amount_per_deduction;
            }
        },

        getDeductionAmount(deduction){
            const payrollDeduction = this.payrollDeductions.find(x => x.deduction_id === deduction.id)
            return payrollDeduction.amount
        }

    },


}
</script>
