<template>
    <div>
        <loading 
            :active.sync="isLoading" 
            :is-full-page="fullPage">
        </loading>


        <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#delete-student-modal">Delete</button>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        
        mounted() {
           console.log(this.employee);
        },

        props:[
            'employee'
        ],

        components: {
            Loading
        },
    }
</script>


