<template>
  <div class="d-flex flex-row align-items-center">
    <select v-model="dtrAdjustment.addOrSub" class="form-control form-control-sm mr-2 add-or-sub">
        <option value="add">Add</option>
        <option value="sub">Subtract</option>
    </select>
    <input
      class="input-minutes form-control form-control-sm"
      type="text"
      v-model="dtrAdjustment.minutes"
      placeholder="Minutes"
    />
    <input
      class="input-remark form-control form-control-sm ml-2"
      type="text"
      v-model="dtrAdjustment.remark"
      placeholder="Remark"
    />
    <div
      class="add-more flex-shrink-1 d-flex justify-content-center align-items-center"
      @click="addAdjustment"
    >
      <i class="fa fa-plus"></i>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      dtrAdjustment: {
          "addOrSub": "add"
      },
    };
  },
  methods: {
    addAdjustment() {
      this.dtrAdjustment.dtrId = this.dtrid;
      this.$emit("add-time-adjustment", this.dtrAdjustment);
      this.dtrAdjustment = {
          "addOrSub": "add"
      };
    },
  },
  props: ['dtrid'],
};
</script>

<style scoped>
.add-more {
  height: 24px;
  min-width: 24px;
  background-color: #005086;
  border-radius: 50%;
  color: white;
  margin-left: 4px;
  cursor: pointer;
}

.input-remark {
  width: 100px;
}
.input-minutes {
  width: 95px;
}

.add-or-sub {
    width: 70px;
}
</style>
