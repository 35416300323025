<template></template>
<script>
$(document).ready(function () {
  var table = $("#payroll-summary-table").DataTable({
    dom:  `<"row" 
                <"col-1.5 mt-1 p-0"><"col-6 d-flex ml-1 p-0"B><"col mt-1"f> 
            >
            <rtip>
            `,
    buttons: [
      {
        extend: "excelHtml5",
        text: '<i class="fa fa-file-excel fa-2x"></i>',
        titleAttr: "Excel",
        title: "Payroll Summary",
        exportOptions: {
          modifier: {
            search: "applied",
            order: "applied",
          },
        },
      },
      {
        extend: "csvHtml5",
        text: '<i class="fa fa-file-csv fa-2x"></i>',
        titleAttr: "CSV",
        title: "Payroll Summary",
        exportOptions: {
          modifier: {
            search: "applied",
            order: "applied",
          },
        },
      },
      {
        extend: "copy",
        text: '<i class="fa fa-copy fa-2x"></i>',
        titleAttr: "Copy",
        copySuccess: {
          _: "Copied %d rows to clipboard",
        },
        title: "Payroll Summary",
        exportOptions: {
          modifier: {
            search: "applied",
            order: "applied",
          },
        },
      },
      {
        extend: "pdfHtml5",
        text: '<i class="fa fa-file-pdf fa-2x"></i>',
        titleAttr: "PDF",
        title: "Payroll Summary",
        exportOptions: {
          modifier: {
            search: "applied",
            order: "applied",
          },
        },
      },
      {
        extend: "print",
        title:
          "<center><p text-black><strong>Devlarn Ventures</strong><br>Payroll Summary</p></center>",
        text: '<i class="fa fa-print fa-2x"></i>',
        exportOptions: {
          modifier: {
            search: "applied",
            order: "applied",
          },
        },
        customize: function (win) {
          $(win.document.body).addClass("white-bg");
          $(win.document.body).css("font-size", "10px").prepend("");
          $(win.document.body)
            .find("table")
            .addClass("compact")
            .css("font-size", "inherit");
        },
      },
    ],
  });
});
</script>
