import devlarn from '../../api/devlarn'

const state = () => ({
    name : '',
    date : null,
    percentage : 1,
    holidayData: {},
    is_recurring: false,
    type: 'legal-holiday'
})

const getters = {

}

const actions = {
    addHoliday({state,commit }){
        devlarn.saveHoliday(() => {
         commit('setClearInputs');
        },state.name, state.date, state.is_recurring, state.percentage, state.type, state.is_recurring);
    },

    updateHoliday({state,commit }){
        devlarn.updateHoliday(() => {
         commit('setClearInputs');
        },state.holidayData.id, state.name, state.date, state.percentage, state.type, state.is_recurring);
    },

    deleteHoliday({state}){
        devlarn.deleteHoliday(() => {
        state.holidayData = {};
        }, state.holidayData.id)
    }
}

const mutations = {
    setClearInputs(state){
        state.name = '';
        state.date = null;
        state.percentage = 1;
    },

    setName(state, name){
        state.name = name;
    },

    setDate(state, date){
        state.date = date;
    },

    setType(state, type){
        state.type = type;
    },

    setPercentage(state, percentage){
        state.percentage = percentage;
    },

    setHolidayData(state, details) {
        state.holidayData = {};
        state.holidayData = details;
    },
    setUpdateHolidayDefaultData(state){
        state.name = state.holidayData.name;
        state.date = state.holidayData.date;
        state.percentage = state.holidayData.percentage;
        state.is_recurring = state.holidayData.is_recurring;
    },
    setIsRecurring(state, value){
        state.is_recurring = value;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
