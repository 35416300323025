import devlarn from '../../api/devlarn';
// initial state
const state = () => ({
  isLoading: false,
  projects: []
})

// getters
const getters = {

}

// actions
const actions = {
    getProjects({commit}){
        devlarn.getProjects(function(data){
            commit('setProjects', data)
        });
    },
    bulkDeleteCutOffOvertime({commit}, payload){
        devlarn.bulkDeleteCutOffOvertime(function(data){
            location.reload()
        }, payload);
    }
}

// mutations
const mutations = {
    setIsLoadingTrue(state){
        state.isLoading = true;
    },

    setIsLoadingFalse(state){
        state.isLoading = false;
    },

    setProjects(state, data){
        state.projects = data;
    }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
