<template>
  <div class="table-responsive mt-2">
    <table id="employees-table" style="width:100%" class="table table-striped table-sm">
      <thead>
        <tr>
          <th>Employee ID</th>
          <th>Last Name</th>
          <th>First Name</th>
          <th>Middle Name</th>
          <th>Age</th>
          <th>Category</th>
          <th>Position</th>
          <th>Type</th>
          <th>Project</th>
          <th>Action</th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <th>Employee ID</th>
          <th>Last Name</th>
          <th>First Name</th>
          <th>Middle Name</th>
          <th>Age</th>
          <th>Category</th>
          <th>Position</th>
          <th>Type</th>
          <th>Project</th>
          <th>Action</th>
        </tr>
      </tfoot>
    </table>
    <button
      type="button"
      id="edit-employee-btn"
      class="d-none btn btn-primary"
      data-toggle="modal"
      data-target="#editEmployeeModal"
      data-backdrop="static"
    >Edit Employee</button>
    <div
      class="modal fade"
      id="editEmployeeModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editEmployeeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="container-fluid modal-title" id="editEmployeeModalLabel">Edit Employee</h3>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="clearAttachFilesVariable"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <edit-employee-component
            ref="editEmployeeComponent"
            :attach-files="attachFiles"
            @edit-employee-load-attach-files="editEmployeeLoadAttachFile($event)"
            :employee-categories="employeeCategories"
            :is-admin="isAdmin"
          ></edit-employee-component>

          <terminate-restore-modal :employee-id="employeeId" action="terminate"></terminate-restore-modal>
        </div>
      </div>
    </div>
    <button id="refreshButton" class="d-none"></button>
  </div>
</template>

<script>

import { mapMutations } from 'vuex'

export default {
  mounted() {
    let vm = this;
    let startAge = 0;
    let endAge = 0;

    console.log('is admin:'+ this.isAdmin);
    this.setIsAdmin(this.isAdmin);

    this.table = $("#employees-table").DataTable({
      serverSide: true,
      processing: true,
      ajax: "/data/employees",
      lengthMenu: [
        [25, 100, -1],
        [25, 100, "All"],
      ],
      pageLength: 25,
      columns: [
        { data: "id" },
        { data: "last_name" },
        { data: "first_name" },
        { data: "middle_name" },
        { data: "age", sortable: true },
        { data: "employee_category" },
        {
          data: "position",
          render: function (data, type, row) {
            if (data == null) {
              return "";
            }
            return data.name;
          },
        },
        { data: "employee_type" },
        {
          data: "project",
          render: function (data, type, row) {
            if (data == null) {
              return "";
            }
            return data.name;
          },
        },
        {
          sortable: false,
          render: function (data, type, full, meta) {
            var html =
              `
                  <button
                      type="button"
                      class="btn btn-primary btn-sm edit-button m-1"
                      data-employee-id="` +
              full.id +
              `">
                        <span class="fa fa-user-edit mr-1"></span>
                        Edit
                  </button> `;
            return html;
          },
        },
      ],
      buttons: [
        {
          extend: "excelHtml5",
          text: '<i class="fa fa-file-excel fa-2x"></i>',
          titleAttr: "Excel",
          title: 'Employee List',
          exportOptions: {
            modifier: {
              search: "applied",
              order: "applied",
            },
          },
        },
        {
          extend: "csvHtml5",
          text: '<i class="fa fa-file-csv fa-2x"></i>',
          titleAttr: "CSV",
          title: 'Employee List',
          exportOptions: {
            modifier: {
              search: "applied",
              order: "applied",
            },
          },
        },
        {
          extend: "pdfHtml5",
          text: '<i class="fa fa-file-pdf fa-2x"></i>',
          titleAttr: "PDF",
          title: 'Employee List',
          exportOptions: {
            modifier: {
              search: "applied",
              order: "applied",
            },
          },
        },
        {
          extend: "print",
          title:
            "<center><p text-black><strong>Devlarn Ventures</strong><br>List of Employees</p></center>",
          text: '<i class="fa fa-print fa-2x"></i>',
          exportOptions: {
            modifier: {
              search: "applied",
              order: "applied",
            },
          },
          customize: function (win) {
            $(win.document.body).addClass("white-bg");
            $(win.document.body).css("font-size", "10px").prepend("");
            $(win.document.body)
              .find("table")
              .addClass("compact")
              .css("font-size", "inherit");
          },
        },
      ],
      dom: `
            <"row"
                <"col-6 mt-1"l><"col-6 pr-0"f>
                <"col-4 pl-0 mt-2"B>
                <"col-8 d-flex justify-content-end w-100 toolbar">
            >
            <rtip>
        `,

      drawCallback: function (settings) {
        $(".edit-button").click(function () {
          var employee_id = $(this).data("employee-id");
          vm.$refs.editEmployeeComponent.loadEmployeeData(employee_id);
          vm.employeeId = employee_id;
          $("#edit-employee-btn").click();
        });
      },
    });

    $("div.toolbar").append(
      `
        <label for="category" class="ml-2">
            Status:
            <select class="form-control-sm form-control" id="status">
              <option>Active</option>
              <option>Inactive</option>
            </select>
        </label>


          <label for="project" class="ml-2">
            Project
            <select class="form-control form-control-sm" id="project">
              <option value="0">All</option>
              ` +
        this.projectOptions() +
        `
            </select>
          </label>

          <label for="type" class="ml-2">
            Type
            <select class="form-control form-control-sm" id="type">
              <option>All</option>
              <option>Provation</option>
              <option>Regular</option>
              <option>Project Base</option>
            </select>
          </label>

          <label for="category" class="ml-2">
            Category
            <select class="form-control-sm form-control" id="category">
              <option>All</option>
              ` + this.getEmployeeCategories() + `
            </select>
          </label>

          <label class="ml-2">
            Start Age
            <input type="number" id="startage" class="form-control-sm form-control"/>
          </label>

          <label class="ml-2">
            End Age
            <input type="number" id="endage" class="form-control-sm form-control"/>
          </label>
        `
    );

    $(document).ready(function () {
      $("#project").change(function () {
        vm.table.columns(8).search(this.value).draw();
      });

      $("#type").change(function () {
        vm.table.columns(7).search(this.value).draw();
      });

      $("#status").change(function () {
        vm.table.columns(9).search(this.value).draw();
      });

      $("#category").change(function () {
        vm.table.columns(6).search(this.value).draw();
      });

      $("#startage").change(function () {
        startAge = this.value;
        var thisAge = startAge + "|" + endAge;
        vm.table.columns(5).search(thisAge).draw();
      });

      $("#endage").keyup(function () {
        endAge = this.value;
        var thisAge = startAge + "|" + endAge;
        vm.table.columns(5).search(thisAge).draw();
      });

      $("#refreshButton").click(function () {
        vm.table.draw();
      });
    });
  },

  data() {
    return {
      attach_files: null,
      employeeId: null,
      table: {},
    };
  },

  methods: {

    editEmployeeLoadAttachFile(event) {
      this.$emit("edit-employee-load-attach-files", event);
    },

    clearAttachFilesVariable() {
      this.editEmployeeLoadAttachFile([]);
    },

    projectOptions() {
      let options = "";
      // console.log(this.projects);
      $.each(this.projects, function (index, value) {
        // console.log(value);
        options +=
          '<option value="' + value.id + '">' + value.name + "</option>";
      });
      return options;
    },

    getEmployeeCategories() {
      let options = "";
      $.each(this.employeeCategories, function (index, value) {
        options +=
          '<option value="' + value + '">' + value + "</option>";
      });
      return options;
    },

    ...mapMutations('user', [
      'setIsAdmin'
    ]),
  },
  props: ["attachFiles", "projects", "isAdmin", 'employeeCategories'],
};
</script>
