<template>
    <div class="form-group">
        <label>Employee:</label>
        <input type="text" class="form-control form-control-sm" v-model="employeeNameId" placeholder="Empoyee Name" required @input="getEmployees()"/>
        <ul class="list-group">
            <li class="list-group-item employee-list mt-2" v-for="employee in employees" :key="employee.id" @click="setEmployee(employee)">{{ employee.full_name }}</li>
        </ul>
    </div>
</template>
<script>
export default {
    data(){
        return{
            employees: [],
            employeeNameId: '',
        }
    },

    methods:{
        getEmployees(){
            axios.get('/data/get-employees/'+this.employeeNameId)
            .then(response => {
                console.log(response.data);
                this.employees = response.data.data;
            });
        },

        setEmployee(employee){
            this.employees = [];
            this.employeeNameId = employee.full_name;
            this.$emit('set-employee-id', employee.id);
        }
    }
}
</script>
<style scoped>
    .employee-list{
        cursor: pointer;
    }
</style>