<template>
  <div>
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a
          class="nav-item nav-link active"
          id="nav_six-tab"
          data-toggle="tab"
          href="#nav_six"
          role="tab"
          @click="setUrl('/data/evaluation/get-six-months',6)"
          aria-selected="true"
        >6 Months</a>
        <a
          class="nav-item nav-link"
          id="nav-eleven-tab"
          data-toggle="tab"
          href="#nav-eleven"
          role="tab"
          @click="setUrl('/data/evaluation/get-eleven-months',11)"
          aria-selected="false"
        >11 Months</a>
        <a
          class="nav-item nav-link"
          id="nav_year-tab"
          data-toggle="tab"
          href="#nav-year"
          role="tab"
          @click="setUrl('/data/evaluation/get-year',12)"
          aria-selected="false"
        >Yearly</a>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade active" id="nav_six" role="tabpanel">
        <div class="row mt-2">
          <div class="col">
            <h5>
              <span class="fa fa-list mr-2"></span>List of 6 Months Employees
            </h5>
          </div>
          <div class="col">
            <h4 class="display4 float-right">6 Months</h4>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="nav-eleven" role="tabpanel">
        <div class="row mt-2">
          <div class="col">
            <h5>
              <span class="fa fa-list mr-2"></span>List of 11 Months Employees
            </h5>
          </div>
          <div class="col">
            <h4 class="display2 float-right">11 Months</h4>
          </div>
        </div>
      </div>
      <div class="tab-pane fade " id="nav-year" role="tabpanel">
        <div class="row mt-2">
          <div class="col">
             <h5>
              <span class="fa fa-list mr-2"></span>List of Employees (Yearly)
            </h5>
          </div>
          <div class="col">
              <div class="row float-right">
                    <button class="btn btn-primary btn-sm mr-2 btn-refresh-year-list" data-toggle="modal" data-target="#evaluated-list">
                    <span class="fa fa-list-alt mr-1"></span>Evaluated List</button>
                <h4 class="display4">Yearly</h4>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table id="evaluation-table" style="width:100%" class="display table table-striped table-sm">
        <thead>
          <tr>
            <th>Employee ID</th>
            <th>Last Name</th>
            <th>First Name</th>
            <th>Date Hired</th>
            <th>Action</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <th>Employee ID</th>
            <th>Last Name</th>
            <th>First Name</th>
            <th>Date Hired</th>
            <th>Action</th>
          </tr>
        </tfoot>
      </table>
    </div>
    <button type="button" id="refresh-evaluation-table-btn" class="invisible refresh-evaluation-table-btn" @click="refreshData()"></button>
    </div>
</template>
<script>
export default {
  mounted() {
    let vm = this;

    //intial data
    vm.checkStatus();
    vm.ajaxData = "/data/evaluation/get-six-months";
    vm.month = 6;

    function formatDate(date) {
      var today = new Date(date);
      return today.toLocaleDateString("en-US");
    }

    var table = $("#evaluation-table").DataTable({
      serverSide: true,
      processing: true,
      ajax: vm.ajaxData,
      columns: [
        { data: "e_id" },
        { data: "first_name" },
        { data: "last_name" },
        {
          data: "date_hired",
          sortable: true,
          render: function(data, type, full, meta) {
            return formatDate(full.date_hired);
          }
        },
        {
          sortable: false,
          render: function(data, type, full, meta) {
            if(full.month==11){
                var html =
              ` <button
                    type="button"
                    class="btn btn-primary btn-sm update-btn-eleven"
                    data-record_id="` + full.id + `" data-month_update="`+ full.month +
                    `"> <span class="fa fa-save mr-1"></span>
                      Save
                </button> `;
           }else if(full.month==12){
               var html =
              ` <button
                    type="button"
                    class="btn btn-primary btn-sm update-btn-year"
                    data-record_id="` + full.id + `" data-month_update="`+ full.month +
                    `"> <span class="fa fa-save mr-1"></span>
                      Save
                </button> `;
           }else{
              var html =
                ` <button
                      type="button"
                      class="btn btn-primary btn-sm save-btn"
                      data-e_employee_id="` + full.e_id +
                `" data-month_save="`+ full.month +`"> <span class="fa fa-save mr-1"></span>
                      Save
              </button> `;
             }

              var html2 =
              `<button
                type="button"
                class="btn btn-danger btn-sm update-btn-six"
                data-update_eval_id="` + full.id +`"
                > <span class="fa fa-undo mr-1"></span>
                Revert
             </button>`;

              var yearlyBtn =
              `<button
                type="button"
                class="btn btn-info btn-sm eval-yearly-btn"
                data-update_yearly_id="` + full.id +`"
                > <span class="fa fa-save mr-1"></span>
                Save
             </button>`;

              if(full.is_evaluated == true || full.is_evaluated == 1)
                {
                  return full.month == 12 ?  yearlyBtn  :  html2;
                }else
                  return html ;
          }
        }
      ],
      buttons: [
        {
          extend: "excelHtml5",
          text: '<i class="fa fa-file-excel fa-2x"></i>',
          titleAttr: "Excel"
        },
        {
          extend: "csvHtml5",
          text: '<i class="fa fa-file-csv fa-2x"></i>',
          titleAttr: "CSV"
        },
        {
          extend: "pdfHtml5",
          text: '<i class="fa fa-file-pdf fa-2x"></i>',
          titleAttr: "PDF"
        },
        {
          extend: "print",
          title: "<center>Evaluation of Employees</center>",
          text: '<i class="fa fa-print fa-2x"></i>',
          customize: function(win) {
            $(win.document.body).addClass("white-bg");
            $(win.document.body)
              .css("font-size", "10px")
              .prepend("");
            $(win.document.body)
              .find("table")
              .addClass("compact")
              .css("font-size", "inherit");
          }
        }
      ],
      dom: `
            <"row"
                <"col-6"l><"col-6"f>
                <"col-4"B>
                <"col-8 d-flex justify-content-end w-100">
            >
            <rtip>
        `,

      drawCallback: function(settings) {
         $(".update-btn-year").click(function() {
          vm.setUpdate($(this).data("record_id"),true);
        });

        $(".update-btn-eleven").click(function() {
          vm.setUpdate($(this).data("record_id"),false);
        });

        $(".save-btn").click(function() {
          vm.setStore($(this).data("e_employee_id"),$(this).data("month_save"));
        });

        $(".update-btn-six").click(function() {
          vm.setUpdate($(this).data("update_eval_id"));
        });

        $(".eval-yearly-btn").click(function() {
          vm.setUpdateYearlyEvaluation($(this).data("update_yearly_id"));
        });
      },

    });

    $(".btn-refresh-year-list").click(function() {
        $(".close-yearly-list-btn").click();
    });

    this.table = table;
  },

  data() {
    return {
      ajaxData: "",
      table: {},
      month: ""
    };
  },

  methods: {
    setUpdate(record_id,is_yearlylist){
       axios
        .post("/evaluation/update", {
          id: record_id,
          is_yearlylist: is_yearlylist
        })
        .then(response => {
          this.table.draw();
        });
    },

    setStore(employee_id,record_month) {
      axios
        .post("/evaluation/store", {
          employee_id: employee_id,
          month: record_month
        })
        .then(response => {
          this.table.draw();
        });
    },

    setUrl(urlString, month) {
      this.ajaxData = urlString;
      this.table.ajax.url(this.ajaxData).load();
      this.month = month;
    },

    checkStatus() {
      document.getElementById("badge_counter").textContent = "";
      axios.get("/evaluation/checkstatus").then(response => {
        if (response.data.status == "ok")
          document.getElementById("badge_counter").textContent = "New";
      });
    },

    refreshData(){
      this.table.draw();
    },

    setUpdateYearlyEvaluation(id){
      axios.post("/evaluation/yearly-evaluation",{
        id: id
      }).then(response => {
        this.table.draw();
      });
    }
  }
};
</script>
