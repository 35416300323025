<template>
    <div class="table-responsive">

        <table class="table table-striped">
            <thead>
            <tr>
                <th>Timestamp</th>
                <th>Project</th>
                <th><center>Delete</center></th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="(log, index) in timeLogs" :key="index">
                    <td>{{ log.time_log | formatDateTime }}</td>
                    <td>{{ log.project_name }}</td>
                    <td><center><i class="fa fa-times red" @click="confirmDelete(log.id)"></i></center></td>
                </tr>

            </tbody>
        </table>
    </div>
</template>
<script>

import { mapState, mapActions, mapMutations } from "vuex";
export default {
    computed: {
        ...mapState({
            timeLogs: (state) => state.dtr.dateTimeLogs
        }),
    },

    methods: {
        ...mapMutations('dtr', [
            'setTimeLogToDelete',
        ]),

        confirmDelete(id){
            this.setTimeLogToDelete(id);
            $("#confirm-time-log-delete-btn").click();
        }
    }
}

</script>
<style scoped>
.fa.red {
    color: red;
    cursor: pointer;
}
</style>
