/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require('./bootstrap');
require('datatables.net-bs4');
// require('datatables.net-rowgroup-dt')();
require('pdfmake');
require('datatables.net-buttons-bs4')();
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.print.js')();
require('vue-notification');
require('bootstrap-datepicker')

window.pdfMake = require('pdfmake/build/pdfmake');
window.pdfFonts = require('pdfmake/build/vfs_fonts');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

window.Vue = require('vue');

import Vue from 'vue'
import 'es6-promise/auto'
import Vuex from 'vuex'
import Notifications from 'vue-notification'
import store from './store'

import VuejsDialog from 'vuejs-dialog';
import VuejsDialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js';
// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
// Tell Vue to install the plugin.
Vue.use(VuejsDialog);

$('.datepicker').datepicker();

Vue.use(Notifications)
Vue.use(Vuex)

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
// Vue.component('daily-time-record-table-component', require('./components/DailyTimeRecordTableComponent.vue').default);
Vue.component('employee-list-table-component', require('./components/EmployeeListTableComponent.vue').default);
Vue.component('projects-table-component', require('./components/ProjectsTableComponent.vue').default);
Vue.component('add-employee-component', require('./components/AddEmployeeComponent.vue').default);
Vue.component('add-employee-modal-component', require('./components/AddEmployeeModalComponent.vue').default);
Vue.component('delete-employee-component', require('./components/DeleteEmployeeComponent.vue').default);
Vue.component('add-file-modal-component', require('./components/AddFileModalComponent.vue').default);
Vue.component('employee-list-page-table-component', require('./components/EmployeeListPageTableComponent.vue').default);
Vue.component('deductions-table-component', require('./components/DeductionTableComponent.vue').default);

Vue.component('edit-employee-component', require('./components/EditEmployeeComponent.vue').default);
Vue.component('report-list-table-component', require('./components/ReportListTableComponent.vue').default);
Vue.component('chat-event-component', require('./components/ChatEventComponent.vue').default);
Vue.component('unread-message-count', require('./components/UnreadMessageCountComponent.vue').default);


//chat
Vue.component('chat-component', require('./components/ChatComponent.vue').default);

//users
Vue.component('users-table-component', require('./components/UsersTableComponent.vue').default);
Vue.component('sidebar-component', require('./components/SidebarComponent.vue').default);

Vue.component('get-employee-component', require('./components/GetEmployeeComponent.vue').default);
Vue.component('get-project-component', require('./components/GetProjectComponent.vue').default);
Vue.component('cash-advance-component', require('./components/CashAdvanceComponent.vue').default);
Vue.component('pay-cash-advance-component', require('./components/PayCashAdvanceComponent.vue').default);
Vue.component('view-cash-advance-details-component', require('./components/ViewCashAdvanceDetailsComponent.vue').default);
Vue.component('employee-deductions-component', require('./components/EmployeeDeductionsComponent.vue').default);
Vue.component('delete-cash-advance-component', require('./components/DeleteCashAdvanceComponent.vue').default);
Vue.component('edit-cash-advance-details-component', require('./components/EditCashAdvanceDetailsComponent.vue').default);

Vue.component('company-table-component', require('./components/CompanyComponent.vue').default);
Vue.component('selected-employee-ids-component', require('./components/SelectedEmployeeIdsComponent.vue').default);

Vue.component('get-company-component', require('./components/GetCompanyComponent.vue').default);
Vue.component('edit-project-company-component', require('./components/EditProjectCompanyComponent.vue').default);
Vue.component('evaluation-component', require('./components/EvaluationComponent.vue').default);
Vue.component('position-component', require('./components/PositionModalComponent.vue').default);
Vue.component('add-permission-user-component', require('./components/AddPermissionUserComponent.vue').default);
Vue.component('evaluated-yearly-list', require('./components/EvaluatedYearlyListComponent.vue').default);

Vue.component('employees-histories-create', require('./components/employees/histories/create.vue').default);
Vue.component('employees-fields-position', require('./components/employees/fields/position.vue').default);
Vue.component('employees-fields-project', require('./components/employees/fields/project.vue').default);
Vue.component('employees-terminated', require('./components/EmployeeTerminatedListComponent.vue').default);

Vue.component('window-print', require('./components/WindowPrint.vue').default);

Vue.component('payroll-add', require('./components/payroll/add.vue').default);
Vue.component('payroll-select', require('./components/payroll/select.vue').default);
Vue.component('payroll-summary', require('./components/payroll/summary.vue').default);
Vue.component('payroll-deductions', require('./components/payroll/deductions.vue').default);
Vue.component('payroll-process', require('./components/payroll/process.vue').default);
Vue.component('payroll-table', require('./components/payroll/table.vue').default);
Vue.component('payroll-details', require('./components/payroll/details.vue').default);
Vue.component('payroll-delete', require('./components/payroll/delete.vue').default);
Vue.component('payroll-bulk-delete', require('./components/payroll/bulkdelete.vue').default);
Vue.component('payroll-sheet', require('./components/payroll/sheet.vue').default);
Vue.component('other-summary-sheet', require('./components/payroll/other-summary.vue').default);
Vue.component('report-payroll-summary', require('./components/reports/payroll/summary.vue').default);

Vue.component('special-deductions-edit', require('./components/specialDeductions/edit.vue').default);
Vue.component('special-deductions-table', require('./components/specialDeductions/table.vue').default);
Vue.component('special-deductions-delete', require('./components/specialDeductions/delete.vue').default);
Vue.component('special-deductions-type', require('./components/specialDeductions/type.vue').default);

Vue.component('dtr-table', require('./components/dtr/table.vue').default);
Vue.component('dtr-details', require('./components/dtr/details.vue').default);
Vue.component('dtr-penalty-add', require('./components/dtr/penalty/add.vue').default);
Vue.component('dtr-time-adjustment', require('./components/dtr/adjustment/adjustment.vue').default);
Vue.component('dtr-date-timelogs', require('./components/dtr/date-timelogs.vue').default);
Vue.component('add-time-log-component', require('./components/dtr/add.vue').default);
Vue.component('terminate-restore-modal', require('./components/TerminateModalComponent.vue').default);

Vue.component('special-deductions-report', require('./components/specialDeductionsReport/table.vue').default);
Vue.component('special-deductions-report-details', require('./components/specialDeductionsReport/details.vue').default);

Vue.component('holiday-component', require('./components/holidays/table.vue').default);
Vue.component('holiday-add', require('./components/holidays/add.vue').default);
Vue.component('holiday-edit', require('./components/holidays/edit.vue').default);
Vue.component('dtr-import-status', require('./components/dtr/dtr-import-status.vue').default);



//cut of overtime
Vue.component('cut-off-overtime-table', require('./components/cutOffOvertime/table.vue').default);
Vue.component('cut-off-overtime-delete', require('./components/cutOffOvertime/delete.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import vueNumeralFilterInstaller from 'vue-numeral-filter';
import moment from 'moment';
var momentDurationFormatSetup = require("moment-duration-format");

Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
    }
});

Vue.filter('formatTime', function (value) {
    if (value) {
        return moment(String('2020-01-01 ' + value)).format('hh:mm A')
    }
});

Vue.filter('formatDateTime', function (value) {
    if (value) {
        return moment(value).format('MM/DD/YYYY hh:mm A')
    }
});

Vue.filter('hoursDurations', function (valueArray) {

    if (valueArray.length <= 0) {
        return 0;
    }

    let startDate = valueArray[0].time_log;
    let endDate = valueArray[valueArray.length - 1].time_log;

    var now = moment(endDate);
    var end = moment(startDate);
    var duration = moment.duration(now.diff(end));
    var hours = duration.asHours();
    return hours.toFixed(2);
});




Vue.filter('formatDuration', function (value) {
    if (value > 0) {
        // return moment.duration(value, "minutes").format("hh:mm");
        return moment(moment.duration(value, 'minutes')._data).format("HH:mm");
    } else {
        return '00:00';
    }
});

Vue.use(vueNumeralFilterInstaller);

const app = new Vue({
    store,
    el: '#app',
    data() {
        return ({
            attach_files: [],
            totalUnreadMessageCount: null,
            employeeId: 0,
            employees: [],
            specialDeductionToEdit: {}
        })
    },
    methods: {
        fileUploaded(data) {
            this.attach_files.push(data);
            //console.log('fileUploaded');
        },
        editEmployeeLoadAttachFile(files) {
            this.attach_files = files;
            //console.log('editEmployeeLoadAttachFile=' + files);
        },
        // called from chat-event-component and chat-component
        getUnreadMessageCount($id) {
            //console.log("getUnreadMessageCount");
            if ($id != null) {
                axios
                    .get("/api/v1/users/" + $id + "/totalUnreadCount")
                    .then(response => {
                        this.totalUnreadMessageCount = response.data.data;
                    });
            }
        },

        setEmployeeId(value) {
            this.employeeId = value;
            $("#employee-id").val(value);
        },

        setProjectId(value) {
            this.projectId = value;
            $("#project-id").val(value);
        },

        setEmployees(value) {
            this.employees = value;
        },

        setSpecialDeductionToEdit (value) {
            console.log(value, 'special deduction to edit');
            this.specialDeductionToEdit = value;
        }
    }

});


