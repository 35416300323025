<template>
  <div>
    <div class="row">
      <div class="table-responsive">
        <table
          id="special-deductions-report"
          style="width:100%"
          class="display table table-sm table-striped"
        >
          <thead>
            <tr>
              <th>Employee Id</th>
              <th>Employee Name</th>
              <th>Date</th>
              <th>Type</th>
              <th>Description</th>
              <th>Total Amount</th>
              <th>Amount Per Deduction</th>
              <th>Total Amount Paid</th>
              <th>Remaining Balance</th>
              <th>Action</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <th>Employee Id</th>
              <th>Employee Name</th>
              <th>Date</th>
              <th>Type</th>
              <th>Description</th>
              <th>Total Amount</th>
              <th>Amount Per Deduction</th>
              <th>Total Amount Paid</th>
              <th>Remaining Balance</th>
              <th>Action</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <special-deductions-report-details></special-deductions-report-details>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapMutations, mapActions, mapState } from "vuex";
import moment from 'moment';

export default {
  data: function () {
    return {
      isSetPeriod: false,
      isLoading: false,
      fromDate: "",
      toDate: "",
    };
  },

  mounted() {
    let vm = this;

    var table = $("#special-deductions-report").DataTable({
      serverSide: true,
      processing: true,
      ajax: "/special-deduction-report/data",
      lengthMenu: [
        [25, 50, 100, -1],
        [25, 50, 100, "All"],
      ],
      columns: [
        { data: "employee_id" },
        {
          data: "full_name",
          sortable: true,
        },
        {
          data: "date",
          sortable: true,
          render: function(data, type, full, meta){
              return moment(data).format('MM/DD/YYYY');
          }
        },
        { data: "type" },
        { data: "description" },
        {
          data: "amount"
        },
        {
          data: "amount_per_deduction",
          sortable: false,
          render: function (data, type, full, meta) {
            return "" + full.amount_per_deduction;
          },
        },
        {
          data: "total_payment"
        },
        {
          data: "remaining_amount"
        },
        {
          sortable: false,
          render: function (data, type, full, meta) {
            let html =
              ` <button type="button" data-details='` +
              JSON.stringify(full) +
              `' class="btn btn-sm btn-info viewpayments">
                                    <i class="fa fa-clipboard-list mr-1"></i>
                                    View Payments
                                </button>
                            `;
            return html;
          },
        },
      ],
      dom: `
            <"row"
                <"col-6 mt-1"l><"col-6 pr-0"f>
                <"col-4 pl-0 mt-2"B>
                <"col-8 d-flex justify-content-end w-100 toolbar">
            >
            <rtip>
                    `,
      buttons: [
        {
          extend: "pdfHtml5",
          title: "",
          messageTop:
            "<center>Devlarn Ventures<br>Special Deductions Report</center>",
          text: '<i class="fa fa-file-pdf fa-2x"></i>',
          titleAttr: "PDF",
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 6, 7, 8],
            modifier: {
              search: "applied",
              order: "applied",
            },
          },
        },
        {
          extend: "print",
          title: "",
          messageTop:
            "<center>Devlarn Ventures<br>Special Deductions Report</center>",
          text: '<i class="fa fa-print fa-2x"></i>',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 6, 7, 8],
            modifier: {
              search: "applied",
              order: "applied",
            },
          },
          customize: function (win) {
            $(win.document.body).addClass("white-bg");
            $(win.document.body).css("font-size", "10pt").prepend("");
            $(win.document.body)
              .find("table")
              .addClass("compact")
              .css("font-size", "inherit");
          },
        },
        {
        extend: "csvHtml5",
        text: '<i class="fa fa-file-csv fa-2x"></i>',
        titleAttr: "CSV",
        title: "Special Deductions Report",
        exportOptions: {
          modifier: {
            search: "applied",
            order: "applied",
          },
        },
      },
      ],
      drawCallback: function () {
        $(document).ready(function () {
          $(".viewpayments").click(function () {
            let details = $(this).data("details");
            vm.setSpecialDeductionsData(details);
            vm.getSpecialDeductionsData(details["id"]);
            $("#view-payments-details-btn").click();
          });
        });
      },
    });

    $("div.toolbar").append(
      ` <div class="row">
      <form class="form-inline">
        <h6 class="mr-1">Select Period:</h6>
        <div class="input-group mr-1 mb-2">
          <div class="input-group-prepend">
            <span class="input-group-text">From</span>
          </div>
          <input id="from-date" v-model="fromDate" type="date" class="form-control" required />
        </div>
        <div class="input-group mr-1 mb-2">
          <div class="input-group-prepend">
            <span class="input-group-text">To</span>
          </div>
          <input id="to-date" v-model="toDate" type="date" class="form-control" required />
        </div>
        <button type="button" id="load-records-btn" class="btn btn-sm btn-primary mr-1 mb-2"">
          <span class="fa fa-th-list mr-1"></span>Load Records
        </button>
      </form>
    </div>
    `
    );

    $(document).ready(function () {
      var fromDate = null;
      var toDate = null;

      $("#from-date").change(function () {
        fromDate = this.value;
      });

      $("#to-date").change(function () {
        toDate = this.value;
      });

      $("#load-records-btn").click(function () {
        if (fromDate != null && toDate != null) {
          var thisDate = fromDate + "|" + toDate;
          table.columns(2).search(thisDate).draw();
        }
      });
    });

    this.specialDeductionTable = table;
  },
  methods: {
    ...mapActions("specialDeductionReport", ["getSpecialDeductionsData"]),

    ...mapMutations("specialDeductionReport", ["setSpecialDeductionsData"]),
  },
  components: {
    Loading,
  },
};
</script>
