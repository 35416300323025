<template>
    <div class="form-group">
        <label>Company:</label>
        <input type="text" class="form-control " v-model="companyName" placeholder="Company Name" required @input="getCompanies()"/>
        <ul class="list-group">
            <li class="list-group-item employee-list mt-2 bg-info text-white" v-for="company in companies" :key="company.id" @click="setCompany(company)">{{ company.name }}</li>
        </ul>
         <input type="hidden" name="company_id" id="company-id" v-model="companyID"/>
    </div>
</template>
<script>
export default {
    data(){
        return{
            companies: [],
            companyName: '',
            companyID: '',
        }
    },

    methods:{
        getCompanies(){
            axios.get('/data/get-companies/'+this.companyName)
            .then(response => {
                console.log(response.data);
                this.companies = response.data.data;
            });
        },

        setCompany(company){
            this.companies = [];
            this.companyName = company.name; 
            this.companyID  =company.id;
        }
    }
}
</script>
<style scoped>
    .employee-list{
        cursor: pointer;
    }
</style>