<template>
  <div class="row">
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <button
      type="button"
      id="confirm-time-log-delete-btn"
      data-toggle="modal"
      data-target="#confirm-time-log-delete-modal"
      class="btn btn-sm btn-primary mb-3 d-none"
    >
      <span class="fa fa-upload mr-2"></span>
      Delete Time Record
    </button>

    <div
      class="modal fade"
      id="confirm-time-log-delete-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Delete</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">Are you sure you want to delete timelog?</div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              id="delete-timelog-dismiss-btn"
              data-dismiss="modal"
            >
              <span class="fa fa-times mr-2"></span>Cancel
            </button>
            <button
              type="submit"
              id="on-delete-click"
              class="btn btn-sm btn-danger"
              @click="preDeleteTimeLog()"
            >
              <span class="fa fa-trash mr-2"></span>Delete
            </button>
          </div>
        </div>
      </div>
    </div>

    <button
      type="button"
      id="confirm-dtr-meta-delete-btn"
      data-toggle="modal"
      data-target="#confirm-dtr-meta-delete-modal"
      class="btn btn-sm btn-primary mb-3 d-none"
    >
      <span class="fa fa-upload mr-2"></span>
      Delete Time Record
    </button>
    <div
      class="modal fade"
      id="confirm-dtr-meta-delete-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Delete</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            Are you sure you want to delete this DTR record? This include all
            time logs.
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              id="delete-timelog-dismiss-btn"
              data-dismiss="modal"
            >
              <span class="fa fa-times mr-2"></span>Cancel
            </button>
            <button
              type="submit"
              id="on-delete-click"
              class="btn btn-sm btn-danger"
              @click="deleteDtrMeta()"
            >
              <span class="fa fa-trash mr-2"></span>Delete
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table
        id="daily-time-record-table"
        style="width: 100%"
        class="display table table-striped table-sm"
      >
        <thead>
          <tr>
            <th></th>
            <th>ID</th>
            <th>Action</th>
            <th>Employee Name</th>
            <th>Project</th>
            <th>Category</th>
            <th>Hours</th>
            <th>Date</th>
            <th>Timestamps</th>
            <th>Transactions</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <th></th>
            <th>ID</th>
            <th>Action</th>
            <th>Employee Name</th>
            <th>Project</th>
            <th>Category</th>
            <th>Hours</th>
            <th>Date</th>
            <th>Timestamps</th>
            <th>Transactions</th>
          </tr>
        </tfoot>
      </table>
    </div>

    <dtr-details></dtr-details>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  mounted() {
    function formatDate(date) {
      var date = new Date(date);
      // return today.toLocaleDateString("en-US", { month: 'long' , day: 'long'});
      return moment(date).format("MM/DD/YYYY");
    }

    this.getProjectOptions();
    this.getEmployeeCategories();
    this.setIsAdmin(this.isAdmin);
    let vm = this;
    var table = $("#daily-time-record-table").DataTable({
      serverSide: true,
      processing: true,
      searchDelay: 500,
      ajax: "/data/dtrs",
      columns: [
        {
          sortable: false,
          render: function (data, type, full, meta) {
            var html =
              `<button data-id="` +
              full.id +
              `" type="button" class="btn btn-danger btn-sm delete-dtr-meta">
                <span class="fa fa-times fa-1x"></span>
              </button>`;
            return html;
          },
        },
        { data: "employee_id" },
        {
          sortable: false,
          render: function (data, type, full, meta) {
            var currentdate = new Date();
            var buttonUniqueId =
              currentdate.getHours() +
              "" +
              currentdate.getMinutes() +
              "" +
              currentdate.getSeconds() +
              "" +
              Math.floor(Math.random() * 1000000000);
            var html =
              `<button id="` +
              buttonUniqueId +
              `" type="button" class="btn btn-primary btn-sm view-log-details"
                data-unique-id = '` +
              buttonUniqueId +
              `'
                data-employee='` +
              JSON.stringify(full) +
              `'>
                <span class="fa fa-info-circle mr-1"></span>
                View Details
              </button>`;

            return html;
          },
        },
        {
          data: "full_name",
          sortable: false,
        },
        {
          data: 'project_name',
          sortable: false
        },
        {
          render: function (data, type, full, meta) {
            return full.employee.employee_category;
          },
        },
        {
          data: "total_hours",
          sortable: false,
        },
        {
          data: "date_log",
          sortable: true,
          render: function (data, type, full, meta) {
            return formatDate(full.date_log);
          },
        },
        {
          data: "ins_outs",
          sortable: false,
          render: function (data, type, full, meta) {
            var cellText = "";
            data.forEach((x) => {
              cellText =
                cellText +
                `<td style="border:1px solid black">` +
                x.timestamp +
                `</td>`;
            });

            var html =
              `
                    <table style="border:1px solid black">
                        <tr>
                            ` +
              cellText +
              `
                        </tr>
                    </table>
                `;
            return html;
          },
        },
        {
          data: "transactions",
        },
      ],
      dom: `
          <"row"
              <"col-6"l><"col-6 pr-0"f><"d-flex justify-content-end w-100 toolbar">
          >
          <rtip>
      `,

      drawCallback: function (settings) {
        $(".view-log-details").click(function () {
          vm.setEmployee($(this).data("employee"));
          vm.findAndSetProject();
          vm.getDtrMeta();
          vm.getUserDateLogDetails();
          vm.getDateTimeLogs();
          vm.getEmployeeWorkedProjects();
          vm.getEmployeePenalties();
          vm.buttonColorChange($(this).data("unique-id"));
          //get cut off overtime
          vm.getCutOffOvertime($(this).data("employee"));
          $("#view-details-btn").click();
        });

        $(".delete-dtr-details").click(function () {
          var timeLogId = $(this).data("unique-id");

          vm.setTimeLogIdToDelete(timeLogId);
          $("#confirm-time-log-delete-btn").click();
        });

        $(".delete-dtr-meta").click(function () {
          var dtrMetaId = $(this).data("id");

          vm.setDtrMetaIdToDelete(dtrMetaId);
          $("#confirm-dtr-meta-delete-btn").click();
        });
      },
    });

    $("div.toolbar").append(
      `
        <label class="ml-2">
            Project Name
            <select id="project-select" name="projectname" class="form-control form-control-sm">
            <option value="all">All</option>` +
        this.projectOptions +
        `</select>
        </label>

        <label class="ml-2">
            Category
            <select id="category-select" name="projectname" class="form-control form-control-sm">
                <option value="all">All</option>` +
        this.employeeCategoriesStr +
        `
            </select>
        </label>

        <label for="startdate" class="ml-2">
            Start Date
           <div class="d-flex align-items-center">
              <input id="start-date" name="startdate" class="form-control form-control-sm" placeholder="mm/dd/YYYY" data-provide="datepicker">
              <i class="fa fa-calendar cal-icon"></i>
            </div>
        </label>

        <label for="enddate" class="ml-2">
            End Date

            <div class="d-flex align-items-center">
              <input id="end-date" name="enddate" class="form-control form-control-sm" placeholder="mm/dd/YYYY" data-provide="datepicker">
              <i class="fa fa-calendar cal-icon"></i>
            </div>

        </label>
      `
    );

    $(document).ready(function () {
      $("#project-select").change(function () {
        table.columns(0).search("");
        table
          .column(4)
          .search("project|" + this.value)
          .draw();
      });

      $("#category-select").change(function () {
        table.columns(0).search("");
        table
          .column(5)
          .search("category|" + this.value)
          .draw();
      });

      var startDate = "";
      var endDate = "";
      $("#start-date").change(function () {
        table.columns(0).search("");
        startDate = this.value;
        var thisDate = startDate + "|" + endDate;
        table.columns(7).search(thisDate).draw();
      });

      $("#end-date").change(function () {
        table.columns(0).search("");
        endDate = this.value;
        var thisDate = startDate + "|" + endDate;
        table.columns(7).search(thisDate).draw();
      });

      $("#view-today-btn").click(function () {
        table.columns(7).search("today").draw();
      });

      $("#view-all-btn").click(function () {
        //clear all dom if view all
        document.getElementById("project-select").options.selectedIndex = 0;
        document.getElementById("category-select").options.selectedIndex = 0;
        document.getElementById("start-date").value = "";
        document.getElementById("end-date").value = "";
        table.search("").columns().search("").draw();
        table.columns().search("");
      });

      $("#on-delete-click").click(function () {
        table.ajax.reload();
      });
    });
  },

  data() {
    return {
      projectOptions: "",
      employeeCategoriesStr: "",
      fullPage: true,
      dateLog: "",
      logsData: [],
      normalWorkHours: 0,
      totalWorkHours: "00:00",
      overtimeHours: 0,
      penalties: [],
      dtrSummary: "",
      mainProjectInfo: "",
      currentButtonId: 0,
    };
  },

  computed: {
    ...mapState({
      employee: (state) => state.dtr.employee,
      isLoading: (state) => state.general.isLoading,
    }),
  },

  methods: {
    ...mapMutations("dtr", [
      "setEmployee",
      "setProject",
      "setTimeLogToDelete",
      "setDtrMetaIdToDelete",
    ]),

    ...mapMutations('user', [
      'setIsAdmin'
    ]),

    ...mapActions("dtr", [
      "getUserDateLogDetails",
      "getEmployeePenalties",
      "getDtrMeta",
      "getDateTimeLogs",
      "deleteTimeLog",
      "deleteDtrMeta",
      "getEmployeeWorkedProjects",
      "getCutOffOvertime",
    ]),

    setTimeLogIdToDelete(timeLogId) {
      console.log(timeLogId);
      this.setTimeLogToDelete(timeLogId);
    },

    preDeleteTimeLog() {
      this.deleteTimeLog();
      $("#delete-timelog-dismiss-btn").click();
    },

    findAndSetProject() {
      let project = this.projects.find(
        (x) => x.id === this.employee.project_id
      );
      this.setProject(project);
    },

    getProjectOptions() {
      var projectOptions = "";
      $.each(this.projects, function (index, item) {
        projectOptions = projectOptions.concat(
          '<option value="' + item.id + '">' + item.name + "</option>"
        );
      });
      this.projectOptions = projectOptions;
    },

    getEmployeeCategories() {
      var employeeCategories = "";
      $.each(this.employeeCategories, function (index, item) {
        employeeCategories = employeeCategories.concat(
          '<option value="' + item + '">' + item + "</option>"
        );
      });
      this.employeeCategoriesStr = employeeCategories;
    },

    buttonColorChange(buttonId) {
      // restor color previous button selected
      if (this.currentButtonId != 0) {
        this.restoreDefaultButtonColor();
      }

      $("#" + buttonId).removeClass("btn-primary");
      $("#" + buttonId).addClass("btn-danger");
      this.currentButtonId = buttonId;
    },

    restoreDefaultButtonColor() {
      if (this.currentButtonId != 0) {
        $("#" + this.currentButtonId).removeClass("btn-danger");
        $("#" + this.currentButtonId).addClass("btn-primary");
      }
    },
  },

  props: ["projects", "csrf", "employeeCategories", 'isAdmin'],

  components: {
    Loading,
  },
};
</script>
<style scoped>
.remove {
  height: 24px;
  width: 24px;
  background-color: red;
  border-radius: 50%;
  color: white;
  margin-left: 4px;
  cursor: pointer;
}
</style>
