<template>
    <div class="row">
        <loading 
            :active.sync="isLoading" 
            :is-full-page="fullPage">
        </loading>

        <div class="table-responsive">
            <table id="cash-advances-table" style="width:100%" class="display table table-striped table-sm">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Employee Name</th>
                        <th>Employee ID</th>
                        <th>Cash Advance Amount</th>
                        <th>Date</th>
                        <th>Terms</th>
                        <th>Remaining Balance</th>
                        <th>Total Paid</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tfoot>
                    <tr>
                         <th>#</th>
                        <th>Employee Name</th>
                        <th>Employee ID</th>
                        <th>Cash Advance Amount</th>
                        <th>Date</th>
                        <th>Terms</th>
                        <th>Remaining Balance</th>
                        <th>Total Paid</th>
                        <th>Action</th>
                    </tr>
                </tfoot>
            </table>
        </div>

        <pay-cash-advance-component :cash-advance-id="cashAdvanceId" @cash-advance-paid="cashAdvancePaid"></pay-cash-advance-component>
        <view-cash-advance-details-component ref="viewCashAdvanceDetails"></view-cash-advance-details-component>
        <delete-cash-advance-component :cash-advance-id="cashAdvanceId"></delete-cash-advance-component>
        <edit-cash-advance-details-component ref="editCashAdvanceDetails" :cash-advance-id="cashAdvanceId"></edit-cash-advance-details-component>
    </div>
</template>
<script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        mounted() {

            let vm = this;

            function formatDate(date){
                var today  = new Date(date);
                return today.toLocaleDateString("en-US");
            }

            function formatCurrency(value){
                const filters = vm.$options.filters;
                return filters.numeral(value, '0,0.00');
            }

            var table = $('#cash-advances-table').DataTable({
                serverSide: true,
                processing: true,
                ajax:"/data/cash-advances",
                columns: [
                    { data: 'employee_id' },
                    {
                        data: 'full_name',
                        sortable: true,
                        "render": function ( data, type, full, meta ) {
                            var html = full.first_name +' '+ full.middle_name +' '+ full.last_name
                            return html;
                        }
                    },
                    { data: 'employee_id' },
                    { 
                        data: 'amount', 
                        sortable: false,
                        "render": function ( data, type, full, meta ) {
                            return ''+formatCurrency(data);
                        }
                    },
                    { 
                        data: 'date', 
                        sortable: true,
                        "render": function ( data, type, full, meta ) {
                            return formatDate(full.date);
                        }
                    },
                    
                    { data: 'terms' },
                    { 
                        data: 'remaining_balance',
                        sortable: false,
                        "render": function ( data, type, full, meta ) {
                            return ''+formatCurrency(full.amount - full.total_paid);
                        } 
                    },
                    { 
                        data: 'total_paid', 
                        sortable: false,
                        "render": function ( data, type, full, meta ) {
                            return ''+formatCurrency(full.total_paid);
                        } 
                    },
                    {
                        sortable: false,
                        "render": function ( data, type, full, meta ) {
                            
                            var html = `<button 
                                            type="button" 
                                            class="btn btn-primary btn-sm pay-cash-advance mb-1" 
                                            data-cash-advance-id="`+full.id+`">
                                                <span class="fa fa-money-bill-alt mr-1"></span>
                                                Pay
                                        </button>
                                        <button 
                                            type="button" 
                                            class="btn btn-warning btn-sm edit-cash-advance-details mb-1" 
                                            data-cash-advance-id="`+full.id+`">
                                                <span class="fa fa-edit mr-1"></span>
                                                Edit
                                        </button>
                                        <button 
                                            type="button" 
                                            class="btn btn-success btn-sm view-cash-advance-details mb-1" 
                                            data-cash-advance-id="`+full.id+`">
                                                <span class="fa fa-info-circle mr-1"></span>
                                                View Details
                                        </button>
                                        <button 
                                            type="button" 
                                            class="btn btn-danger btn-sm delete-cash-advance mb-1" 
                                            data-cash-advance-id="`+full.id+`">
                                                <span class="fa fa-trash-alt mr-1"></span>
                                                Delete
                                        </button>`
                                        ;

                            return html;
                        }
                    }
                ],
                "dom": 
                    `
                        <"row"
                            <"col-6"l><"col-6 pr-0"f><"d-flex justify-content-end w-100 toolbar">
                        >
                        <rtip>
                    `,

                "drawCallback": function( settings ) {
                    $(".pay-cash-advance").click(function(){
                        vm.cashAdvanceId = $(this).data('cash-advance-id');
                        $("#pay-cash-advance-btn").click();
                    });

                    $(".view-cash-advance-details").click(function(){
                        vm.cashAdvanceId = $(this).data('cash-advance-id');
                        vm.getCashAdvanceDetails();
                    });

                    $(".delete-cash-advance").click(function(){
                        vm.cashAdvanceId = $(this).data('cash-advance-id');
                        vm.deleteCashAdvance();
                    });

                    $(".edit-cash-advance-details").click(function(){
                        vm.cashAdvanceId = $(this).data('cash-advance-id');
                        vm.editCashAdvanceDetails();
                    });
                }
            });


            table.on( 'order.dt search.dt', function () {
                table.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
                    cell.innerHTML = i+1;
                });
            }).draw();

            this.table = table;
        },

        data(){
            return{
                isLoading: false,
                fullPage: true,
                cashAdvanceId: 0,
                table: {}
            }
        },

        computed: {
            isCashAdvanceIdSet: function(){
                if(this.cashAdvanceId == 0){
                    return false;
                }else{
                    return true;
                }
            }
        },

        methods:{
            cashAdvancePaid(){
                this.table.draw();
            },

            getCashAdvanceDetails(){
                this.$refs.viewCashAdvanceDetails.getCashAdvanceDetails(this.cashAdvanceId);
                $("#view-cash-advance-details-btn").click();
            },

            deleteCashAdvance(){
                $("#delete-cash-advance-btn").click();
            },

            editCashAdvanceDetails(){
                $("#edit-cash-advance-details-btn").click();
                this.$refs.editCashAdvanceDetails.getCashAdvanceDetails(this.cashAdvanceId);
            }
        },

        components: {
            Loading
        },
    }
</script>
