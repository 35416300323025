import devlarn from '../../api/devlarn'
import { over } from 'lodash'

// initial state
const state = () => ({
  isLoading: false,
  deleteCutOffOverTime: 0,
})

// getters
const getters = {

}

// actions
const actions = {
    deleteCutOffOvertimeEntry({ state }) {
        devlarn.deleteCutOffOvertimeEntry(state.deleteCutOffOverTime)
    },
}

// mutations
const mutations = {
    setDeleteCutOffOvertime(state, id) {
        state.deleteCutOffOverTime = id;
    },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
