<template>
    <!-- Modal -->
<div class="modal fade" id="edit-holiday-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Edit Holiday</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
                <div class="modal-body">
                    <label>Name:</label>
                    <input type="text" :value="holidayData.name" ref="name" class="form-control mb-2" @input="handleSetName($event.target.value)" name="name" placeholder="Holiday Name" required />
                    <label>Date:</label>
                    <input type="date" ref="date" :value="holidayData.date" class="form-control mb-2" @change="handleSetDate($event.target.value)" name="date" required />
                    <label>Type:</label>
                    <select
                        ref="type"
                        class="form-control mb-2"
                        @change="handleSetType($event.target.value)"
                        name="type"

                        required
                    >
                        <option value="legal-holiday"  :selected="holidayData.type=='legal-holiday'">Legal Holiday</option>
                        <option value="special-holiday" :selected="holidayData.type=='special-holiday'">Special Holiday</option>
                    </select>
                    <label>Percentage:</label>
                    <div class="input-group">
                    <input type="number" ref="percentage" :value="holidayData.percentage" @input="handleSetPercentage($event.target.value)" class="form-control" min="1"
                    max="100" required>
                    <div class="input-group-append">
                        <span class="input-group-text">%</span>
                    </div>
                    </div>

                    <div class="d-flex justify-content-left align-items center">
                        <input
                            type="checkbox"
                            name="is_recurring"
                            v-model="is_recurring"
                            id="is-recurring"
                            @change="setIsRecurringValue()"
                            class="mt-1"
                        />
                        <label class="ml-2 my-0">
                            Is recurring?
                        </label>
                    </div>
                </div>



                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal"><span class="fa fa-times mr-1"></span>Cancel</button>
                    <button type="button" class="btn btn-primary" @click="handleUpdateHoliday()" data-dismiss="modal"><span class="fa fa-save mr-1"></span>Update</button>
                </div>
        </div>
    </div>
</div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
    data(){
        return {
            is_recurring: false,
        }
    },
    watch: {
        holidayData(value){
            if(value){
                this.is_recurring = value.is_recurring
            }
        }
    },
    computed: {
        ...mapState({
            holidayData: (state) => state.holidays.holidayData,
        }),
    },
    methods: {
        ...mapActions('holidays',[
               'updateHoliday',
           ]),
        ...mapMutations('holidays',[
            'setName',
            'setDate',
            'setPercentage',
            'setClearInputs',
            'setType',
            "setIsRecurring"
        ]),

        handleUpdateHoliday(){
            this.updateHoliday();
            this.clearInputs();
            location.reload();
        },
        handleSetName(value){
            this.setName(value);
        },
        handleSetType(value){
            this.setType(value);
        },
        handleSetDate(value){
            this.setDate(value);
        },
        handleSetPercentage(value){
            this.setPercentage(value);
        },
        clearInputs(){
            this.$refs.name.value = "";
            this.$refs.date.value = null;
            this.$refs.percentage.value = 1;
        },
        setIsRecurringValue(){
            let val = $("#is-recurring").is(":checked");
            this.setIsRecurring(this.is_recurring);
        }
    },
}
</script>
