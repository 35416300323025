<template>
    <div>
      <div class="modal fade" id="delete-history-modal" tabindex="-1" role="dialog" aria-labelledby="editEmployeeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="container-fluid modal-title" id="editEmployeeModalLabel">Confirm</h6>

            <button type="button" class="close" @click="closeModal()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h7>Are you sure you want to delete?</h7>
          </div>
          <div class="modal-footer">
            <button class="btn btn-sm btn-secondary" type="button" @click="closeModal()">Cancel</button>
            <button class="btn btn-sm btn-danger" type="button" @click="deleteHistory()">Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data(){
        return {
            history_id: 0
        }
    },

    methods: {
        closeModal(){
            $("#delete-history-modal").modal('hide');
        },

        deleteHistory(){
            let vm = this;
            axios.post('/employees/histories', {
                history_id: this.history_id
            }).then(response => {
                vm.$emit('refresh-histories')
                vm.closeModal();
            })
        }
    }
  }
</script>

