<template>
    <!-- Modal -->
<div class="modal fade" id="evaluated-list" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Evaluated Employee List </h5>
                <button type="button" class="btn btn-outline-info btn-sm mt-1 ml-2" @click="refreshData()"> <span class="fa fa-refresh mr-1"></span>Refresh</button>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
             
                <div class="modal-body">
                   <div class="table-responsive">
                    <table id="evaluated-list-table" style="width:100%" class="display table table-sm">
                        <thead>
                        <tr>
                            <th>Employee ID</th>
                            <th>Last Name</th>
                            <th>First Name</th>  
                            <th>Action</th>  
                        </tr>
                        </thead>
                        <tfoot>
                        <tr>
                            <th>Employee ID</th>
                            <th>Last Name</th>
                            <th>First Name</th>  
                            <th>Action</th>  
                        </tr>
                        </tfoot>
                    </table>
                    </div>  
                </div>
        
                <div class="modal-footer">
                    <button type="button" id="btn-close-year-list" class="btn btn-secondary close-eval-list-btn" data-dismiss="modal">
                        <span class="fa fa-times mr-1"></span>Close</button> 
                    <button type="button" class="invisible close-yearly-list-btn" @click="refreshData()"></button>
              
                </div> 
        </div>
    </div>
</div> 
</template>
<script>
export default {
    mounted() {
    let vm = this; 

    var table = $("#evaluated-list-table").DataTable({
      serverSide: true,
      processing: true,
      ajax: "/data/evaluation/get-yearly-list",
      columns: [
        { data: "id" },
        { data: "first_name" },
        { data: "last_name" }, 
        {
          sortable: false,
          render: function(data, type, full, meta) {
              var html2;
              return  html2 =
              `<button 
                type="button" 
                class="btn btn-danger btn-sm update-btn" 
                data-record_id="` + full.id +`"
                > <span class="fa fa-undo mr-1"></span>
                Revert
             </button>`;    
          }
        }
      ],
      buttons: [ 
        {
          extend: "print",
          title: "<center>Evaluation of Employees</center>",
          text: '<i class="fa fa-print"></i>',
          customize: function(win) {
            $(win.document.body).addClass("white-bg");
            $(win.document.body)
              .css("font-size", "10px")
              .prepend("");
            $(win.document.body)
              .find("table")
              .addClass("compact")
              .css("font-size", "inherit");
          }
        }
      ],
      dom: `
            <"row" 
                <"col-6"l><"col-6"> 
                <"col-4"B>
                <"col-8 d-flex justify-content-end w-100">
            >
            <rtip>
        `,
         
      drawCallback: function(settings) {
         $(".update-btn").click(function() {
         vm.setUpdate($(this).data("record_id")); 
        }); 
      },
     
    }); 

    $(".close-eval-list-btn").click(function(){
        $(".refresh-evaluation-table-btn").click();
    });
    
    this.table = table;
  },
  methods: {
       setUpdate(id){  
        var is_yearlylist = true;
       axios
        .post("/evaluation/update", {
          id: id,   
          is_yearlylist: is_yearlylist,
          is_revert: true
        })
        .then(response => {
          this.table.draw();
        });
    }, 

        refreshData(){
            this.table.draw();
        }
  }
}
</script>