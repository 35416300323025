<template>
    <div class="d-flex flex-row align-items-center">
        <input class="input-description form-control form-control-sm" type="text" v-model="details.description" placeholder="Description"/>
        <input class="input-amount form-control form-control-sm ml-2"  type="text" v-model="details.amount" placeholder="Amount"/>
        <div class="add-more flex-shrink-1 d-flex justify-content-center align-items-center" @click="addPenalty">
            <i class="fa fa-plus"></i>
        </div>
    </div>
</template>
<script>
    export default {
        data: function(){
            return{
                details:{}
            }
        },

        methods:{
            addPenalty(){
                this.$emit('add-penalty', this.details);
                this.details = {};
            }
        }
    }
</script>
<style scoped>
    .add-more{
        height: 24px;
        min-width: 24px;
        background-color: #005086;
        border-radius: 50%;
        color: white;
        margin-left: 4px;
        cursor: pointer;
    }

    .input-description,
    .input-amount{
        width: 95px;
    }
</style>